// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

import '@vaadin/vaadin-combo-box';
import '@vaadin/vaadin-item';

@customElement('backpackemr-select-two')
export class CustomSelect2 extends LitElement {

    static get styles() {
        return css`
            .container {
                display: grid;
            }

            vaadin-combo-box {
                --vaadin-combo-box-overlay-width: 350px;
            }

            @media (max-width: 768px) {

            }
        `
        ;
    }

    @property({type: Boolean, attribute: true})
    disabled:boolean = false;

    @property()
    label:string = '';

    @property()
    value:string = '';

    @property({type: Array, attribute: false})
    items:Array<any> = [];

    @property({type: Number})
    itemIndex:number

    @property({type: Boolean})
    disableCustomValue:boolean = false;

    @property({type: Boolean})
    required:boolean = false;
    
    @property()
    errorMessage:string = '';
    
    @property({type: Boolean})
    autofocus:boolean = false;

    render() {
        return html`
            <div class="container">
                <vaadin-combo-box
                    style="--vaadin-combo-box-overlay-width: 350px"
                    label="${this.label}"
                    clear-button-visible
                    ?allow-custom-value="${!this.disableCustomValue}"
                    item-value-path="key"
                    item-label-path="value"
                    ?readonly="${this.disabled}"
                    .items="${this.items}"
                    value="${this.value || ''}" 
                    ?required="${this.required}"
                    ?autofocus="${this.autofocus}"
                    error-message="${this.errorMessage}"
                    @change="${this.selectionChanged}"
                    >
                </vaadin-combo-box>
            </div>
        `;
    }

    selectionChanged(event) {
        if(event?.target){
            this.dispatchEvent(new CustomEvent('value-changed', {
                detail: {
                    selectedItem: event.target.selectedItem,
                    key: event.target.value,
                    itemIndex: this.itemIndex
                },
                bubbles: true,
                composed: true 
            }));
        }
    }
}

//For a rainy day :)
//todo: make the text-area value more robust. break out into fn and add some bumpers (e.g. empty item list)

// ${this.disabled
//     ? html`
//         <vaadin-text-area 
            //     label="${this.label}"
            //     value="${this.items && this.items.find(item => { 
            //         return item.key === this.value 
            //     })?.value || this.value}"
            //     ?readonly="${this.disabled}"
            //     >
            // </vaadin-text-area>
//         `
//     : html`
//         <vaadin-combo-box 
//             style="--vaadin-combo-box-overlay-width: 350px"
//             label="${this.label}"
//             clear-button-visible
//             ?allow-custom-value="${!this.disableCustomValue}"
//             item-value-path="key"
//             item-label-path="value"
//             ?readonly="${this.disabled}"
//             .items="${this.items}"
//             value="${this.value || ''}" 
//             ?required="${this.required}"
//             ?autofocus="${this.autofocus}"
//             error-message="${this.errorMessage}"
//             @change="${this.selectionChanged}">
//         </vaadin-combo-box>
//     `
// }