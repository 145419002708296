import { LitElement, customElement, html, css, property, internalProperty } from 'lit-element';
import authenticator from '../../utils/authenticator';
import CurrentPatient from '../../models/currentPatient';

// components
import '@material/mwc-button';
import '@material/mwc-icon-button';
import './userMenuButton';
import '../common/customAddNewItemButton';

// Current Patient 
import { currentTabDetailChangesVar, currentPatientProfileDataChangesVar, queueCategory, currentPatientQueueDataChangesVar  } from '../../api/graphQL';
import { isEmpty } from 'lodash';
import { analyzeMedicalTabUpdateResp } from '../../utils/medicalTabUpdateRespAnalysis';

// models
import { User } from '../../models/user';
import { QUEUESTATUS } from '../../models/queueCategories';
import { Router, RouterLocation } from '@vaadin/router';
import { router } from '../../utils/routeRegistry';

import { selectedCampaignID } from '../../api/graphQL';

import { themePrimaryBlue } from '../../styles/themeColors';

import i18next from 'i18next';

@customElement('main-header')
export class MainHeader extends LitElement {

    @property({attribute: false})
    user:User;

    @property({type: Boolean})
    showBackButton:boolean = false;

    @internalProperty()
    routeLocation:RouterLocation = router.location;

    static get styles() {
        return css`
        * {
            margin: 0;
            padding: 0;
            color: #333333;
        }

        .centered-container{
            display: grid;
            grid-template-columns: 1fr minmax(auto, 1200px) 1fr;
            padding: 6px 12px;
            background-color: ${themePrimaryBlue};
            height: 50px;
        }

        .header-content {
            grid-column: 2;
            display: grid;
            grid-template-columns: auto auto;
            align-items: center;
            justify-content: space-between;
        }

        .logo {
            height: 48px;
            cursor: pointer;
        }

        mwc-button {
            --mdc-theme-primary: #ffffff;
            --mdc-theme-on-primary: ${themePrimaryBlue};
        }

        .new-patient-and-user-menu {
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: 26px;
            justify-self: end;
        }
        
        .user-menu-dropdown {
            display: flex;
            align-items: center;
        }

        .icon-div {
            background-color: white;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            text-align: center;
        }

        /* phone */
        @media (max-width: 488px) {
            .hide-on-mobile {
            display: none;
            }

            .new-patient-and-user-menu {
            grid-column-gap: 16px
            }
        }
        `;
    }

    render() {
        // console.log('** header: router **', router);

        return html`
        <header class="centered-container">
            <div class="header-content">
                ${this.showBackButton ? 
                html` 
                    <mwc-button 
                    icon="arrow_back" 
                    label="${i18next.t('navigateBack').toString()}"
                    outlined
                    @click="${this._navigateToPatientList}">
                </mwc-button>
                `
                : html`
                <img class="logo" src="/media/web-logo-white.png" alt="BackpackEMR Logo" @click=${this._navigateHome}/>
                `}
                
                <div class="new-patient-and-user-menu">
                    ${this.user 
                        ? html`
                        <div>
                            <mwc-button 
                                raised
                                label="${i18next.t('newPatient').toString()}"
                                icon="person_add"
                                @click="${this._handleCreateNewPatient}"
                            >
                            </mwc-button>
                        </div>
                        <div class="user-menu-dropdown">
                            <div class="icon-div hide-on-mobile">
                            <mwc-icon slot="graphic" style="color:${themePrimaryBlue}">person</mwc-icon>
                            </div>
                            <user-menu-button 
                            .user=${this.user}
                            @handleLogout="${this._handleLogout}">
                            </user-menu-button>
                        </div>`
                        : html ``
                    }
                </div>
            </div>
        </header>
        `;
    }

    async _handleCreateNewPatient(event) {
        event.preventDefault();

        if (!isEmpty(currentTabDetailChangesVar())) {
            const resp = await analyzeMedicalTabUpdateResp();
            this.dispatchEvent(new CustomEvent('saved-queued-details', {
              detail: {
                success: resp.success,
                type: resp.type
              },
              bubbles: true,
              composed: true
            }))
        } 
        Router.go(`/createNewPatient`);
    }

    _navigateHome(event) {
        this._navigateToPatientList(event);
    }

    async _handleLogout(event) {
        event.preventDefault();

        if (!isEmpty(currentTabDetailChangesVar())) {
            const resp = await CurrentPatient.saveQueuedDetails('tab-details');
            if (resp) {
                authenticator.logout();
            }
        } else if (!isEmpty(currentPatientProfileDataChangesVar())){
            const resp = await CurrentPatient.saveQueuedDetails('patient-profile-details');
            if (resp) {
                authenticator.logout();
            }
        } else if (!isEmpty(currentPatientQueueDataChangesVar())) {
            const resp = await CurrentPatient.saveQueuedDetails('queue-data');
            if (resp) {
                authenticator.logout();
            }
        } else {
            authenticator.logout();
        } 
        this.user = null;
        this.showBackButton = false;
        Router.go(`/login`);
    }

    async _navigateToPatientList(event){
        event.preventDefault();

        if (router.location.route.component === 'patient-chart') {
            if (!isEmpty(currentTabDetailChangesVar())) {
              const resp = await analyzeMedicalTabUpdateResp();
              this.dispatchEvent(new CustomEvent('saved-queued-details', {
                detail: {
                  success: resp.success,
                  type: resp.type
                },
                bubbles: true,
                composed: true
              }));
            } 
        } else if (router.location.route.component === 'patient-profile') {
            if (!isEmpty(currentPatientProfileDataChangesVar())) {
              const resp = await CurrentPatient.saveQueuedDetails('patient-profile-details');
              this.dispatchEvent(new CustomEvent('saved-queued-details', {
                detail: {
                  success: resp.updatePatientProfile.success,
                  type: 'patient-profile-update'
                },
                bubbles: true,
                composed: true
              }));
            } 
        }

        if (!isEmpty(currentPatientQueueDataChangesVar())) {
            if (window.localStorage.getItem('patientQueueTab')) {
                let updatedPatientQueueData: any = currentPatientQueueDataChangesVar();
                updatedPatientQueueData.status = QUEUESTATUS.WAITING;
                currentPatientQueueDataChangesVar(updatedPatientQueueData);
            }
            await CurrentPatient.saveQueuedDetails('queue-data');
        }

        if (!selectedCampaignID()) {
            Router.go(`/patients`);
        } else {
            Router.go(`/patients?campaign=${selectedCampaignID()}`);
        } 
    }

}