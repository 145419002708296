// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

// Themes
import { themePrimaryBlue, themeDarkGrey } from '../../../styles/themeColors';

// Components
import '../medicalTabHeader.ts';
import '../../common/customSelect.ts';
import '@vaadin/vaadin-text-field/vaadin-text-area';

//translations
import i18next from 'i18next';

import { VirtualCareDetails } from '../../../models/clinical/virtualCare'

// Utility
import { formatDateTime, getTimeSinceDate } from '../../../utils/dateTimeFormatter';

@customElement('tab-virtual-care')
export class TabVirtualCare extends LitElement {
    static get styles() {
        return css`
            .content {
                display: grid;
            }

            vaadin-text-area.min-height {
                min-height: 150px;
            }
            
            @media (max-width: 768px) {

            }
        `
        ;
    }

    @property({attribute: false})
    details: VirtualCareDetails;
    
    @internalProperty()
    readOnlyMode:boolean = true;

    @property()
    patientID:string = '';

    @property()
    visitID:string = '';

    @property()
    campaignID:string = '';

    firstUpdated() {
        if (!this.details) {
            this.details = {
                type: 'patient-primaryCareVirtualCare',
                campaignId: this.campaignID,
                channels: [this.campaignID],
                patientId: this.patientID,
                updatedAt: null,
        
                reasonForConsult: null,
                requestedById: null
            };
        }

        this.readOnlyMode = this.details?._id ? true : false;
    }

    render() {
        if(!this.details){
            return html`
                <h3>${i18next.t('virtualCare')}</h3>
                <p>${i18next.t('noVirtualCareData')}.</p>
            `;
        }
        
        return html`
            <div class="content">
                <medical-tab-header
                    tabName="${i18next.t('virtualCare').toString() || ''}"
                    .updatedDateTime="${this.details?.updatedAt}"
                    ?readOnlyMode="${this.readOnlyMode}"
                    @edit-content="${this._handleEditContent}">
                </medical-tab-header>
                
                ${this.details?.updatedAt
                    ? html `
                        <backpackemr-text-field
                            label="${i18next.t('Requested').toString()}"
                            .value="${this.formatRequestedDate()}"
                            ?disabled="${true}">
                        </backpackemr-text-field>
                        `
                    : html ``
                    }
                <backpackemr-text-area
                    label="${i18next.t("reasonForConsult").toString()}"
                    placeholder="${i18next.t("notes").toString()}"
                    ?disabled="${this.readOnlyMode}"
                    .value="${this.details?.reasonForConsult || ''}"
                    @text-changed="${(event:CustomEvent) => {
                        this.details.reasonForConsult = event.detail.value
                        this.tabDetailsUpdated();
                    }}"
                >
                </backpackemr-text-area>
            </div>
        `;
    }

    formatRequestedDate(){
        if(!this.details?.updatedAt){
            return '';
        }
        return `${getTimeSinceDate(this.details?.updatedAt)} ago (${formatDateTime(this.details?.updatedAt)})`;
    }

    _handleEditContent(){
        this.readOnlyMode = !this.readOnlyMode;
    }

    getUpdateMutationVariables() {
        const virtualCareInfo: VirtualCareDetails = {
            _id: this.details?._id,
            _rev: this.details?._rev,
            campaignId: this.details.campaignId,
            channels: this.details.channels,
            patientId: this.details.patientId,
            type: 'patient-primaryCareVirtualCare',
            updatedAt: new Date().toISOString(),

            reasonForConsult: this.details.reasonForConsult,
            requestedById: this.details.requestedById,
        }

        return {
            tab: 'VIRTUALCARE',
            id: this.details?._id,
            visitID: this.visitID,
            tabDetails: virtualCareInfo
        }
    };

    tabDetailsUpdated(){
        this.dispatchEvent(new CustomEvent('tab-info-updated', {
            detail: {
                variables: this.getUpdateMutationVariables()
            },
            bubbles: true,
            composed: true
        }));
    }
}