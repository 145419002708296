// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';
import * as moment from 'moment';

// Themes
import { themePrimaryBlue, themeLightGreyBorders } from '../../styles/themeColors';

// Components
import './visitListItem.ts'

@customElement('visit-list-mobile')
export class VisitListMobile extends LitElement {

    static get styles() {
        return css`

            .visits-list-toggle {
                display: grid;
            }

            .visit-list-label {
                padding: 15px;
            }

            mwc-select {
                --mdc-theme-primary: ${themePrimaryBlue};
            }

            @media (max-width: 768px) {
                .visits-list {
                    display: none;
                }
            }
        `
        ;
    }

    @property({attribute: false})
    visits: any;

    @property()
    selectedVisitID: string; //patientCampaignID

    render() {
        return html`
            <div class="visits-list-toggle">
                <mwc-select
                    label="Visits (${this.visits?.length})"
                    value="${this.selectedVisitID}"
                    @action="${this._handleVisitChangedOnMobile}"
                >
                    <mwc-list-item value="newVisit">+ New Visit</mwc-list-item>
                    ${this.visits.map(visit => {
                        return html`
                            <mwc-list-item value="${visit.patientCampaignID}">${moment(visit.visitDateTime).format("DD MMM YYYY")}</mwc-list-item>
                        `
                    })}
                </mwc-select>
            </div>
        `;
    }

    _handleVisitChangedOnMobile(event) {
        event.preventDefault();

        if (event?.target?.value === 'newVisit') {
            this.dispatchEvent(new CustomEvent('add-new-visit', {
                bubbles: true,
                composed: true
            }))
        } else {
            this.dispatchEvent( new CustomEvent('visit-changed', {
                detail: { patientCampaignID: this.visits[event?.detail?.index-1]?.patientCampaignID },
                bubbles: true,
                composed: true 
            }));
        }
    }

}