import CurrentPatient from "../models/currentPatient";

// This function is used to determine whether or not medical tab info was updated properly. It cannot be further refactored since each medical tab detail is its own schema from graphQL layer. 
// The return object is used to display a snackbar on the backpackEMRRouter component to notify the user regarding the save status
export async function analyzeMedicalTabUpdateResp():Promise<any> {
    const updatedTab = await CurrentPatient.saveQueuedDetails('tab-details');
    if (!updatedTab) return;

    let successfullySavedChanges: boolean = false;

    switch (true) {
        // History Tab 
        case updatedTab.hasOwnProperty('updateExamDetails'):
            successfullySavedChanges = updatedTab.updateExamDetails.success;
            break
        case updatedTab.hasOwnProperty('createExamDetails'):
            successfullySavedChanges = updatedTab.createExamDetails.success;
            break
        // Complaints Tab 
        case updatedTab.hasOwnProperty('updateComplaintDetails'):
            successfullySavedChanges = updatedTab.updateComplaintDetails.success;
            break
        case updatedTab.hasOwnProperty('createComplaintDetails'):
            successfullySavedChanges = updatedTab.createComplaintDetails.success;
            break
        // Public Health Tab
        case updatedTab.hasOwnProperty('updatePublicHealthDetails'):
            successfullySavedChanges = updatedTab.updatePublicHealthDetails.success;
            break
        case updatedTab.hasOwnProperty('createPublicHealthDetails'):
            successfullySavedChanges = updatedTab.createPublicHealthDetails.success;
            break
        // Vitals Tab
        case updatedTab.hasOwnProperty('updateVitalsDetails'):
            successfullySavedChanges = updatedTab.updateVitalsDetails.success;
            break
        case updatedTab.hasOwnProperty('createVitalsDetails'):
            successfullySavedChanges = updatedTab.createVitalsDetails.success;
            break
        // History Tab
        case updatedTab.hasOwnProperty('updateHistoryDetails'):
            successfullySavedChanges = updatedTab.updateHistoryDetails.success;
            break
        case updatedTab.hasOwnProperty('createHistoryDetails'):
            successfullySavedChanges = updatedTab.createHistoryDetails.success;
            break
        // Labs Tab
        case updatedTab.hasOwnProperty('updateLabsDetails'):
            successfullySavedChanges = updatedTab.updateLabsDetails.success;
            break
        case updatedTab.hasOwnProperty('createLabsDetails'):
            successfullySavedChanges = updatedTab.createLabsDetails.success;
            break
        // Vision Tab
        case updatedTab.hasOwnProperty('updateVisionDetails'):
            successfullySavedChanges = updatedTab.updateVisionDetails.success;
            break
        case updatedTab.hasOwnProperty('createVisionDetails'):
            successfullySavedChanges = updatedTab.createVisionDetails.success;
            break
        // Virtual Care Tab
        case updatedTab.hasOwnProperty('updateVirtualCareDetails'):
            successfullySavedChanges = updatedTab.updateVirtualCareDetails.success;
            break
        case updatedTab.hasOwnProperty('createVirtualCareDetails'):
            successfullySavedChanges = updatedTab.createVirtualCareDetails.success;
            break
        default:
            break
    }

    return {
        success: successfullySavedChanges,
        type: 'tab-update'
    }
}