import { LitElement, html, property, internalProperty, css, customElement } from 'lit-element';
import { query } from 'lit-element/lib/decorators.js';

// utils
import authenticator from '../utils/authenticator';
import logger, { Component } from '../utils/logger';
import { router, routes } from '../utils/routeRegistry';
import '../I18n/index'

// models
import { User } from '../models/user';
 
// components
import './mainHeader/mainHeader.ts';
import './backpackEmrFooter.ts';
import './backpackEmrMain.ts';
import { Snackbar } from '@material/mwc-snackbar';


@customElement('backpackemr-router')
export class BackpackEmrRouter extends LitElement {
    static get styles() {
        return css`
            * {
                margin: 0;
                padding: 0;
                color: #333333;
            }

            .app {
                display: grid;
                height: 100vh;
                grid-template-rows: auto 1fr;

                font-family: Roboto, Helvetica, sans-serif;
                background: #f4f4f4;
            }

            main {
                overflow: scroll;
            }
        `;
    }
    
    @internalProperty()
    user:User = authenticator.getAuthenticatedUser();

    @internalProperty()
    showBackButton:boolean = false;
    
    @query('#outlet')
    outlet:HTMLElement;

    @property({type: Object}) 
    location = router.location;

    render() {
        // console.log('current NODE_ENV',process.env.NODE_ENV);
        
        return html`
            <div class="app">
                
                <main-header
                    .user=${this.user}
                    .showBackButton="${this.showBackButton}"
                >
                </main-header>
                <main>
                    <backpackemr-main>
                        <div id="outlet"></div>
                    </backpackemr-main>

                    <mwc-snackbar 
                        id="saveSnackbar"
                        leading
                    >
                        <mwc-icon-button icon="close" slot="dismiss"></mwc-icon-button>
                    </mwc-snackbar>
                    <!-- <backpackemr-footer></backpackemr-footer> -->
                </main>
                    
            </div>
        `;
    }

    firstUpdated(changedProperties) {
        router.setOutlet(this.outlet);
        router.setRoutes(routes);

        this.addEventListener('show-header-back-button', this._handleHeaderShowBackButton);
        this.addEventListener('hide-header-back-button', this._handleHeaderHideBackButton);
        this.addEventListener('user-logged-in', this.authUpdated);
        this.addEventListener('saved-queued-details', this.informUserOfSaveStatus)
    }

    // invoked only when the user updates a document and then navigates to a different component, ie from patient profile to patient list/patient chart, or patient chart to patient list. 
    informUserOfSaveStatus(event) {
        if (event.detail.success) {
        const snackbar = this.shadowRoot.querySelector('#saveSnackbar') as Snackbar;
        snackbar.timeoutMs = 5000;
        if (event.detail?.archived === true) {
            snackbar.labelText = 'Patient Archived Successfully!';
        } else {
            snackbar.labelText = 'Saved!';
        }
        snackbar.show();
    } else {
        const snackbar = this.shadowRoot.querySelector('#saveSnackbar') as Snackbar;
        snackbar.timeoutMs = 10000;
        if (event.detail.type !== 'patient-profile-update') {
            snackbar.labelText = `Failed to save your changes. Someone updated the patient's visit details. We've refreshed the patient's information with the latest information. You may go back add your updates again.`;
        } else if (event.detail.type === 'patient-profile-update' && !event.detail?.archived){
            snackbar.labelText = `Failed to update patient profile. Someone updated it before you clicked away. We've refreshed the patient's profile data for you.`
        } else {
            snackbar.labelText = 'Failed to archive patient. Try again.'
        }
        snackbar.show();
        }
    }

    private authUpdated(e:any) {
        this.user = authenticator.getAuthenticatedUser()
    }

    _handleHeaderShowBackButton(event:CustomEvent){
        this.showBackButton = true;
    }

    _handleHeaderHideBackButton(){
        this.showBackButton = false;
    }

}