import { gql } from '@apollo/client/core';

export const updatePatientCustomLOVs = gql`        
    mutation UpdatePatientCustomLOVs($campaignID: String!, $password: String!){
        updatePatientCustomLOVs(campaignID: $campaignID, password: $password){
            code,
            success,
            message,
            results {
                patientsUpdatedCount,
                patientsFailedCount
            }
        }
    }
`;