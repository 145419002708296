import { ApolloClient, InMemoryCache, createHttpLink, DefaultOptions, makeVar, TypePolicy } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { ExamDetails } from '../models/clinical/exam';
import authenticator from '../utils/authenticator';
import config from '../utils/config';
import logger, {Component} from '../utils/logger'

const apiUrl = config.getConfig("api.url", 'http://localhost:1337');
const graphUrl = `${apiUrl}/graphql`
logger.info(Component.Service, `using graphql endpoint at: ${graphUrl}`)

const link = createHttpLink({
  uri: graphUrl,
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => {
  const token = authenticator.getToken();
  let tokenKey = null;
  if (token) {
    tokenKey = token.key;
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      Authorization: tokenKey,
      ...headers,
    }
  }
});

export const currentPatientProfileDataChangesVar = makeVar({});
export const currentTabDetailChangesVar = makeVar({});
export const selectedCampaignID = makeVar<string>('');
export const currentPatientID = makeVar<string>('');
export const queueCategory = makeVar<string>('');
export const currentPatientQueueDataChangesVar = makeVar({});
export const campaignCustomLists = makeVar<{primaryCareDiagnosis:Array<string>,primaryCareTreatments:Array<string>, primaryCareDrugs:Array<string>}>({
    primaryCareDiagnosis: null,
    primaryCareTreatments: null,
    primaryCareDrugs: null 
});

// export const currentExamVar = makeVar<any>({});
// export const currentComplaintVar = makeVar<any>({});


const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  }
}

const cache = new InMemoryCache();

export const client = new ApolloClient({
    cache,
    link: authLink.concat(link),
    defaultOptions: defaultOptions,
});


// const cache = new InMemoryCache({
//     typePolicies: {
//         Query: {
//             fields: {
//                 currentPatient: {
//                     read(): any {
//                       return currentPatientVar();
//                     }
//                 },
//                 currentExam: {
//                     read(): any {
//                         return currentExamVar();
//                     }
//                 }
//             }
//         }
//     }
// });