// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

// Themes

// Models
import { HistoryDetails } from '../../../models/clinical/history';

// Components
import '../medicalTabHeader.ts';
import '../../common/customSelect.ts';
import '../../common/customSelect2.ts';
import '../../common/customTextArea.ts';
import '../../common/customCheckbox.ts';
import '../../common/customAddNewItemButton.ts';
import '../../common/removeItemButton.ts';
import '../../common/customAddRemoveButtons.ts';

//translations
import i18next from 'i18next';
import { generateTranslatedList } from '../../../I18n/i18nUtil';
import { chronicHistories, allergies, currentMedications } from '../../../constants/primaryCare-history';

@customElement('tab-history')
export class TabHistory extends LitElement {

    static get styles() {
        return css`
            .content {
                display: grid;
            }

            .row {
                display: grid;
                grid-column-gap: 16px;
                grid-template-columns: 1fr 3fr;
                align-items: start;
                padding-bottom: 14px;
            }

            .notes-add-remove {
                display: grid;
                grid-column-gap: 16px;
                grid-template-columns: 1fr auto;
            }

            .row-medical-alerts {
                display: grid;
                grid-column-gap: 16px;
                grid-template-columns: 1fr 1fr 1fr;
                align-items: start;
            }

            h4 {
                margin-top: 24px;
                margin-bottom: 12px;
            }

            @media (max-width: 768px) {
                .row {
                    display: grid;
                    grid-template-columns: 1fr;
                }

                .row-medical-alerts {
                    display: grid;
                    grid-column-gap: 16px;
                    grid-row-gap: 24px;
                    grid-template-columns: 1fr;
                    align-items: start;
                }

            }
        `
        ;
    }

    @internalProperty()
    readOnlyMode:boolean = true;

    @property()
    patientID:string = '';
    
    @property()
    visitID:string = '';

    @property()
    campaignID:string = '';

    // can't default this when details does't exist. If the parent passes NULL, it's overwriting the default. So if the parent passes the prop, even if it's null, it will set the prop. Defaults are only helpful when not passing the prop in at all???? is that right???
    @property({attribute: false})
    details: HistoryDetails;

    firstUpdated() {
        if (!this.details) {
            this.details = {
                type: 'patient-primaryCareHistory',
                campaignId: this.campaignID,
                channels: [this.campaignID],
                patientId: this.patientID,
                updatedAt: null,
        
                pregnant: false,
                hivPositive: false,
                breastFeeding: false,
            
                chronicHistories: [
                    {
                        history: null,
                        historyNotes: null
                    }
                ],
                currentMedications: [
                    {
                        currentMedication: null,
                        medicationNotes: null
                    }
                ],
                allergies: [
                    {
                        allergy: null,
                        allergyNotes: null
                    }
                ]
            };
        }

        this.readOnlyMode = this.details?._id ? true : false;
    }

    render() {
        if(!this.details){
            return html`
                <h3>${i18next.t('history')}</h3>
                <p>${i18next.t('noHistoryData')}.</p>
            `;
        }
        
        return html`
            <div class="content">
                <medical-tab-header
                    tabName="${i18next.t('history').toString() || ''}"
                    .updatedDateTime="${this.details?.updatedAt}"
                    ?readOnlyMode="${this.readOnlyMode}"
                    @edit-content="${this._handleEditContent}">
                </medical-tab-header>

                <div class="row-medical-alerts">
                    <backpackemr-checkbox
                        label="${i18next.t('pregnant').toString()}"
                        ?checked="${this.details?.pregnant}" 
                        ?disabled="${this.readOnlyMode}"
                        @check-changed="${() => {
                            this.details.pregnant = !this.details.pregnant;
                            this.tabDetailsUpdated();
                        }}">
                    </backpackemr-checkbox>
                    <backpackemr-checkbox
                        label="${i18next.t('breastFeeding').toString()}"
                        ?checked="${this.details?.breastFeeding}" 
                        ?disabled="${this.readOnlyMode}"
                        @check-changed="${() => {
                            this.details.breastFeeding = !this.details.breastFeeding;
                            this.tabDetailsUpdated();
                        }}">
                    </backpackemr-checkbox>
                    <backpackemr-checkbox
                        label="${i18next.t('hivPositive').toString()}"
                        ?checked="${this.details?.hivPositive}" 
                        ?disabled="${this.readOnlyMode}"
                        @check-changed="${() => {
                            this.details.hivPositive = !this.details.hivPositive
                            this.tabDetailsUpdated();
                        }}">
                    </backpackemr-checkbox>
                </div>

                <h4>${i18next.t('chronicIllnesses')}:</h4>

                ${this.details?.chronicHistories.map( (history, index) => {
                    return html`
                        <div class="row">
                            <backpackemr-select-two
                                .items="${generateTranslatedList(chronicHistories)}"
                                ?disabled="${this.readOnlyMode}"
                                .value="${history?.history}"
                                @value-changed="${(event:CustomEvent) => {
                                    this.details.chronicHistories[event.detail.itemIndex].history = event.detail.key
                                    this.tabDetailsUpdated();
                                }}"
                                itemIndex="${index}">
                            </backpackemr-select-two>

                            <div class="notes-add-remove">
                                <backpackemr-text-area
                                    placeholder="${i18next.t("notes").toString()}"
                                    ?disabled="${this.readOnlyMode}"
                                    .value="${history?.historyNotes || ''}"
                                    @text-changed="${(event:CustomEvent) => {
                                        this.details.chronicHistories[event.detail.itemIndex].historyNotes = event.detail.value
                                        this.tabDetailsUpdated();
                                    }}"
                                    itemIndex="${index}">
                                </backpackemr-text-area>

                                <custom-add-remove-buttons
                                    ?disabled="${this.readOnlyMode}"
                                    .itemIndex="${index}"
                                    .lastItem="${index === this.details.chronicHistories.length-1 ? true : false}"
                                    @remove-item="${this._removeChronicHistory}"
                                    @add-item="${this._addChronicHistory}">
                                </custom-add-remove-buttons>
                            </div>
                        </div>
                    `
                })}
                
                <h4>${i18next.t('currentMedications')}:</h4>

                ${this.details?.currentMedications.map( (medication, index) => {
                    return html`
                        <div class="row">
                            <backpackemr-select-two
                                .items="${generateTranslatedList(currentMedications)}"
                                ?disabled="${this.readOnlyMode}"
                                .value="${medication.currentMedication}"
                                @value-changed="${(event:CustomEvent) => {
                                    this.details.currentMedications[event.detail.itemIndex].currentMedication = event.detail.key
                                    this.tabDetailsUpdated();
                                }}"
                                itemIndex="${index}">
                            </backpackemr-select-two>

                            <div class="notes-add-remove">
                                <backpackemr-text-area
                                    placeholder="${i18next.t("notes").toString()}"
                                    ?disabled="${this.readOnlyMode}"
                                    .value="${medication?.medicationNotes || ''}"
                                    @text-changed="${(event:CustomEvent) => {
                                        this.details.currentMedications[event.detail.itemIndex].medicationNotes = event.detail.value
                                        this.tabDetailsUpdated();
                                    }}"
                                    itemIndex="${index}">
                                </backpackemr-text-area>

                                <custom-add-remove-buttons
                                    ?disabled="${this.readOnlyMode}"
                                    .itemIndex="${index}"
                                    .lastItem="${index === this.details.currentMedications.length-1 ? true: false}"
                                    @remove-item="${this._removeMedicationItem}"
                                    @add-item="${this._addCurrentMedication}">
                                </custom-add-remove-buttons>
                            </div>
                        </div>
                    `
                })}

                <h4>${i18next.t('allergies')}</h4>

                ${this.details?.allergies.map( (allergy, index) => {
                    return html`
                        <div class="row">
                            <backpackemr-select-two
                                .items="${generateTranslatedList(allergies)}"
                                ?disabled="${this.readOnlyMode}"
                                .value="${allergy.allergy}"
                                @value-changed="${(event:CustomEvent) => {
                                    this.details.allergies[event.detail.itemIndex].allergy = event.detail.key
                                    this.tabDetailsUpdated();
                                }}"
                                itemIndex="${index}">
                            </backpackemr-select-two>

                            <div class="notes-add-remove">
                                <backpackemr-text-area
                                    placeholder="${i18next.t("notes").toString()}"
                                    ?disabled="${this.readOnlyMode}"
                                    .value="${allergy?.allergyNotes || ''}"
                                    @text-changed="${(event:CustomEvent) => {
                                        this.details.allergies[event.detail.itemIndex].allergyNotes = event.detail.value
                                        this.tabDetailsUpdated();
                                    }}"
                                    itemIndex="${index}">
                                </backpackemr-text-area>

                                <custom-add-remove-buttons
                                    ?disabled="${this.readOnlyMode}"
                                    .itemIndex="${index}"
                                    .lastItem="${index === this.details.allergies.length-1 ? true: false}"
                                    @remove-item="${this._removeAllergiesItem}"
                                    @add-item="${this._addAllergy}">
                                </custom-add-remove-buttons>
                            </div>
                        </div>
                    `
                })}
            </div>
        `;
    }

    _handleEditContent(){
        this.readOnlyMode = !this.readOnlyMode;
    }

    _addCurrentMedication() {
        this.details.currentMedications.push({
            currentMedication: '',
            medicationNotes: ''
        });

        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    _addAllergy() {
        this.details.allergies.push({
            allergy: '',
            allergyNotes: ''
        });

        this.requestUpdate();
        this.tabDetailsUpdated();
    }


    _addChronicHistory() {
        this.details.chronicHistories.push({
            history: '',
            historyNotes: ''
        });

        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    _removeChronicHistory(evt) {
        const itemIndex = evt.detail.itemIndex;
        if(this.details.chronicHistories.length !== 1) {
            this.details.chronicHistories = this.details.chronicHistories.filter( (item, index) => index !== itemIndex);
        }

        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    _removeAllergiesItem(evt) {
        const itemIndex = evt.detail.itemIndex;
        if(this.details.allergies.length !== 1) {
            this.details.allergies = this.details.allergies.filter( (item, index) => index !== itemIndex);
        }

        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    _removeMedicationItem(evt) {
        const itemIndex = evt.detail.itemIndex;
        if(this.details.currentMedications.length !== 1) {
            this.details.currentMedications = this.details.currentMedications.filter( (item, index) => index !== itemIndex);
        }

        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    getUpdateMutationVariables() {
        return {
            tab: 'HISTORY',
            id: this.details?._id,
            visitID: this.visitID,
            tabDetails: {
                _id: this.details?._id,
                _rev: this.details?._rev,
                type: 'patient-primaryCareHistory',
                campaignId: this.details.campaignId,
                channels: this.details.channels,
                patientId: this.details.patientId,
                updatedAt: new Date().toISOString(),

                pregnant: this.details.pregnant,
                hivPositive: this.details.hivPositive,
                breastFeeding: this.details.breastFeeding,
            
                chronicHistories: this.details.chronicHistories,
                currentMedications: this.details.currentMedications,
                allergies: this.details.allergies
            }
        };
    }

    tabDetailsUpdated(){
        this.dispatchEvent(new CustomEvent('tab-info-updated', {
            detail: {
                variables: this.getUpdateMutationVariables()
            },
            bubbles: true,
            composed: true
        }));
    }
}