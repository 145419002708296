// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

// Themes
import { themePrimaryBlue } from '../../../styles/themeColors';

// Models
import { VisionDetails } from '../../../models/clinical/vision';

//translations
import i18next from 'i18next';
import { generateTranslatedList } from '../../../I18n/i18nUtil';
import { eyeRx, treatments } from '../../../constants/primaryCare-vision';

// Components
import '../medicalTabHeader.ts';
import '../../common/customAddNewItemButton.ts';
import '../../common/customSelect2';
import '../../common/customCheckbox';
import '../../common/customAddRemoveButtons.ts';

// Utility
import { getCurrentNumericDateTime } from '../../../utils/dateTimeFormatter';
@customElement('tab-vision')
export class TabVision extends LitElement {

    static get styles() {
        return css`
            .content {
                display: grid;
            }

            .row {
                display: grid;
                grid-column-gap: 16px;
                grid-template-columns: 1fr 3fr;
                align-items: start;
                padding-bottom: 24px;
            }

            .notes-add-remove {
                display: grid;
                grid-column-gap: 16px;
                grid-template-columns: 1fr auto;
            }

            .row-treatments {
                align-items: center;
            }

            @media (max-width: 768px) {
                .row {
                    display: grid;
                    grid-template-columns: 1fr;
                }
            }
        `
        ;
    }

    @property({attribute: false})
    details: VisionDetails;

    @internalProperty()
    readOnlyMode:boolean = true;

    @property()
    loggedInUser: string;

    @property()
    patientID:string = '';

    @property()
    visitID:string = '';

    @property()
    campaignID:string = '';

    firstUpdated() {
        if (!this.details) {
            this.details = {
                type: 'patient-primaryCareGlasses',
                campaignId: this.campaignID,
                channels: [this.campaignID],
                patientId: this.patientID,
                updatedAt: null,
        
                eyeDiagnoses: [
                    {
                        eyeDiagnosis: null,
                        eyeDiagnosisNotes: null
                    }
                ],
                eyeTreatments: [
                    {
                        eyeTreatment: null,
                        patientReceived: false,
                        treatmentCompletedByandDate: null
                    }
                ]
            };
        }

        this.readOnlyMode = this.details?._id ? true : false;
    }

    render() {
        if(!this.details){
            return html`
                <h3>${i18next.t('vision')}</h3>
                <p>${i18next.t('noVisionData')}.</p>
            `;
        }
        
        return html`
            <div class="content">
                <medical-tab-header
                    tabName="${i18next.t('vision').toString() || ''}"
                    .updatedDateTime="${this.details?.updatedAt}"
                    ?readOnlyMode="${this.readOnlyMode}"
                    @edit-content="${this._handleEditContent}">
                </medical-tab-header>

                <h4>${i18next.t('diagnoses')}</h4>
                    ${this.details?.eyeDiagnoses.map( (eyeDiagnosis, index) => {
                        return html`
                            <div class="row">
                                <backpackemr-select-two
                                    .items="${generateTranslatedList(eyeRx)}"
                                    ?disabled="${this.readOnlyMode}"
                                    .value="${eyeDiagnosis.eyeDiagnosis}"
                                    @value-changed="${(event:CustomEvent) => {
                                        this.details.eyeDiagnoses[event.detail.itemIndex].eyeDiagnosis = event.detail.key
                                        this.tabDetailsUpdated();
                                    }}"
                                    itemIndex = "${index}">
                                </backpackemr-select-two>
                                
                                <div class="notes-add-remove">
                                    <backpackemr-text-area
                                        rows="1"
                                        placeholder="${i18next.t('notes').toString()}"
                                        ?disabled="${this.readOnlyMode}"
                                        .value="${eyeDiagnosis?.eyeDiagnosisNotes || ' '}"
                                        @text-changed="${(event:CustomEvent) => {
                                            this.details.eyeDiagnoses[event.detail.itemIndex].eyeDiagnosisNotes = event.detail.value
                                            this.tabDetailsUpdated();
                                        }}"
                                        itemIndex = "${index}">
                                    </backpackemr-text-area>
                                    
                                    <custom-add-remove-buttons
                                        ?disabled="${this.readOnlyMode}"
                                        .itemIndex="${index}"
                                        .lastItem="${index === this.details.eyeDiagnoses.length-1 ? true: false}"
                                        @remove-item="${this._removeDiagnosis}"
                                        @add-item="${this._addDiagnosis}">
                                    </custom-add-remove-buttons>
                                </div>
                            </div>
                        `
                    })}

                <h4>${i18next.t('treatments')}</h4>
                    ${this.details?.eyeTreatments.map( (eyeTreatment, index) => {
                        return html`
                            <div class="row row-treatments">
                                <backpackemr-select-two
                                    .items="${generateTranslatedList(treatments)}"
                                    ?disabled="${this.readOnlyMode}"
                                    .value="${eyeTreatment.eyeTreatment}"
                                    @value-changed="${(event:CustomEvent) => {
                                        this.details.eyeTreatments[event.detail.itemIndex].eyeTreatment = event.detail.key
                                        this.tabDetailsUpdated();
                                    }}"
                                    itemIndex="${index}">
                                </backpackemr-select-two>
                                
                                <div class="notes-add-remove">
                                    <backpackemr-checkbox 
                                        label="${this.formatPatientReceivedInfo(eyeTreatment.patientReceived, eyeTreatment.treatmentCompletedByandDate)}"
                                        ?checked="${eyeTreatment.patientReceived}"
                                        ?disabled="${this.readOnlyMode}"
                                        @check-changed="${() => {
                                            this._togglePatientReceived(index)
                                            this.tabDetailsUpdated();
                                        }}">
                                    </backpackemr-checkbox>

                                    <custom-add-remove-buttons
                                        ?disabled="${this.readOnlyMode}"
                                        .itemIndex="${index}"
                                        .lastItem="${index === this.details.eyeTreatments.length-1 ? true : false}"
                                        @remove-item="${this._removeTreatment}"
                                        @add-item="${this._addTreatment}">
                                    </custom-add-remove-buttons>
                                </div>
                            </div>
                        `
                    })}
            </div>
        `;
    }

    formatPatientReceivedInfo(patientReceived:boolean, treatmentCompletedByandDate:string):string {
        if(!patientReceived) {
            return i18next.t('patientReceived');
        } else {
            return `${i18next.t('patientReceived')} (${i18next.t('completedUser')} ${treatmentCompletedByandDate})`
        }
    }

    _togglePatientReceived(index:number) {
        if(this.details.eyeTreatments[index].patientReceived === true) {
            this.details.eyeTreatments[index].patientReceived = false;
            this.details.eyeTreatments[index].treatmentCompletedByandDate = '';
        } else {
            this.details.eyeTreatments[index].patientReceived = true;
            this.details.eyeTreatments[index].treatmentCompletedByandDate = `${this.loggedInUser} ${getCurrentNumericDateTime()}`;
        }

        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    _handleEditContent() {
        this.readOnlyMode = !this.readOnlyMode;
    }

    _addDiagnosis() {
        this.details.eyeDiagnoses.push({
            eyeDiagnosis: '',
            eyeDiagnosisNotes: ''
        })
        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    _addTreatment() {
        this.details.eyeTreatments.push({
            eyeTreatment: '',
            patientReceived: false,
            treatmentCompletedByandDate: ''
        })

        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    _removeDiagnosis(event) {
        const itemIndex = event.detail.itemIndex;
        if(this.details.eyeDiagnoses.length !== 1) {
            this.details.eyeDiagnoses = this.details.eyeDiagnoses.filter((item, index) => index !== itemIndex);
        }
        
        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    _removeTreatment(event) {
        const itemIndex = event.detail.itemIndex;
        if(this.details.eyeTreatments.length !== 1) {
            this.details.eyeTreatments = this.details.eyeTreatments.filter((item, index) => index !== itemIndex);
        }

        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    getUpdateMutationVariables() {
        return {
            tab: 'VISION',
            id: this.details?._id,
            visitID: this.visitID,
            tabDetails: {
                _id: this.details?._id,
                _rev: this.details?._rev,
                type: 'patient-primaryCareGlasses',
                campaignId: this.details.campaignId,
                channels: this.details.channels,
                updatedAt: new Date().toISOString(),
                patientId: this.details.patientId,

                eyeDiagnoses: this.details.eyeDiagnoses,
                eyeTreatments: this.details.eyeTreatments
            }
        }
    }

    tabDetailsUpdated(){
        this.dispatchEvent(new CustomEvent('tab-info-updated', {
            detail: {
                variables: this.getUpdateMutationVariables()
            },
            bubbles: true,
            composed: true
        }));
    }
}