import i18next from "i18next";
// import moment from 'moment'
import { LANG_KEY } from '../I18n/i18nUtil'

i18next.init({
    lng: "en",
    debug: false,
    fallbackLng: "en",
    resources: {},
    nsSeparator: false,      // allow keys to be phrases having `:`, `.`
    keySeparator: false      // allow keys to be phrases having `:`, `.`
  },
  (err, t) => {
    if (err) {
      return console.error("Language translation initiation: something went wrong loading.", err);
    }

    i18next.addResources("en", "translation", require("./english.json"));
    i18next.addResources("es", "translation", require("./es.json"));
    i18next.addResources("fr", "translation", require("./fr.json"));

    if (localStorage.getItem(LANG_KEY) && i18next.language !== localStorage.getItem(LANG_KEY)) {

      i18next.changeLanguage(localStorage.getItem(LANG_KEY));
    } 
  }
);


function loadLocaleFile(language: string) {
  console.log("loadLocaleFile: ", language);
  
  switch (language) {
    case "en":
      i18next.addResources("en", "translation", require("./english.json"));
      console.log("i18n: loaded EN file");
      break;
    case "es":
      i18next.addResources("es", "translation", require("./es.json"));
      console.log("i18n: loaded ES file");
      break;
    case "fr":
      i18next.addResources("fr", "translation", require("./fr.json"));
      console.log("i18n: loaded FR file");
      break;
    default:
      break;
  }
}

// // All other translations for the app goes to the respective language file:
// switch (languageCode) {
//   case 'af':
//     i18next.translations.af = require('./af.json')
//     break
//   case 'am':
//     i18next.translations.am = require('./am.json')
//     break
//   case 'ar':
//     i18next.translations.ar = require('./ar.json')
//     break
//   case 'bg':
//     i18next.translations.bg = require('./bg.json')
//     break
//   case 'ca':
//     i18next.translations.ca = require('./ca.json')
//     break
//   case 'cs':
//     i18next.translations.cs = require('./cs.json')
//     break
//   case 'da':
//     i18next.translations.da = require('./da.json')
//     break
//   case 'de':
//     i18next.translations.de = require('./de.json')
//     break
//   case 'el':
//     i18next.translations.el = require('./el.json')
//     break
//   case 'es':
//     i18next.translations.es = require('./es.json')
//     break
//   case 'et':
//     i18next.translations.et = require('./et.json')
//     break
//   case 'fi':
//     let addCode = i18next.locale.substr(0, 3)
//     if (addCode === 'fil') {
//       i18next.translations.fil = require('./fil.json')
//     } else {
//       i18next.translations.fi = require('./fi.json')
//     }
//     break
//   case 'fr':
//     i18next.translations.fr = require('./fr.json')
//     require('moment/locale/fr')
//     break
//   case 'he':
//     i18next.translations.he = require('./he.json')
//     break
//   case 'hi':
//     i18next.translations.hi = require('./hi.json')
//     break
//   case 'hr':
//     i18next.translations.hr = require('./hr.json')
//     break
//   case 'hu':
//     i18next.translations.hu = require('./hu.json')
//     break
//   case 'in':
//     i18next.translations.in = require('./id.json')
//     break
//   case 'id':
//     i18next.translations.id = require('./id.json')
//     break
//   case 'it':
//     i18next.translations.it = require('./it.json')
//     break
//   case 'ja':
//     i18next.translations.ja = require('./ja.json')
//     break
//   case 'ko':
//     i18next.translations.ko = require('./ko.json')
//     break
//   case 'lt':
//     i18next.translations.lt = require('./lt.json')
//     break
//   case 'lv':
//     i18next.translations.lv = require('./lv.json')
//     break
//   case 'ms':
//     i18next.translations.ms = require('./ms.json')
//     break
//   case 'nb':
//     i18next.translations.nb = require('./nb.json')
//     break
//   case 'nl':
//     i18next.translations.nl = require('./nl.json')
//     break
//   case 'no':
//     i18next.translations.no = require('./no.json')
//     break
//   case 'pl':
//     i18next.translations.pl = require('./pl.json')
//     break
//   case 'pt':
//     i18next.translations.pt = require('./pt.json')
//     break
//   case 'ro':
//     i18next.translations.ro = require('./ro.json')
//     break
//   case 'ru':
//     i18next.translations.ru = require('./ru.json')
//     break
//   case 'sl':
//     i18next.translations.sl = require('./sl.json')
//     break
//   case 'sk':
//     i18next.translations.sk = require('./sk.json')
//     break
//   case 'sr':
//     i18next.translations.sr = require('./sr.json')
//     break
//   case 'sv':
//     i18next.translations.sv = require('./sv.json')
//     break
//   case 'sw':
//     i18next.translations.sw = require('./sw.json')
//     break
//   case 'th':
//     i18next.translations.th = require('./th.json')
//     break
//   case 'tr':
//     i18next.translations.tr = require('./tr.json')
//     break
//   case 'uk':
//     i18next.translations.uk = require('./uk.json')
//     break
//   case 'vi':
//     i18next.translations.vi = require('./vi.json')
//     break
//   case 'zh':
//     i18next.translations.zh = require('./zh.json')
//     break
//   case 'zu':
//     i18next.translations.zu = require('./zu.json')
//     break
//   default:
//     break
// }

// let dateFormatString = moment.localeData().longDateFormat('L')
// // override moment load for region specific files
// switch (i18next.locale) {
//   case 'en-GB':
//   case 'en-KE':
//     console.log("setting locale to en-gb")
//     require('moment/locale/en-gb')
//     dateFormatString = 'DD MMM YYYY'
//     break
//   case 'fr-CA':
//     require('moment/locale/fr-ca')
//     break
//   default:
//     break
// }

// // set date format for app
// export const dateFormat = dateFormatString
// export const timeFormat = moment.localeData().longDateFormat('LT')

// export const toDateOrCurrentDate = (fromValue: any) => {
//   return moment((fromValue || new Date()), [moment.ISO_8601, dateFormat], true).toDate()
// }
