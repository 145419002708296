import { COMPLAINTS, HISTORY, VITALS, LABS, VISION, EXAM, PHARMACY, VIRTUALCARE } from "./medicalCareTabs";

export enum DISCHARGE {
  DISCHARGE = 'discharge'
}

export enum QUEUECATEGORIES {
  COMPLAINTS = 'primaryCareComplaint',
  HISTORY = 'primaryCareHistory',
  VITALS = 'primaryCareVitals',
  LABS = 'primaryCareLabs',
  VISION = 'primaryCareGlasses',
  EXAM = 'primaryCareExam',
  PHARMACY = 'primaryCarePharmacy',
  DISCHARGE = 'primaryCareComplete',
  VIRTUALCARE = 'primaryCareVirtualCare',
}

export enum QUEUESTATUS {
  WAITING = 'WAITING',
  BEING_SEEN = 'BEING_SEEN'
}