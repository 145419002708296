import { LitElement, html, css, property, query, internalProperty, customElement } from 'lit-element';

import '@vaadin/vaadin-radio-button';
import '@vaadin/vaadin-radio-button/vaadin-radio-group';
import { RadioGroupElement } from '@vaadin/vaadin-radio-button/vaadin-radio-group';
import { RadioButtonElement } from '@vaadin/vaadin-radio-button';

import i18next from 'i18next';

@customElement('custom-radio-buttons')
export class CustomRadioButtons extends LitElement {
  static get styles() {
    return css`
      .container {
          display: grid;
      }

      @media (max-width: 768px) {

      }
    `
  }

  @property({type: String, attribute: false})
  label:string;

  @property({type: Boolean, attribute: false})
  readOnlyMode: boolean;

  @property({type:Array, attribute: false})
  buttonOptions:Array<string>;

  @property({type: Number})
  itemIndex:number

  @property({type: String, attribute: false})
  checked: string;

  @query('#radio-group')
  radioGroup: RadioGroupElement;

  @internalProperty()
  selectedValue: string = '';

  render() {
    return html`
      <div class="container">
        <vaadin-radio-group
          label="${this.label? i18next.t(this.label).toString() : ''}"
          .value="${this.checked}"
          @value-changed="${event => this.handleOptionSelection(event)}"
          id="radio-group"
          ?disabled="${this.readOnlyMode}"
        >
          ${this.buttonOptions.map((option, index) => {
            return html`
              <vaadin-radio-button 
                value="${option}"
                id="${option}" 
                ?checked="${option === this.checked}"
              >
                ${i18next.t(option)}
              </vaadin-radio-button>
            `
          })}
        </vaadin-radio-group>
      </div>
    `
  }

  // toggleButtonChecked(option) {
  //   if(this.checked === this.radioGroup.value) {
  //     console.log('clicked a pre selected button');
  //     const selectedButton:RadioButtonElement = this.radioGroup.querySelector(`#${option}`);
  //     selectedButton.toggleAttribute('checked');
  //     this.radioGroup.value = null;

  //   } else {
  //     console.log('clicked a new button!');
  //   }
  // }
  //                 @click="${() => this.toggleButtonChecked(option)}"


  handleOptionSelection(event) {
    if(this.checked === this.radioGroup.value){
      const selectedButton:RadioButtonElement = this.radioGroup.querySelector(`#${event.detail.value}`);
      selectedButton.toggleAttribute('checked');
      // this.radioGroup.value = null;
    }


    this.dispatchEvent(new CustomEvent('option-selected', {
      detail: {
        selectedOption: this.radioGroup.value ? this.radioGroup.value : '',
        itemIndex: this.itemIndex
      },
      bubbles: true,
      composed: true
    }))
  }
}