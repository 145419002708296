// Core
import { customElement, html, internalProperty, css, property } from "lit-element";
import { ApolloLitElement } from '../apolloLitElement';

// Router
import { router } from "../../utils/routeRegistry";
import {
  PreventAndRedirectCommands,
  RedirectResult,
  Router,
  RouterLocation,
} from '@vaadin/router';

// Models
import { Patient } from '../../models/patient';
import { PatientQueuesByCategory } from "../../models/clinical/PatientQueuesByCategory";
import { Campaign } from '../../models/campaignDetails';
import { SORTFIELD } from '../../models/search';
import { COMPLAINTS, HISTORY, VITALS, LABS, VISION, EXAM, PHARMACY, VIRTUALCARE } from "../../models/medicalCareTabs";

// Components
import './patientListContainer';
import '@vaadin/vaadin-tabs';

// Utility
import i18next from "i18next";
import { themePrimaryBlue, themeMediumGrey } from '../../styles/themeColors';

// MakeVar
import { queueCategory } from "../../api/graphQL";


const queueCategoryIndicesToNames = {
    0 : COMPLAINTS.TABNAME,
    1 : HISTORY.TABNAME,
    2 : VITALS.TABNAME,
    3 : LABS.TABNAME,
    4 : VISION.TABNAME,
    5 : EXAM.TABNAME,
    6 : PHARMACY.TABNAME,
    7 : 'discharge',
    8 : VIRTUALCARE.TABNAME
}

const queueCategoryNamesToIndices = {
    [COMPLAINTS.TABNAME]: 0,
    [HISTORY.TABNAME]: 1,
    [VITALS.TABNAME]: 2,
    [LABS.TABNAME]: 3,
    [VISION.TABNAME]: 4,
    [EXAM.TABNAME]: 5,
    [PHARMACY.TABNAME]: 6,
    discharge: 7,
    [VIRTUALCARE.TABNAME]: 8
}

@customElement('patient-queue')
export class PatientQueue extends ApolloLitElement {
    static get styles() {
        return css`
            vaadin-tab {
                font-family: Roboto, Arial, sans-serif; 
                color: ${themeMediumGrey};
            }

            vaadin-tab[selected] {
                color: ${themePrimaryBlue}
            }

            .queue-category-tabs {
                margin: 0px 0px 10px 0px;
                padding: 0;
            }
        `
    }

    @property({attribute: false})
    patients: Array<Patient>

    @internalProperty()
    patientQueues: PatientQueuesByCategory

    @internalProperty()
    queueCategorySorted: any;

    @internalProperty()
    selectedTabIndex: number;

    connectedCallback():void {
        if (!window.localStorage.getItem('patientQueueTab')) {
            window.localStorage.setItem('patientQueueTab', COMPLAINTS.TABNAME);
        }
        super.connectedCallback();

        this.patientQueues = {
            [COMPLAINTS.TABNAME]: [],
            [HISTORY.TABNAME]: [],
            [VITALS.TABNAME]: [],
            [LABS.TABNAME]: [],
            [VISION.TABNAME]: [],
            [EXAM.TABNAME]: [],
            [PHARMACY.TABNAME]: [],
            discharge: [],
            [VIRTUALCARE.TABNAME]: []
        }

        // initialize the queue category sorting object that keeps track of which tabs have been sorted previously so they are not sorted again.
        this.queueCategorySorted = {
            [COMPLAINTS.TABNAME]: false,
            [HISTORY.TABNAME]: false,
            [VITALS.TABNAME]: false,
            [LABS.TABNAME]: false,
            [VISION.TABNAME]: false,
            [EXAM.TABNAME]: false,
            [PHARMACY.TABNAME]: false,
            discharge: false,
            [VIRTUALCARE.TABNAME]: false
        }

        for (let i=0; i<this.patients.length; i++) {
            if (this.patients[i].patientProfileSummary?.currentCampaignData?.queue?.queueId) {
                const stationId = this.patients[i].patientProfileSummary?.currentCampaignData?.queue?.queueId;
                switch(stationId) {
                    case ('primaryCareComplaint'):
                        this.patientQueues[COMPLAINTS.TABNAME].push(this.patients[i]);
                        break;
                    case ('primaryCareHistory'):
                        this.patientQueues[HISTORY.TABNAME].push(this.patients[i]);
                        break;
                    case ('primaryCareVitals'):
                        this.patientQueues[VITALS.TABNAME].push(this.patients[i]);
                        break;
                    case ('primaryCareLabs'):
                        this.patientQueues[LABS.TABNAME].push(this.patients[i]);
                        break;
                    case ('primaryCareGlasses'):
                        this.patientQueues[VISION.TABNAME].push(this.patients[i]);
                        break;
                    case ('primaryCareExam'):
                        this.patientQueues[EXAM.TABNAME].push(this.patients[i]);
                        break;
                    case ('primaryCarePharmacy'):
                        this.patientQueues[PHARMACY.TABNAME].push(this.patients[i]);
                        break;
                    case ('primaryCareComplete'):
                        this.patientQueues.discharge.push(this.patients[i]);
                        break;
                    case ('primaryCareVirtualCare'):
                        this.patientQueues[VIRTUALCARE.TABNAME].push(this.patients[i]);
                        break;
                    default:
                        continue
                }
            }
        }

        this.selectedTabIndex = queueCategoryNamesToIndices[queueCategory()];

        // sort only the selected
        this.handleSortQueueCategory(queueCategoryIndicesToNames[this.selectedTabIndex]);
    }

    render() {
        const selectedQueueCategory = queueCategoryIndicesToNames[this.selectedTabIndex];

        return html`
            <vaadin-tabs selected=${this.selectedTabIndex} class="queue-category-tabs" @selected-changed="${event => this.handleQueueCategorySelected(event)}">
                <vaadin-tab> ${i18next.t('complaints')} (${this.patientQueues[COMPLAINTS.TABNAME].length}) </vaadin-tab>
                <vaadin-tab> ${i18next.t('history')} (${this.patientQueues[HISTORY.TABNAME].length}) </vaadin-tab>
                <vaadin-tab> ${i18next.t('vitals')} (${this.patientQueues[VITALS.TABNAME].length}) </vaadin-tab>
                <vaadin-tab> ${i18next.t('labs')} (${this.patientQueues[LABS.TABNAME].length}) </vaadin-tab>
                <vaadin-tab> ${i18next.t('vision')} (${this.patientQueues[VISION.TABNAME].length}) </vaadin-tab>
                <vaadin-tab> ${i18next.t('exam')} (${this.patientQueues[EXAM.TABNAME].length}) </vaadin-tab>
                <vaadin-tab> ${i18next.t('pharmacy')} (${this.patientQueues[PHARMACY.TABNAME].length}) </vaadin-tab>
                <vaadin-tab> ${i18next.t('discharge')} (${this.patientQueues['discharge'].length}) </vaadin-tab>
                <vaadin-tab> ${i18next.t('virtualCare')} (${this.patientQueues[VIRTUALCARE.TABNAME].length}) </vaadin-tab>
            </vaadin-tabs>

            ${this.patientQueues[selectedQueueCategory].length > 0 ?
                html`
                    <patient-list-container .patients="${this.patientQueues[selectedQueueCategory]}"></patient-list-container>
                ` 
                : html `No patients in ${selectedQueueCategory} queue`
            }

        `
    }

    handleQueueCategorySelected(event) {
        if (event?.detail?.value === this.selectedTabIndex) return; 

        queueCategory(queueCategoryIndicesToNames[event?.detail?.value]);

        this.selectedTabIndex = event?.detail?.value;
        const selectedQueueCategory = queueCategoryIndicesToNames[event?.detail?.value];

        if (!this.queueCategorySorted[selectedQueueCategory]) {
            this.handleSortQueueCategory(selectedQueueCategory);
        }
        window.localStorage.setItem('patientQueueTab', queueCategory());
    }

    handleSortQueueCategory(selectedQueueCategory) {
        this.patientQueues[selectedQueueCategory].sort((a,b) => a?.patientProfileSummary?.currentCampaignData?.queue?.timestamp > b?.patientProfileSummary?.currentCampaignData?.queue?.timestamp ? 1 : -1);
        this.queueCategorySorted[selectedQueueCategory] = true;
    }
}
