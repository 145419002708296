import { LitElement, customElement, html, css, property, internalProperty } from 'lit-element';

// models
import { User } from '../models/user';

//TODO(muz): this can be common
@customElement('userbox-header')
export class UserBoxHeader extends LitElement {

  @property({attribute: false})
  user:User = null

  @internalProperty()
  showCard:Boolean = false

  static get styles() {
    return css`      
      #userInfoContainer {
        display: flex;
        justify-content: flex-end;        
      }

      #userInfo {
        padding-right:10px;
        padding-left:10px;
        height:40px;
        display: flex;
      }

      #userInfo:hover {
        background-color: #ccc;
        cursor:pointer;
      }

      #avatar {
        height:20px;
        padding-top: 10px;
      }

      #username {
        margin-left: 5px; 
        display: inline;  
        margin-top: 0px;
        padding-top: 12px;
      }

      #userCard {
        width:200px; 
        padding:10px;
        background-color:white;
        height:100px;
        display: flex;
        flex-flow: row;
        font-size:9pt;
        border:solid 1px #444;
        box-shadow: -19px 16px 28px -24px rgba(117,115,117,1);
        margin-top: -1px;
      }

      #userCard img {
        width:50px; 
      }

      #leftCol {
        display: flex;
        flex-direction: column;
        height:100%;
      }

      #leftCol #signout{
        display: flex;
        flex-grow: 4;
        justify-content: flex-end;
        flex-direction: column;
        color: navy;
      }

      #userCard a{
       text-decoration:none;
      }

      #userCard a:hover{
        text-decoration:underline;
      }

      #rightCol {
        padding-left:5px;
        color: black;
      }

      #userSummary {
        font-weight:bold;
      }

      #options {
        margin-top: 5px;
      }

    `;
  }

  render() {
    const isAuthed = this.user != null ? "auth" : "unauth";

    if (!isAuthed) {
      return html``
    }

    var card = this.buildCard();
    return html`      
      <div id="userInfoContainer">
        <div id="userInfo" @click=${this.toggleCard}>
          <!-- <img id="avatar" src="/media/user_icon.png" /> -->
          <div id="username"> 
            ${this.user.name}
          </div>
        </div>
      </div>
      ${card}
    `;
  }  

  buildCard() {
    if (!this.showCard) {
      return html``
    }

    // var cardImageUri = "/media/logo.png"
    // if (this.user.profileImageUri) {
    //   cardImageUri = this.user.profileImageUri
    // }
    
    // <img id="cardLogo" src="${cardImageUri}" /> 
    return html`
      <div id="userCard">
        <div id="leftCol">
          <div id="signout">
            <a href="#" @click=${this.signOut}>sign out</a>
          </div>
        </div>
        <div id="rightCol">
          <div id="userSummary">
            ${this.user.name}
          </div>
          <div id="email">
            muz.ahmed@gmail.com
          </div>
          <div id="options">
            <a href="#">account settings</a>
          </div>
        </div>        
      </div>`;
  }

  toggleCard() {
    this.showCard = !this.showCard;
    this.requestUpdate();
  }

  signOut(e:any) {
    
    e.preventDefault();
  }
}