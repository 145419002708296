// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

// Themes
import { themePrimaryBlue, themeSecondaryBlue, themeHoverGrey, themeLightGreyBorders } from '../../styles/themeColors';

// Models
import { Visit } from '../../models/visit';

// Components
import './visitListItem.ts'

// Translation
import i18next from 'i18next';

@customElement('visit-list')
export class VisitList extends LitElement {

    static get styles() {
        return css`

            .visits-list {
                background-color: #fff;
                border: 1px solid ${themeLightGreyBorders};
                border-top: none;
                height:100%;
            }

            .visit-list-label {
                padding: 15px;
            }

            mwc-button {
                --mdc-theme-primary: ${themePrimaryBlue};
            }

            .newVisit {
                display: grid;
                align-content: center;
                min-height: 60px;
            }

            @media (max-width: 768px) {
                .visits-list {
                    display: none;
                }
            }
        `
        ;
    }

    @property({attribute: false})
    visits: Array<Visit>;

    @property()
    selectedVisitID: string; //patientCampaignID

    render() {
        return html`
            <div class="visits-list">
                <div class="newVisit">
                    <mwc-button 
                        label="${i18next.t('newVisit').toString()}"
                        icon="add"
                        @click="${this._addNewVisit}">
                    </mwc-button>
                </div>

                ${this.visits.map(visit =>
                    html`
                        <visit-list-item .visit=${visit} ?selected=${visit.patientCampaignID === this.selectedVisitID}></visit-list-item>
                    `
                )}
            </div>
        `;
    }

    _addNewVisit() {
        this.dispatchEvent(new CustomEvent('add-new-visit', {
            bubbles: true,
            composed: true
        }))
    }

}