// Foundation
import { LitElement, customElement, html, css, property } from 'lit-element';

import '@vaadin/vaadin-checkbox/vaadin-checkbox';

@customElement('backpackemr-checkbox')
export class CustomCheckbox extends LitElement {

    static get styles() {
        return css`            
            .container {
                display: grid;
            }

            @media (max-width: 768px) {

            }
        `
        ;
    }

    @property({type: Boolean, attribute: true})
    disabled:boolean = false;

    @property({type: String, attribute: true})
    label:string = "";

    @property({type: Boolean, attribute: true})
    checked:boolean = false;

    render() {
        return html`
            <div class="container">
                <vaadin-checkbox 
                    ?checked="${this.checked}"
                    ?disabled="${this.disabled}"
                    @change="${this._checkChanged}"
                >
                    ${this.label}
                </vaadin-checkbox>
            </div>
        `;
    }

    _checkChanged(event) {
        if(event?.target){
            this.dispatchEvent(new CustomEvent('check-changed', {
                bubbles: true,
                composed: true 
            }));
        }
    }
}