import { css } from 'lit-element';

export const themePrimaryBlue = css`#006D8B`;
export const themePrimaryBlueHex = `#006D8B`;
export const themePrimaryBlueDisabledHex = `rgb(0, 109, 140, 0.6)`;

export const themeSecondaryBlue = css`#189CA7`;
export const themeLightGreyBorders = css`#C3C3C3`;
export const themeHoverGrey = css`#F4F4F4`;

export const themeDarkGrey = css`#333333`;
export const themeMediumGrey = css`#666666`;
export const themeLightGrey = css`#999999`;

export const themeRed = css`#c10000`;