// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

//components
import '@vaadin/vaadin-text-field/vaadin-number-field';

@customElement('backpackemr-number-field')
export class CustomNumberField extends LitElement {

    static get styles() {
        return css`
            .container {
                display: grid;
            }
            
            vaadin-number-field {
                width: 100%;
            }

            /* @media (max-width: 600px) {
                vaadin-number-field {
                    width: fit-content;
                }
            } */
        `
        ;
    }

    @property({type: String, attribute: true})
    value:string;
    
    @property({type: Boolean, attribute: true})
    disabled:boolean = false;

    @property({type: String, attribute: true})
    label:string = '';

    @property({type: String, attribute: true})
    placeholder:string = '';
    
    @property()
    suffix:string = '';

    @property({type: Number})
    itemIndex:number

    render() {        
        return html`
            <div class="container">
                <vaadin-number-field 
                    label="${this.label}"
                    value="${this.value}"
                    placeholder="${this.placeholder}"
                    ?readonly="${this.disabled}"
                    @input=${this.textChanged}>
                    ${this.suffix 
                        ? html`<div slot="suffix">${this.suffix}</div>`
                        : html``
                    }
                </vaadin-number-field>
            </div>
        `;
    }

    textChanged(event) {
        if(event?.target){
            this.dispatchEvent(new CustomEvent('text-field-changed', {
                detail: {
                    value: event.target.value,
                    itemIndex: this.itemIndex
                },
                bubbles: true,
                composed: true 
            }));
        }
    }
}