import axios from 'axios'
import logger, { Component } from "../utils/logger";
import config from '../utils/config';

// models
import { User } from "../models/user";

const apiUrl = config.getConfig("api.url", 'http://localhost:1337');
const loginUrl = `${apiUrl}/login`
// logger.info(Component.Service, `using login endpoint at: ${loginUrl}`)

interface Token {
    key: string
    expiration: Date
}

class Authenticator {
    static readonly USER_KEY = "web_admin_user";
    static readonly TOKEN_KEY = "web_admin_token";

    isAuthenticated(): Boolean {
        var token = this.getToken();
        // logger.debug(Component.Auth, "got token")
        return this.validateToken(token);
    }

    getAuthenticatedUser(): User {
        if (!this.isAuthenticated()) {
            logger.debug(Component.Auth, "no user authenticated")
            return null;
        }
        // logger.debug(Component.Auth, "returning authenticated user")
        return this.getUser();
    }

    async authenticate(username:string, password:string): Promise<Boolean> {
        try {
            const resp = await axios.post(
                loginUrl,
                {
                    username: username,
                    password: password,
                }
            );

            const authenticated = resp.status == 200 && resp.data.user;
            if (authenticated) {
                logger.info(Component.Auth, "successfully authenticated");
                this.storeToken({
                    key:resp.data.token,
                    expiration: new Date(resp.data.tokenExpiration)
                });
                this.storeUser({
                    name: resp.data.user.firstName + ' ' + resp.data.user.lastName,
                    profileImageUri: "",
                    email: username,
                    campaignList: resp.data.campaigns,
                    userID: resp.data.user.id
                });
            }
            return authenticated;
        } catch(e) {
            console.log("error: ", e);
            return false;
        }
    }

    async logout() {
        logger.info(Component.Auth, "logged out");
        sessionStorage.removeItem(Authenticator.USER_KEY);
        sessionStorage.removeItem(Authenticator.TOKEN_KEY);
    }

    private validateToken(token:Token) : Boolean {
        return token != null && token.expiration > new Date();
    }

    private storeToken(token:Token) {
        sessionStorage.setItem(Authenticator.TOKEN_KEY, JSON.stringify(token));
    }

    private storeUser(user:User) {
        sessionStorage.setItem(Authenticator.USER_KEY, JSON.stringify(user));
    }

    getToken():Token {
        return JSON.parse(
            sessionStorage.getItem(Authenticator.TOKEN_KEY),
            (name, value) => {
                if (name == "expiration") {
                    return new Date(value);
                }
                return value;
            }
        );
    }

    getUser():User {
        return JSON.parse(sessionStorage.getItem(Authenticator.USER_KEY));
    }
}

export default new Authenticator()