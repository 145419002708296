// Foundation
import { LitElement, customElement, html, property, css, internalProperty } from 'lit-element';
import * as moment from 'moment';

// Components
import '@material/mwc-button';
import '../common/profilePhoto';

import CurrentPatient from '../../models/currentPatient';
import { currentTabDetailChangesVar, currentPatientProfileDataChangesVar, currentPatientQueueDataChangesVar  } from '../../api/graphQL';
import { isEmpty } from 'lodash';

import { analyzeMedicalTabUpdateResp } from '../../utils/medicalTabUpdateRespAnalysis';

// Themes
import { themePrimaryBlue } from '../../styles/themeColors';
import { Router } from '@vaadin/router';

// Models
import { Campaign } from '../../models/campaignDetails';

//Utilities
import i18next from 'i18next';
import { getTimeSinceDate, formatDate, formatAgeAndBirthDate } from '../../utils/dateTimeFormatter';
@customElement('patient-chart-summary')
export class PatientChartSummary extends LitElement {
    static get styles() {
        return css`
            * {
                margin: 0;
                padding: 0;
            }

            mwc-button {
                --mdc-theme-primary: ${themePrimaryBlue};
                --mdc-button-outline-color: ${themePrimaryBlue};
            }

            /* Patient summary card */
            .patient-summary-container {
                display: grid;
                grid-template-columns: 60px auto 1fr auto;
                grid-column-gap: 16px;
                grid-template-areas:
                    "patient-photo patient-summary-name patient-summary-demographics . patient-summary-profile-link";
                align-items: center;
                padding: 8px 12px;
                border: 1px solid #C3C3C3;
                background-color: #fff;
                font-size: 14px;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            }

            .patient-photo {
                grid-area: patient-photo;
                display: grid;
            }

            .patient-summary-name {
                grid-area: patient-summary-name;
                display: grid;
                grid-gap: 10px;
                align-items: center;
                font-size: 20px;
            }

            .patient-summary-demographics {
                grid-area: patient-summary-demographics
            }

            .patient-summary-profile-link {
                grid-area: patient-summary-profile-link;
            }

            .patient-summary-treatment {
                grid-area: patient-summary-treatment;
                text-align: right;
            }



             /* tablet */
             @media (max-width: 768px) {

                .main-content {
                    display: grid;
                    grid-template-columns: 1fr;
                }

                /* Patient summary card */
                .patient-summary-container {
                    display: grid;
                    grid-template-columns: auto 1fr auto;
                    grid-column-gap: 16px;
                    grid-template-areas:
                        "patient-photo patient-summary-name"
                        "patient-photo patient-summary-demographics"
                        "patient-photo patient-summary-profile-link"
                        ;
                    align-items: start;
                    grid-gap: 8px;
                    padding: 8px 12px;
                    border: 1px solid #C3C3C3;
                    border-top: none;
                    background-color: #fff;
                    font-size: 14px;
                }

                
             }

        `
        ;
    }

    @property({attribute: false})
    patientProfileSummary: any;

    @property({attribute: false })
    patientID: string;

    @property({attribute: false})
    currentCampaignData: Campaign;

    render() {
        return html`
            <div class="patient-summary-container">
                <div class="patient-photo">
                    <profile-photo .photo="${this.patientProfileSummary?.profilePhoto}"></profile-photo>
                </div>
                <div class="patient-summary-name">
                    ${this.formatName()}
                </div>
                <div class="patient-summary-demographics">
                    ${this.formatDemographics()}
                </div>
                <div class="patient-summary-profile-link">
                    <mwc-button 
                        outlined  
                        label="${i18next.t('viewProfile').toString()}" 
                        @click="${this._handleViewProfile}">
                    </mwc-button>
                </div>
                
            </div>
            
        `;
    }

    formatName(){
        if(this.patientProfileSummary?.firstName || this.patientProfileSummary?.lastName) {
            const firstName = this.patientProfileSummary.firstName? `${this.patientProfileSummary.firstName} ` : '';
            const lastName = this.patientProfileSummary.lastName? `${this.patientProfileSummary.lastName}` : '';
            return html`${firstName}${lastName}`;
        } else {
            return html`${i18next.t('addName')}`;  //TODO - display a button for "Add Name" and take them to the patient profile screen
        }
    }

    formatDemographics() {
        const gender = this.patientProfileSummary.gender? `${i18next.t(`${this.patientProfileSummary.gender.toLowerCase()}`).toString()}` : '';
        const ageAndBirthdate = this.patientProfileSummary.dateOfBirth? `${formatAgeAndBirthDate(this.patientProfileSummary.dateOfBirth)}` : '';
        const humanId = this.patientProfileSummary.humanID? `${i18next.t('humanId').toString()}: ${this.patientProfileSummary.humanID}` : '';

        if(gender && ageAndBirthdate && humanId) return html`${gender} • ${ageAndBirthdate} • ${humanId}`;
        else if(gender && !ageAndBirthdate && !humanId) return html`${gender}`;
        else if(!gender && ageAndBirthdate && !humanId) return html`${ageAndBirthdate}`;
        else if(!gender && !ageAndBirthdate && humanId) return html`${humanId}`;
        else if(gender && ageAndBirthdate && !humanId) return html`${gender} • ${ageAndBirthdate}`;
        else if(gender && !ageAndBirthdate && humanId) return html`${gender} • ${humanId}`;
        else if(!gender && ageAndBirthdate && humanId) return html`${ageAndBirthdate} • ${humanId}`;
        else {
            return '';
        }
    }

    async _handleViewProfile() {
        if (!isEmpty(currentPatientQueueDataChangesVar())) {
            const resp = await CurrentPatient.saveQueuedDetails('queue-data');
        }

        if(isEmpty(currentTabDetailChangesVar())) {
            Router.go(`/patients/${this.patientID}/profile`);
        } else {
            const resp = await analyzeMedicalTabUpdateResp();
            this.dispatchEvent(new CustomEvent('saved-queued-details', {
                detail: {
                    success: true,
                    type: 'tab-updated',
                    response: resp
                },
                bubbles: true,
                composed: true
            }));
            Router.go(`/patients/${this.patientID}/profile`);
        }
    }

}