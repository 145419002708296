// Foundation
import { LitElement, customElement, html, property, css } from 'lit-element';
import * as moment from 'moment';

// Themes
import { themePrimaryBlue, themeLightGreyBorders, themeDarkGrey } from '../../styles/themeColors';

import i18next from 'i18next';

@customElement('footer-save-bar')
export class VisitEditStatusBar extends LitElement {

    static get styles() {
        return css`
            * {
                margin: 0;
                padding: 0;
            }

            .content {
                display: grid;
                padding: 8px;
                background-color: #ffffff;
                border: 1px solid ${themeLightGreyBorders};
                box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.2);
            }

            .update-button {
                --mdc-theme-primary: ${themePrimaryBlue};
                --mdc-theme-on-primary: white;
                justify-self: end;
            }

             /* phone */
             @media (max-width: 488px) {
                .content {
                    grid-template-columns: 1fr;
                    padding: 8px 0;
                }

                .update-button {
                    justify-self: stretch;
                }
             }
        `
        ;
    }

    @property({type: Boolean, attribute: true})
    readOnlyMode:boolean;

    @property({type: Boolean, attribute: true})
    disableSaveButton:boolean;

    render() {
        if(!this.readOnlyMode) {
           return html`
                <div class="content">
                    <mwc-button
                        class="update-button"
                        label="${i18next.t("save").toString()}"
                        raised
                        ?disabled="${this.readOnlyMode || this.disableSaveButton}"
                        @click="${() => this.dispatchEvent(new CustomEvent('save', { detail: 'data', bubbles: true, composed: true }))}">
                    </mwc-button>
                </div>
           `
        }
        
    }

}