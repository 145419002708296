export enum PUBLICHEALTH {
  TABNAME = 'PUBLICHEALTH',
  VISIT_MEDICAL_DETAILS_CATEGORY = 'publicHealthDetails',
  UPDATE_MUTATION_NAME = 'updatePublicHealthDetails',
  CREATE_MUTATION_NAME = 'createPublicHealthDetails'
}

export enum COMPLAINTS {
  TABNAME = "COMPLAINTS",
  VISIT_MEDICAL_DETAILS_CATEGORY = "complaintDetails",
  UPDATE_MUTATION_NAME = "updateComplaintDetails",
  CREATE_MUTATION_NAME = 'createComplaintDetails'
}

export enum HISTORY {
  TABNAME = "HISTORY",
  VISIT_MEDICAL_DETAILS_CATEGORY = "historyDetails",
  UPDATE_MUTATION_NAME = "updateHistoryDetails",
  CREATE_MUTATION_NAME = 'createHistoryDetails'
}

export enum VITALS {
  TABNAME = 'VITALS',
  VISIT_MEDICAL_DETAILS_CATEGORY = 'vitalsDetails',
  UPDATE_MUTATION_NAME = 'updateVitalsDetails',
  CREATE_MUTATION_NAME = 'createVitalsDetails'
}

export enum LABS {
  TABNAME = "LABS",
  VISIT_MEDICAL_DETAILS_CATEGORY = "labsDetails",
  UPDATE_MUTATION_NAME = "updateLabsDetails",
  CREATE_MUTATION_NAME = "createLabsDetails"
}

export enum VISION {
  TABNAME = 'VISION',
  VISIT_MEDICAL_DETAILS_CATEGORY = 'visionDetails',
  UPDATE_MUTATION_NAME = 'updateVisionDetails',
  CREATE_MUTATION_NAME = 'createVisionDetails'
}

export enum EXAM {
  TABNAME = 'EXAM',
  VISIT_MEDICAL_DETAILS_CATEGORY = 'examDetails',
  UPDATE_MUTATION_NAME = 'updateExamDetails',
  CREATE_MUTATION_NAME = 'createExamDetails'
}

export enum PHARMACY {
  TABNAME = 'PHARMACY',
  VISIT_MEDICAL_DETAILS_CATEGORY = 'examDetails',
  UPDATE_MUTATION_NAME = 'updateExamDetails',
  CREATE_MUTATION_NAME = ''
}

export enum VIRTUALCARE {
  TABNAME = 'VIRTUALCARE',
  VISIT_MEDICAL_DETAILS_CATEGORY = 'virtualCareDetails',
  UPDATE_MUTATION_NAME = 'updateVirtualCareDetails',
  CREATE_MUTATION_NAME = 'createVirtualCareDetails'
}
