import i18next from "i18next";

export function generateTranslatedList(list:Array<string>):Array<any> {

    let translatedList = list.map( item => {
        return {
            key: item,
            value: i18next.t(item)
        }
    });

    // sorts the list of objects based on the value from the key in the specified user language.
    return translatedList.sort((a,b) => (a.value > b.value ? 1 : -1));

}

export const LANG_KEY = 'backpackEMR-lng'