// Foundation
import { LitElement, customElement, html, internalProperty, css, property, query } from 'lit-element';

// Themes
import { themeLightGrey } from '../../../styles/themeColors';

// Models
import { VitalsDetails, unitOfMeasure } from '../../../models/clinical/vitals';

// Components
import '../medicalTabHeader.ts';
import '../../common/customTextField';
import '../../common/customTextArea';
import '../../common/customNumberField';
import '../../graphs/growthChart';

//translations
import i18next from 'i18next';

@customElement('tab-vitals')
export class TabVitals extends LitElement {
	static get styles() {
		return css`
			.content {
				display: grid;
				/* margin-bottom: 20px; */
			}

			.row {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-column-gap: 16px;
				align-items: center;
			}

			.two-column-section {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-column-gap: 80px;
			}

			.bmi-section {
				display: grid;
				grid-template-columns: 1fr 1fr;
			}

			.oneColumnRow {
				display: grid;
				grid-template-columns: 1fr;
				padding-bottom: 16px;
			}

			.rowThreeColumns {
				display: grid;
				grid-template-columns: 1fr 1fr auto;
				grid-column-gap: 16px;
				align-items: center;
			}

			.bloodPressureRow {
				display: grid;
				grid-template-columns: 1fr auto 1fr;
				grid-column-gap: 16px;
				align-items: center;
			}

			.conversion {
				display: inline-block;
				color: ${themeLightGrey};
				font-size: 14px;
			}
			mwc-icon-button {
				color: tomato;
				align-self: end;
				--mdc-icon-size: 32px;
			}

			@media (max-width: 1100px) {
				.content {
					display: grid;
					grid-template-columns: 1fr;
				}

				.two-column-section {
					display: grid;
					grid-template-columns: 1fr;
					grid-column-gap: 0;
				}
			}
		`;
	}

	@query('#growth-chart')
	_growthChart;

	@property({ attribute: false })
	details: VitalsDetails;

	@internalProperty()
	readOnlyMode: boolean = true;

	@property()
	patientID: string = '';

	@property()
	visitID: string = '';

	@property()
	campaignID: string = '';

	firstUpdated() {
		if (!this.details) {
			this.details = {
				type: 'patient-primaryCareVitals',
				campaignId: this.campaignID,
				channels: [this.campaignID],
				patientId: this.patientID,
				updatedAt: null,

				armCircumferenceCm: null,
				armCircumferenceIn: null,
				bloodPressurediastolic: null,
				bloodPressureSystolic: null,
				bmi: null,
				headCircumferenceCm: null,
				headCircumferenceIn: null,
				heartRate: null,
				heightCm: null,
				heightIn: null,
				o2Saturation: null,
				respiratoryRate: null,
				temperatureC: null,
				temperatureF: null,
				vitalsNotes: null,
				weightKg: null,
				weightLbs: null,
				zScoreArmCircumference: null,
				zScoreBmi: null,
				zScoreHeightWeight: null,
			};
		}

		this.readOnlyMode = this.details?._id ? true : false;
	}

	render() {
		if (!this.details) {
			return html`
				<h3>${i18next.t('vitals')}</h3>
				<p>${i18next.t('noVitalsData')}.</p>
			`;
		}

		return html`
			<div class="content">
				<medical-tab-header tabName="${i18next.t('vitals').toString() || ''}" .updatedDateTime="${this.details?.updatedAt}" ?readOnlyMode="${this.readOnlyMode}" @edit-content="${this._handleEditContent}"> </medical-tab-header>

				<div class="two-column-section">
					<div class="left-side">
						<div class="row">
							<backpackemr-number-field label="${i18next.t('temperature').toString()}" suffix="°F" value="${this.details?.temperatureF}" @text-field-changed="${(event: CustomEvent) => this._handleTemperatureValueChange(event.detail.value, unitOfMeasure.imperial)}" ?disabled="${this.readOnlyMode}"> </backpackemr-number-field>
							<backpackemr-number-field label="${i18next.t('temperature').toString()}" suffix="°C" value="${this.details?.temperatureC}" @text-field-changed="${(event: CustomEvent) => this._handleTemperatureValueChange(event.detail.value, unitOfMeasure.metric)}" ?disabled="${this.readOnlyMode}"> </backpackemr-number-field>
						</div>
						<div class="row">
							<backpackemr-number-field label="${i18next.t('weight').toString()}" suffix="lbs" value="${this.details?.weightLbs}" @text-field-changed="${(event: CustomEvent) => this._handleWeightValueChange(event.detail.value, unitOfMeasure.imperial)}" ?disabled="${this.readOnlyMode}"> </backpackemr-number-field>
							<backpackemr-number-field label="${i18next.t('weight').toString()}" suffix="kg" value="${this.details?.weightKg}" @text-field-changed="${(event: CustomEvent) => this._handleWeightValueChange(event.detail.value, unitOfMeasure.metric)}" ?disabled="${this.readOnlyMode}"> </backpackemr-number-field>
						</div>
						<div class="row">
							<backpackemr-number-field label="${i18next.t('height').toString()}" suffix="in" value="${this.details?.heightIn}" @text-field-changed="${(event: CustomEvent) => this._handleHeightValueChange(event.detail.value, unitOfMeasure.imperial)}" ?disabled="${this.readOnlyMode}"> </backpackemr-number-field>
							<backpackemr-number-field label="${i18next.t('height').toString()}" suffix="cm" value="${this.details?.heightCm || ''}" @text-field-changed="${(event: CustomEvent) => this._handleHeightValueChange(event.detail.value, unitOfMeasure.metric)}" ?disabled="${this.readOnlyMode}"> </backpackemr-number-field>
						</div>

						<div class="row">
							<backpackemr-number-field label="${i18next.t('headCircumference').toString()}" suffix="in" value="${this.details?.headCircumferenceIn}" @text-field-changed="${(event: CustomEvent) => this._handleHeadCircumferenceChange(event.detail.value, unitOfMeasure.imperial)}" ?disabled="${this.readOnlyMode}"> </backpackemr-number-field>
							<backpackemr-number-field label="${i18next.t('headCircumference').toString()}" suffix="cm" value="${this.details?.headCircumferenceCm}" @text-field-changed="${(event: CustomEvent) => this._handleHeadCircumferenceChange(event.detail.value, unitOfMeasure.metric)}" ?disabled="${this.readOnlyMode}"> </backpackemr-number-field>
						</div>
						<div class="row">
							<backpackemr-number-field label="${i18next.t('armCircumference').toString()}" suffix="in" value="${this.details?.armCircumferenceIn}" @text-field-changed="${(event: CustomEvent) => this._handleArmCircumferenceChange(event.detail.value, unitOfMeasure.imperial)}" ?disabled="${this.readOnlyMode}"> </backpackemr-number-field>
							<backpackemr-number-field label="${i18next.t('armCircumference').toString()}" suffix="cm" value="${this.details?.armCircumferenceCm}" @text-field-changed="${(event: CustomEvent) => this._handleArmCircumferenceChange(event.detail.value, unitOfMeasure.metric)}" ?disabled="${this.readOnlyMode}"> </backpackemr-number-field>
						</div>
						<div class="rowThreeColumns"></div>
					</div>

					<div class="right-side">
						<div class="row">
							<backpackemr-number-field
								label="${i18next.t('heartRate').toString()}"
								suffix="bpm"
								value="${this.details?.heartRate}"
								@text-field-changed="${(event: CustomEvent) => {
									this.details.heartRate = event.detail.value;
									this.tabDetailsUpdated();
								}}"
								?disabled="${this.readOnlyMode}"
							>
							</backpackemr-number-field>
						</div>
						<div class="bloodPressureRow">
							<backpackemr-number-field
								label="${i18next.t('bloodPressure').toString()}"
								suffix="${i18next.t('systolic').toString()}"
								value="${this.details?.bloodPressureSystolic}"
								@text-field-changed="${(event: CustomEvent) => {
									this.details.bloodPressureSystolic = event.detail.value;
									this.tabDetailsUpdated();
								}}"
								?disabled="${this.readOnlyMode}"
							>
							</backpackemr-number-field>
							/
							<backpackemr-number-field
								label="${i18next.t('bloodPressure').toString()}"
								suffix="${i18next.t('diastolic').toString()}"
								value="${this.details?.bloodPressurediastolic}"
								@text-field-changed="${(event: CustomEvent) => {
									this.details.bloodPressurediastolic = event.detail.value;
									this.tabDetailsUpdated();
								}}"
								?disabled="${this.readOnlyMode}"
							>
							</backpackemr-number-field>
						</div>
						<div class="row">
							<backpackemr-number-field
								label="${i18next.t('o2Saturation').toString()}"
								suffix="%"
								value="${this.details?.o2Saturation}"
								@text-field-changed="${(event: CustomEvent) => {
									this.details.o2Saturation = event.detail.value;
									this.tabDetailsUpdated();
								}}"
								?disabled="${this.readOnlyMode}"
							>
							</backpackemr-number-field>
							<backpackemr-number-field
								label="${i18next.t('respiratoryRate').toString()}"
								suffix="bpm"
								value="${this.details?.respiratoryRate}"
								@text-field-changed="${(event: CustomEvent) => {
									this.details.respiratoryRate = event.detail.value;
									this.tabDetailsUpdated();
								}}"
								?disabled="${this.readOnlyMode}"
							>
							</backpackemr-number-field>
						</div>
					</div>
				</div>
				<!-- BMI Area -->
				<div class="bmi-section">
					<div class="left-side">
						<div class="rowThreeColumns">
							<backpackemr-number-field label="${i18next.t('bmi').toString()}" value="${this.details?.bmi}" ?disabled="${true}"> </backpackemr-number-field>
							<backpackemr-number-field
								label="${i18next.t('zScoreBmi').toString()}"
								value="${this.details?.zScoreBmi}"
								@text-field-changed="${(event: CustomEvent) => {
									this.details.zScoreBmi = event.detail.value;
									this.tabDetailsUpdated();
								}}"
								?disabled="${this.readOnlyMode}"
							>
							</backpackemr-number-field>
						</div>
					</div>

					<div class="right-side">
						<div class="rowThreeColumns">
							<backpackemr-number-field
								label="${i18next.t('zScoreHeightWeight').toString()}"
								value="${this.details?.zScoreHeightWeight}"
								@text-field-changed="${(event: CustomEvent) => {
									this.details.zScoreHeightWeight = event.detail.value;
									this.tabDetailsUpdated();
								}}"
								?disabled="${this.readOnlyMode}"
							>
							</backpackemr-number-field>
							<backpackemr-number-field
								label="${i18next.t('zScoreArmCircumference').toString()}"
								value="${this.details?.zScoreArmCircumference}"
								@text-field-changed="${(event: CustomEvent) => {
									this.details.zScoreArmCircumference = event.detail.value;
									this.tabDetailsUpdated();
								}}"
								?disabled="${this.readOnlyMode}"
							>
							</backpackemr-number-field>
						</div>
					</div>
				</div>
				<!-- Notes Area -->
				<div class="oneColumnRow">
					<backpackemr-text-area
						label="${i18next.t('notes').toString()}"
						placeholder="${i18next.t('notes').toString()}"
						?disabled="${this.readOnlyMode}"
						.value="${this.details?.vitalsNotes}"
						@text-changed="${(event: CustomEvent) => {
							this.details.vitalsNotes = event.detail.value;
							this.tabDetailsUpdated();
						}}"
					>
					</backpackemr-text-area>
				</div>
			</div>
		`;
	}

	_handleEditContent() {
		this.readOnlyMode = !this.readOnlyMode;
	}

	_handleTemperatureValueChange(input: string, unit: string) {
		if (input === '') {
			this.details.temperatureC = this.details.temperatureF = '';
		}
		if (unit === unitOfMeasure.imperial && input !== '') {
			this.details.temperatureF = input;
			this.details.temperatureC = this.convertTemperature(input, unit);
		} else if (unit === unitOfMeasure.metric && input !== '') {
			this.details.temperatureC = input;
			this.details.temperatureF = this.convertTemperature(input, unit);
		}

		this.requestUpdate();
		this.tabDetailsUpdated();
	}

	_handleWeightValueChange(input: string, unit: string) {
		if (input === '') {
			this.details.weightKg = this.details.weightLbs = '';
		}
		if (unit === unitOfMeasure.imperial && input !== '') {
			this.details.weightLbs = input;
			this.details.weightKg = this.convertWeight(input, unit);
		} else if (unit === unitOfMeasure.metric && input !== '') {
			this.details.weightKg = input;
			this.details.weightLbs = this.convertWeight(input, unit);
		}
		this.details.bmi = this.calculateBmi(this.details.weightKg, this.details.heightCm);

		this.requestUpdate();
		this.tabDetailsUpdated();
	}

	_handleHeightValueChange(input: string, unit: string) {
		if (input === '') {
			this.details.heightCm = this.details.heightIn = '';
		}
		if (unit === unitOfMeasure.imperial && input !== '') {
			this.details.heightIn = input;
			this.details.heightCm = this.convertLengthMeasurement(input, unit);
		} else if (unit === unitOfMeasure.metric && input !== '') {
			this.details.heightCm = input;
			this.details.heightIn = this.convertLengthMeasurement(input, unit);
		}
		this.details.bmi = this.calculateBmi(this.details.weightKg, this.details.heightCm);

		this.requestUpdate();
		this.tabDetailsUpdated();
	}

	_handleHeadCircumferenceChange(input: string, unit: string) {
		if (input === '') {
			this.details.headCircumferenceCm = this.details.headCircumferenceIn = '';
		}
		if (unit === unitOfMeasure.imperial && input !== '') {
			this.details.headCircumferenceIn = input;
			this.details.headCircumferenceCm = this.convertLengthMeasurement(input, unit);
		} else if (unit === unitOfMeasure.metric && input !== '') {
			this.details.headCircumferenceCm = input;
			this.details.headCircumferenceIn = this.convertLengthMeasurement(input, unit);
		}

		this.requestUpdate();
		this.tabDetailsUpdated();
	}

	_handleArmCircumferenceChange(input: string, unit: string) {
		if (input === '') {
			this.details.armCircumferenceCm = this.details.armCircumferenceIn = '';
		}
		if (unit === unitOfMeasure.imperial && input !== '') {
			this.details.armCircumferenceIn = input;
			this.details.armCircumferenceCm = this.convertLengthMeasurement(input, unit);
		} else if (unit === unitOfMeasure.metric && input !== '') {
			this.details.armCircumferenceCm = input;
			this.details.armCircumferenceIn = this.convertLengthMeasurement(input, unit);
		}

		this.requestUpdate();
		this.tabDetailsUpdated();
	}

	// converts between Farenheit and Celsius
	convertTemperature(value: string, unit: string): string {
		let convertedTemp: number;
		if (unit === unitOfMeasure.imperial) {
			convertedTemp = Math.round((parseInt(value) - 32) * (5 / 9));
		} else if (unit === unitOfMeasure.metric) {
			convertedTemp = Math.round(parseInt(value) * (9 / 5) + 32);
		}
		return convertedTemp.toString();
	}

	//converts between pounds and kg
	convertWeight(value: string, unit: string): string {
		const kgInAPound: number = 0.453592;
		let convertedWeight: number;
		if (unit === unitOfMeasure.imperial) {
			convertedWeight = parseFloat(value) * kgInAPound;
		} else if (unit === unitOfMeasure.metric) {
			convertedWeight = parseFloat(value) / kgInAPound;
		}
		return convertedWeight.toFixed(1).toString();
	}

	// converts between inches and cm
	convertLengthMeasurement(value: string, unit: string): string {
		const centimetersInAnInch: number = 2.54;
		let convertedLength: number;
		if (unit === unitOfMeasure.imperial) {
			convertedLength = parseFloat(value) * centimetersInAnInch;
		} else if (unit === unitOfMeasure.metric) {
			convertedLength = parseFloat(value) / centimetersInAnInch;
		}
		return convertedLength.toFixed(1).toString();
	}

	calculateBmi(mass: string, height: string): string {
		if (mass === '' || height === '') return '';

		const massKg = parseFloat(mass);
		const heightInMeters = parseFloat(height) / 100;
		const bmi: number = massKg / (heightInMeters * heightInMeters);
		return bmi.toFixed(1).toString();
	}

	getUpdateMutationVariables() {
		const updatedVitalsInfo: VitalsDetails = {
			_id: this.details?._id,
			_rev: this.details?._rev,
			campaignId: this.details.campaignId,
			channels: this.details.channels,
			patientId: this.details.patientId,
			updatedAt: new Date().toISOString(),
			type: this.details.type,
			armCircumferenceCm: this.details.armCircumferenceCm,
			armCircumferenceIn: this.details.armCircumferenceIn,
			bloodPressureSystolic: this.details.bloodPressureSystolic,
			bloodPressurediastolic: this.details.bloodPressurediastolic,
			bmi: this.details.bmi,
			headCircumferenceCm: this.details.headCircumferenceCm,
			headCircumferenceIn: this.details.headCircumferenceIn,
			heartRate: this.details.heartRate,
			heightCm: this.details.heightCm,
			heightIn: this.details.heightIn,
			o2Saturation: this.details.o2Saturation,
			respiratoryRate: this.details.respiratoryRate,
			temperatureC: this.details.temperatureC,
			temperatureF: this.details.temperatureF,
			vitalsNotes: this.details.vitalsNotes,
			weightKg: this.details.weightKg,
			weightLbs: this.details.weightLbs,
			zScoreArmCircumference: this.details.zScoreArmCircumference,
			zScoreBmi: this.details.zScoreBmi,
			zScoreHeightWeight: this.details.zScoreHeightWeight,
		};

		return {
			tab: 'VITALS',
			id: this.details?._id,
			visitID: this.visitID,
			tabDetails: updatedVitalsInfo,
		};
	}

	tabDetailsUpdated() {
		this.dispatchEvent(
			new CustomEvent('tab-info-updated', {
				detail: {
					variables: this.getUpdateMutationVariables(),
				},
				bubbles: true,
				composed: true,
			})
		);
	}
}
