import { gql } from '@apollo/client/core';

export const updatePatientProfile = gql`
    mutation UpdatePatientProfile($id: String!, $input: PatientProfileInput!) {
      updatePatientProfile(id: $id, input: $input){
        code,
        success,
        message,
        refreshedDetails {
          patientID,
          updatedAt,
          patientProfileSummary { 
              firstName, 
              lastName, 
              gender, 
              governmentID, 
              humanID, 
              dateOfBirth, 
              profilePhoto {
                  fileName
                  signedURL
              },
              currentCampaignData {
                  clinicNumber,
                  queue {
                      queueId,
                      status,
                      timestamp,
                  },
              }
          },
          patientProfileDetails {
              middleName,
              occupation,
              phoneNumber,
              contactName,
              email,
              addressLine1,
              addressLine2,
              addressLine3,
              insuranceType,
              insuranceID,
              patientConsented,
              patientConsentedUserDate,
              archived,
          }
        }
      }
}
`;

export const createNewPatient = gql`
  mutation CreateNewPatient($input: PatientProfileInput!) {
    createNewPatient(input: $input){
      code,
      success,
      message,
      refreshedDetails{
        newPatientID
      }
    }
  }` 