import {
	css,
	customElement,
	html,
	LitElement,
	property,
	query,
} from 'lit-element';

@customElement('backpackemr-growth-chart')
export class GrowthChart extends LitElement {
	static get styles() {
		return css``;
	}

	@query('chart')
	_chart;

	@property()
	patientAge: number = 0;

	@property()
	patientWeightIbs: number = 0;

	@property()
	patientHeightIn: number = 0;

	@property()
	width: number;

	@property()
	height: number;

	buildChart(svg) {}

	componentDidLoad() {}

	render() {
		return html`<svg id="chart"></svg>`;
	}
}
