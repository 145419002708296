// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

// Themes
import { themePrimaryBlue, themeSecondaryBlue, themeHoverGrey, themeLightGreyBorders } from '../../styles/themeColors';


// Models 
import { PUBLICHEALTH, COMPLAINTS, HISTORY, VITALS, LABS, VISION, EXAM, PHARMACY, VIRTUALCARE} from '../../models/medicalCareTabs';

// Web Components
import '@material/mwc-tab-bar';
import '@material/mwc-tab';

import i18next from 'i18next';

const tabsByName = {
    [PUBLICHEALTH.TABNAME]: 0,
    [COMPLAINTS.TABNAME]: 1,
    [HISTORY.TABNAME]: 2,
    [VITALS.TABNAME]: 3,
    [LABS.TABNAME]: 4,
    [VISION.TABNAME]: 5,
    [EXAM.TABNAME]: 6,
    [PHARMACY.TABNAME]: 7,
    [VIRTUALCARE.TABNAME]: 8,
}

const tabsByID = {
    0: PUBLICHEALTH.TABNAME,
    1: COMPLAINTS.TABNAME,
    2: HISTORY.TABNAME,
    3: VITALS.TABNAME,
    4: LABS.TABNAME,
    5: VISION.TABNAME,
    6: EXAM.TABNAME,
    7: PHARMACY.TABNAME,
    8: VIRTUALCARE.TABNAME,
}

@customElement('visit-tab-bar')
export class VisitTabBar extends LitElement {

    static get styles() {
        return css`
            * {
                margin: 0;
                padding: 0;
            }

            .tabs-container {
                display: grid;
            }

            .tabs {
                display: grid;
                overflow: scroll;
                background-color: #fff;
                border: 1px solid #C3C3C3;
                border-top: none;
                border-bottom: none;
                max-width: 1050px;
                
                /* horizontal scroll indicators: */
                background:
                    linear-gradient(90deg, white 0%, rgba(255,255,255,0)),
                    linear-gradient(-90deg, white 0%, rgba(255,255,255,0)) 100% 0,
                    radial-gradient(
                        farthest-side at 0% 50%,
                        rgba(0,0,0,.3),
                        rgba(0,0,0,0)
                    ),
                    radial-gradient(
                        farthest-side at 100% 50%,
                        rgba(0,0,0,.3),
                        rgba(0,0,0,0)
                    ) 100% 0%;
                background-repeat: no-repeat;
                background-color: #fff;
                background-size: 100px 137px, 100px 137px, 40px 137px, 40px 137px;
                background-attachment: local, local, scroll, scroll;
            }

            mwc-tab {
                --mdc-theme-primary: ${themePrimaryBlue};       
                --mdc-tab-horizontal-padding: 24px;
            }

            @media (max-width: 768px) {
                .tabs {
                    border: 1px solid #C3C3C3;
                    border-top: none;
                    border-bottom: none;
                }

            }
        `
        ;
    }

    get selectedTabIndex(){
        return tabsByName[this.selectedTabName];
    }

    @property({type: String, attribute: false})
    selectedVisitMedicalModule: string;

    @property({type: String})
    selectedTabName: string = PUBLICHEALTH.TABNAME;

    @internalProperty()
    hasPublicHealthTabDetails:boolean = false;

    @internalProperty()
    hasComplaintTabDetails:boolean = false;
    
    @internalProperty()
    hasHistoryTabDetails:boolean = false;
    
    @internalProperty()
    hasVitalsTabDetails:boolean = false;
    
    @internalProperty()
    hasLabsTabDetails:boolean = false;
    
    @internalProperty()
    hasVisionTabDetails:boolean = false;
    
    @internalProperty()
    hasExamTabDetails:boolean = false;
    
    @internalProperty()
    hasPharmacyTabDetails:boolean = false;
    
    @internalProperty()
    hasTelemedTabDetails:boolean = false;

    render() {
        return html`
            <div class='tabs-container'>
                        <mwc-tab-bar class="tabs" activeIndex="${this.selectedTabIndex}" @MDCTabBar:activated=${this._handleTabChanged}>
                            ${this.selectedVisitMedicalModule === 'primaryCare' ? 
                                html`
                                    <mwc-tab label="${i18next.t('publicHealth').toString()}" ?hasImageIcon=${this.hasPublicHealthTabDetails}>
                                        ${this.hasPublicHealthTabDetails && this.displayIndicator()} 
                                    </mwc-tab>
                                    <mwc-tab label="${i18next.t('complaints').toString()}" ?hasImageIcon=${this.hasComplaintTabDetails}>
                                        ${this.hasComplaintTabDetails && this.displayIndicator()} 
                                    </mwc-tab>
                                    <mwc-tab label="${i18next.t('history').toString()}" ?hasImageIcon=${this.hasHistoryTabDetails}>
                                        ${this.hasHistoryTabDetails && this.displayIndicator()}
                                    </mwc-tab>
                                    <mwc-tab label="${i18next.t('vitals').toString()}" ?hasImageIcon=${this.hasVitalsTabDetails}>
                                        ${this.hasVitalsTabDetails && this.displayIndicator()}
                                    </mwc-tab>
                                    <mwc-tab label="${i18next.t('labs').toString()}" ?hasImageIcon=${this.hasLabsTabDetails}>
                                        ${this.hasLabsTabDetails && this.displayIndicator()}
                                    </mwc-tab>
                                    <mwc-tab label="${i18next.t('vision').toString()}" ?hasImageIcon=${this.hasVisionTabDetails}>
                                        ${this.hasVisionTabDetails && this.displayIndicator()}
                                    </mwc-tab>
                                    <mwc-tab label="${i18next.t('exam').toString()}" ?hasImageIcon=${this.hasExamTabDetails}>
                                        ${this.hasExamTabDetails && this.displayIndicator()}
                                    </mwc-tab>
                                    <mwc-tab label="${i18next.t('pharmacy').toString()}" ?hasImageIcon=${this.hasPharmacyTabDetails}>
                                        ${this.hasPharmacyTabDetails && this.displayIndicator()}
                                    </mwc-tab>
                                    <mwc-tab label="${i18next.t('virtualCare').toString()}" ?hasImageIcon=${this.hasTelemedTabDetails}>
                                        ${this.hasTelemedTabDetails && this.displayIndicator()}
                                    </mwc-tab>
                                ` 
                                : this.displayNonPrimaryCareVisit()}
                        </mwc-tab-bar>
                    

                
            </div>
        `;
    }

    displayNonPrimaryCareVisit() {
        switch(this.selectedVisitMedicalModule) {
            case ('cleftLip') :
                return html`
                    <mwc-tab label="${i18next.t('cleftLipVisit').toString()}"></mwc-tab>
                `
            case ('orthopedic'):
                return html`
                    <mwc-tab label="${i18next.t('orthopedic').toString()}"></mwc-tab>
                `            
        }
    }

    displayIndicator(){
        return html`
            <svg
                slot="icon"
                width="10px"
                height="10px"
                style="margin-bottom:7px;">
                <circle
                    r="5px"
                    cx="5px"
                    cy="5px"
                    color="${themePrimaryBlue}">
                </circle>
            </svg>
        `;
    }

    _handleTabChanged(event){
        if(event?.detail?.index !== this.selectedTabIndex){
            this.dispatchEvent(new CustomEvent('tab-changed', {
                detail: { tabName: tabsByID[event.detail.index] },
                bubbles: true,
                composed: true 
            }));
        }
    }

}