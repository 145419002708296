import { LitElement, customElement, html, property, css, internalProperty } from 'lit-element';
import { Router } from '@vaadin/router';

import * as moment from 'moment';

import { Patient } from '../../models/patient'

import { themeHoverGrey, themeLightGreyBorders, themePrimaryBlue, themeSecondaryBlue } from '../../styles/themeColors';

import '../common/profilePhoto';
import '@vaadin/vaadin-icons';
import '@vaadin/vaadin-lumo-styles/sizing';

// Utility
import { getTimeSinceDate, formatDate, formatAgeAndBirthDate } from '../../utils/dateTimeFormatter';
import i18next from 'i18next';
import { queueCategory, selectedCampaignID } from '../../api/graphQL';
import authenticator from '../../utils/authenticator';
@customElement('patient-list-item')
export class PatientListItem extends LitElement {

    static get styles() {
        return css`
            * {
                font-family: Roboto, Arial, sans-serif; 
                margin: 0;
                padding: 0; 
            }

            .patient-card {
                display: grid;
                grid-template-columns: auto 1fr;
                grid-gap: 12px;
                align-items: center;
                justify-items: start;
                min-height: 50px;
                padding: 12px;
                border: 1px solid ${themeLightGreyBorders};
                border-bottom: none;
                background-color: #fff;
                font-size: 14px;
                cursor: pointer;
            }

            .primary-data {
                display: grid;
                grid-gap: 4px;
                width: 100%;
            }

            .patient-card:hover {
                background-color: ${themeHoverGrey};
            }

            .patient-name {
                font-size: 24px;
            }

            .clinic-number {
                background-color: ${themePrimaryBlue};
                color: #fff;
                padding: 1px 8px 1px 8px;
                font-size: 17px;
                border-radius: 8px;
                margin-right: 8px;
            }

            .clinic-number-patient-name-station-name {
                display: grid;
                grid-template-columns: auto 1fr auto;
                align-items: center;
            }

            .demographics-and-wait-time {
                display: grid; 
                grid-template-columns: 1fr 1fr;
                align-items: center;
            }

            .station-name {
                background-color: ${themeSecondaryBlue};
                color: #fff;
                float: right;
                justify-self: end;
                padding: 2px 16px 2px 16px;
                box-shadow: 3px;
            }

            .waiting-time {
                float: right;
                justify-self: end;
            }

            @media (max-width: 768px) {

            }

            @media (max-width: 501px) {
                .patient-card {
                    border: 1px solid ${themeLightGreyBorders};
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                    align-items: start;
                }
            }
            
            iron-icon {
                --iron-icon-height: 12px;
	            --iron-icon-width: 12px;
            }
        `
        ;
    }

    @property({attribute:false})
    patient: Patient; 

    render() {
        if (!this.patient.patientProfileSummary){
            return html`
                <div class="patient-card">
                    Sorry.. something went wrong
                </div>
            `;
        }

        return html`
            <div class="patient-card" tabindex="1" @click="${this.navigateToPatient}"> 
                <profile-photo .photo="${this.patient?.patientProfileSummary?.profilePhoto}"></profile-photo>
                <div class = "primary-data">
                    <div class = "clinic-number-patient-name-station-name">
                        ${this.formatClinicNumber()}
                        <div class="patient-name">${this.patient?.patientProfileSummary?.firstName} ${this.patient?.patientProfileSummary?.lastName}</div>
                        
                        ${!queueCategory() ? 
                            html`${this.formatStationName()}` 
                            : html`${this.formatPatientStatus()}`}
                    </div>
                    <div class="patientIds">${this.formatIDs()}</div>
                    <div class="demographics-and-wait-time">
                        ${this.formatDemographics()}
                        ${this.formatWaitTime()}
                    </div>
                </div>
            </div>
        `;
    }
    
    navigateToPatient(event) {
        event.preventDefault();
        Router.go(`/patients/${this.patient.patientID}`);
    }


    formatIDs(){
        const humanId = this.patient.patientProfileSummary.humanID? `${i18next.t('humanId').toString()}: ${this.patient.patientProfileSummary.humanID}` : '';
        const governmentID = this.patient.patientProfileSummary.governmentID? `• Gov ID: ${this.patient.patientProfileSummary.governmentID}` : '';
        if(humanId || governmentID){
            return html`${humanId} ${governmentID}`;
        } 
        return '';
    }

    formatDemographics(){
        const gender = this.patient.patientProfileSummary.gender? `${i18next.t(`${this.patient.patientProfileSummary.gender.toLowerCase()}`).toString()}` : '';
        const ageAndBirthDate = formatAgeAndBirthDate(this.patient.patientProfileSummary.dateOfBirth);

        if (gender && ageAndBirthDate){
            return html`<div>${gender} • ${ageAndBirthDate}</div>`;
        } else if (!gender && ageAndBirthDate){
            return html`<div>${ageAndBirthDate}</div>`
        } else if (gender && !ageAndBirthDate){
            return html`<div>${gender}</div>`;
        }
        return html`<div></div>`;
    }
    

    // formatInClinicTreatment(){
    //     if(this.patient?.currentCampaignPatientDetails?.inClinicTreatment){
    //         return html`In-clinic treatment: ${this.patient.currentCampaignPatientDetails.inClinicTreatment}`;
    //     }
    //     return html``;
    // }

    formatClinicNumber() {
        if (this.patient?.patientProfileSummary?.currentCampaignData?.clinicNumber) {
            return html`
                <div class="clinic-number">
                    ${this.patient.patientProfileSummary.currentCampaignData.clinicNumber}
                </div>
                `
        }
        return html``;
    }

    formatStationName() {
        if(this.patient.patientProfileSummary.currentCampaignData && this.patient.patientProfileSummary.currentCampaignData.queue && this.patient.patientProfileSummary.currentCampaignData.queue.queueId){
            return html`
                <div class="station-name">
                    ${this.getStationName()}
                </div>
                `;
        }
        return html``;
    }

    formatPatientStatus() {
        if (this.patient.patientProfileSummary.currentCampaignData.queue.status === "BEING_SEEN") {
            return html`
                <div class="station-name">
                    ${i18next.t('BEING_SEEN')}
                </div>
            `
        }
    }

    formatWaitTime(){
        if(this.patient?.patientProfileSummary?.currentCampaignData?.queue?.timestamp){

            return html`
                <div class = "waiting-time">
                    <iron-icon slot="prefix" icon="vaadin:clock"></iron-icon>
                    ${getTimeSinceDate(new Date(parseInt(this.patient.patientProfileSummary.currentCampaignData.queue.timestamp)).toISOString())}
                </div>
                `;
        } 
        return html``;
    }

    getStationName() {
        const stationId = this.patient?.patientProfileSummary?.currentCampaignData.queue.queueId;

        switch(stationId) {
            case ('primaryCareComplaint'):
                return `${i18next.t('Complaint')}`;
            case ('primaryCareHistory'):
                return `${i18next.t('History')}`;
            case ('primaryCareVitals'):
                return `${i18next.t('Vitals')}`;
            case ('primaryCareLabs'):
                return `${i18next.t('Labs')}`;
            case ('primaryCareGlasses'):
                return `${i18next.t('Vision')}`;
            case ('primaryCareExam'):
                return `${i18next.t('Exam')}`;
            case ('primaryCarePharmacy'):
                return `${i18next.t('Pharmacy')}`;
            case ('primaryCareComplete'):
                return `${i18next.t('Discharge')}`;
            case ('primaryCareVirtualCare'):
                return `${i18next.t('Virtual Care')}`;
            default:
                return
        }
    }
}