// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

// Themes
import { themeSecondaryBlue } from '../../styles/themeColors';

import '@vaadin/vaadin-combo-box';
import '@vaadin/vaadin-item';

@customElement('backpackemr-remove-item-button')
export class RemoveItemButton extends LitElement {

    static get styles() {
        return css`
            /* .container {
                display: grid;
            } */

            mwc-icon-button {
                color: ${themeSecondaryBlue};
            }

            @media (max-width: 768px) {

            }
        `
        ;
    }

    @property({type: Boolean, attribute: true})
    disabled:boolean = false;

    @property({type: Number})
    itemIndex:number

    render() {
        return html`
            <!-- <div class="container"> -->
                <mwc-icon-button
                    icon="cancel"
                    ?disabled="${this.disabled}"
                    @click="${this._removeItem}"
                >
                </mwc-icon-button>
            <!-- </div> -->
        `;
    }

    _removeItem() {
        this.dispatchEvent(new CustomEvent('remove-item', {
            detail: {
                itemIndex: this.itemIndex
            },
            bubbles: true,
            composed: true 
        }));
    }
}