import {customElement, html, internalProperty, css , property, LitElement } from 'lit-element';

import '@material/mwc-icon-button';

import { themePrimaryBlue } from '../../styles/themeColors';

import i18next from 'i18next';

@customElement('pagination-controls')
export class PaginationControls extends LitElement {

    static get styles() {
        return css`
            mwc-button, mwc-icon-button {
                color: ${themePrimaryBlue};
                --mdc-icon-size: 25px;
            }

            .pagination-buttons {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(50px, 5%));
                padding-top: 10px;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
        `
    }

    @property({attribute: false}) 
    pageNumber: number = 1;

    @property({type: Boolean})
    nextPageButtonDisabled: boolean;

    render() {
        return html`
            <div class="pagination-buttons">
                <mwc-icon-button 
                    icon="first_page"
                    @click="${this.firstPageButtonClick}" 
                    ?disabled="${this.pageNumber === 1}"
                ></mwc-icon-button>

                <mwc-icon-button  
                    icon="arrow_back"
                    @click="${this.previousPageButtonClick}" 
                    ?disabled="${this.pageNumber === 1}"
                ></mwc-icon-button>
            
                ${i18next.t('Page')} ${this.pageNumber}
                
                <mwc-icon-button 
                    outlined 
                    icon="arrow_forward"
                    @click="${this.nextPageButtonClick}" 
                    ?disabled="${this.nextPageButtonDisabled}"
                ></mwc-icon-button>

                <mwc-icon-button 
                    outlined 
                    icon="last_page"
                    @click="${this.lastPageButtonClick}" 
                    ?disabled="${true}"
                ></mwc-icon-button>
            </div>
        `
    }

    firstPageButtonClick() {
        this.dispatchEvent(new CustomEvent('first-page-clicked'));
    }

    previousPageButtonClick() {
        this.dispatchEvent(new CustomEvent('previous-page-clicked'));
    }

    nextPageButtonClick() {
        this.dispatchEvent(new CustomEvent('next-page-clicked', {
            detail: {clicked: true},
            bubbles: true,
            composed: true
        }));
    }

    //need to add new query on backend to support this feature. 
    lastPageButtonClick() {
        this.dispatchEvent(new CustomEvent('last-page-clicked'))
    }
}