// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';
import * as _ from 'lodash'
import { query } from 'lit-element/lib/decorators.js';

// Models
import { Campaign } from '../../models/campaignDetails';
import { SORTDIRECTION, SORTFIELD} from '../../models/search';

// Themes
import { themePrimaryBlue } from '../../styles/themeColors';

// Components
import '@vaadin/vaadin-combo-box';
import '@vaadin/vaadin-item';
import '@vaadin/vaadin-text-field';
import '@vaadin/vaadin-icons';
import '@material/mwc-button';
import '@material/mwc-menu';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-icon';
import { Button } from '@material/mwc-button';
import { Menu } from '@material/mwc-menu';

// Translations
import i18next from 'i18next';

const illegalPatientSearchEventKeys = {
    "ArrowLeft": false,
    "ArrowRight": false,
    "ArrowUp": false,
    "ArrowDown": false,
    "Meta": false,
    "End": false,
    "Home": false,
    "PageUp": false,
    "PageDown": false,
    "Control": false,
    "Shift": false
}

@customElement('patient-list-filters')
export class PatientListFilters extends LitElement {

    static get styles() {
        return css`
            
            .filters-container{
                display: grid;
                grid-template-columns: 1fr auto;
                grid-column-gap: 80px;
                grid-row-gap: 16px;
                justify-content: center;
                align-items: center;
                padding: 20px 0px 0px 0px;
            }
            
            .campaign-search-container{
                display: grid;
                grid-template-columns: auto 1fr;
                grid-gap: 16px;
                align-items: center;
            }

            .search-bar {
                display: grid;
                grid-template-columns: 1fr auto;
                grid-gap: 16px;
                align-items: center;
            }

            mwc-button{
                --mdc-theme-primary: ${themePrimaryBlue};
                --mdc-theme-on-primary: white;
            }

            vaadin-combo-box{
                --vaadin-combo-box-overlay-width: 350px
            }

            .sort-select{
                justify-self: right;
                --mdc-theme-primary: ${themePrimaryBlue};
                --mdc-theme-on-primary: white;
            }

            #menu > [mwc-list-item]:not([selected]) [slot="graphic"] {
                display: none;
            }

            /* tablet */
            @media (max-width: 768px) {
                .filters-container{
                    grid-template-columns: 1fr;
                }
            }

            /* phone */
            @media (max-width: 488px) {
                .campaign-search-container{
                    grid-template-columns: 1fr;
                }

                .filters-container{
                    grid-template-columns: 1fr;
                }

                .search-bar {
                    grid-template-columns: 1fr;
                }
            }
        `
        ;
    }

    @property({ attribute: false })
    userCampaignList: Array<Campaign> = [];

    @property({ attribute: false })
    selectedCampaignID: string = '';

    @internalProperty()
    campaignListItems: Array<Campaign>

    @property()
    searchCriteria: string = '';

    @property({attribute: false})
    sortField: SORTFIELD;
    
    @property({attribute: false})
    sortDirection: SORTDIRECTION = SORTDIRECTION.ASC;

    @query('#menuButton')
    menuButton: Button;

    @query('#menu')
    menu: Menu;

    @internalProperty()
    timer: any

    @property({ type: Boolean })
    patientListView: boolean;

    firstUpdated() {
        // Without this code, the menu items will render on top of the menu button and will only move to its proper location after selecting a different sorting option. 
        this.sortField = SORTFIELD.NAMEAZ;
        this.sortField = SORTFIELD.REGISTRATION;

        this.timer = null;
    }

    render() {
        if (this.patientListView) {
            this.campaignListItems = 
            [
                {
                    campaignID: '', 
                    campaignName: i18next.t('allMyCampaigns')
                },
                ...this.sortedUserCampaignList()
            ];
        } else {
            this.campaignListItems = 
            [
                ...this.sortedUserCampaignList()
            ];
        }

        return html`
            <div class="filters-container">
                <div class="campaign-search-container">
                    <vaadin-combo-box 
                        .items="${this.campaignListItems}"
                        item-label-path="campaignName"
                        item-value-path="campaignID"
                        placeholder="Select a campaign"
                        value="${this.selectedCampaignID}"
                        @change="${this._handleCampaignSelected}" 
                    ></vaadin-combo-box>

                    <div class="search-bar">
                        <vaadin-text-field
                            placeholder="${i18next.t('search by patient name or ID').toString()}"
                            value="${this.searchCriteria}"
                            @change=${(event) => this._handleSearch(event, false)}
                            clear-button-visible
                            @keyup=${(event) => this._handleSearch(event, false)}
                            @keypress=${ (event) => { if (event.code === 'Enter') this._handleSearch(event, true)} }
                        >
                            <iron-icon slot="prefix" icon="vaadin:search"></iron-icon>
                        </vaadin-text-field>

                        <mwc-button 
                            class="search-button"
                            label="${i18next.t('search').toString()}"
                            raised
                            @click="${(event) => this._handleSearch(event, true)}"
                        ></mwc-button>
                    </div>
                </div>

                <div class="sort-select" style="position:relative;">
                    <mwc-button 
                        id="menuButton" 
                        label="${this.getSortButtonLabel()}"
                        outlined
                        icon="arrow_drop_down"
                        trailingIcon
                        @click="${ () => this.menu.show() }"
                        >
                    </mwc-button>
                    <mwc-menu 
                        id="menu" 
                        .anchor="${this.menuButton}"
                        corner="BOTTOM_START"
                        @action="${this._handleSortSelection}"
                    >
                        <mwc-list-item 
                            graphic="icon" 
                            ?selected="${this.sortField === SORTFIELD.REGISTRATION}">
                            <mwc-icon slot="graphic">check</mwc-icon>
                            <span>${i18next.t('Registration (most recent)')}</span>
                        </mwc-list-item>
                        <li divider role="separator"></li>
                        <mwc-list-item 
                            graphic="icon" 
                            ?selected="${this.sortField === SORTFIELD.NAMEAZ}">
                            <mwc-icon slot="graphic">check</mwc-icon>
                            <span>${i18next.t('Patient Name (A->Z)')}</span>
                        </mwc-list-item>
                        <mwc-list-item 
                            graphic="icon" 
                            ?selected="${this.sortField === SORTFIELD.NAMEZA}">
                            <mwc-icon slot="graphic">check</mwc-icon>
                            <span>${i18next.t('Patient Name (Z->A)')}</span>
                        </mwc-list-item>
                    </mwc-menu>
                </div>

            </div>
        `;
    }

    _handleSortSelection(event) {
        let sortField: string = '';
        let sortDirection: string = '';

        switch(event.detail.index){
            case 0:
                sortField = SORTFIELD.REGISTRATION;
                sortDirection = SORTDIRECTION.DESC;
                break;
            case 1:
                sortField = SORTFIELD.NAMEAZ;
                sortDirection = SORTDIRECTION.ASC;
                break;
            case 2:
                sortField = SORTFIELD.NAMEZA;
                sortDirection = SORTDIRECTION.DESC;
                break;
            default:
                sortField = SORTFIELD.REGISTRATION;
        }

        if (sortField !== this.sortField) {
            this.dispatchEvent(new CustomEvent('sort-criteria-changed', {
                detail: {
                    sortField,
                    sortDirection //TODO - i stopped here with the direction, but i think we should separate the field and direction for future sorting. No critical for now.
                },
                bubbles: true,
                composed: true
            }));
        }
    }

    getSortButtonLabel (){
        let sortButtonLabel: string = '';

        switch(this.sortField){
            case SORTFIELD.REGISTRATION:
                sortButtonLabel = i18next.t('Sort: Registration');
                break;
            case SORTFIELD.NAMEAZ:
                sortButtonLabel = i18next.t('Sort: Name (A->Z)');
                break;
            case SORTFIELD.NAMEZA:
                sortButtonLabel = i18next.t('Sort: Name (Z->A)');
                break;
            default:
                sortButtonLabel = i18next.t('Sort by');
        }

        return sortButtonLabel;
    }

    _handleCampaignSelected(event) {
        this.dispatchEvent(new CustomEvent('campaign-selected', {
            detail: { campaignID: event.target.value },
            bubbles: true,
            composed: true
        }))
    }

    _handleSearch(event:any, searchImmediately:boolean) {
        if (!illegalPatientSearchEventKeys.hasOwnProperty(event.key)) {
            clearTimeout(this.timer); // if the previous timer hasn't finished yet, clear it first. This ensures that only after the last keypress from the user will the event be dispatched to the parent component
        
            if (searchImmediately) {
                this.dispatchEvent(new CustomEvent('search-criteria-changed', {
                    detail: { searchCriteria: this.searchCriteria },
                    bubbles: true,
                    composed: true 
                }));
            } else {
                this.searchCriteria = event.target.value;
                this.timer = setTimeout(() => {
                    this.dispatchEvent(new CustomEvent('search-criteria-changed', {
                        detail: { searchCriteria: this.searchCriteria },
                        bubbles: true,
                        composed: true 
                    }));
                }, 500);
            }
        }
    }

    sortedUserCampaignList():Array<Campaign> {
        return this.userCampaignList.sort((a,b) => {
            let x = a.campaignName.toLowerCase();
            let y = b.campaignName.toLowerCase();
            if (x < y) {
                return -1;
            }
            if (x > y) {
                return 1;
            }
            return 0;
        })
    }
}