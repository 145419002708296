import { LitElement, customElement, html, property, internalProperty, css } from 'lit-element';
import '@material/mwc-button';
import '@material/mwc-textfield';
import '@material/mwc-snackbar';
import '@material/mwc-formfield';

import { Snackbar } from '@material/mwc-snackbar';
import '../components/common/languageSelect.ts'

import { Router } from '@vaadin/router';

// import {
//   PreventAndRedirectCommands,
//   RedirectResult,
//   Router,
//   RouterLocation,
// } from '@vaadin/router';

// utils
import authenticator from '../utils/authenticator';

// models
// import { RouteInfo } from '../models/routeInfo';

// styles
import { themePrimaryBlue, themeMediumGrey } from '../styles/themeColors';

// components
import './appLink.ts';
import { LANG_KEY } from '../I18n/i18nUtil'

@customElement('backpackemr-auth')
export class BackpackEmrAuth extends LitElement {

  @internalProperty()
  username:string = localStorage.getItem("backpackEMR-usr") || '';

  @internalProperty()
  password:string = "";

  @internalProperty()
  disabled: boolean = false;

  @internalProperty()
  language: string = localStorage.getItem(LANG_KEY) || 'en';
  
  static get styles() {
    return css`
      .content {
        display: grid;
        justify-items: center;
        grid-template-columns: 1fr;
      }

      .login-container {
        display: grid;
        grid-template-columns: 1fr;
        background-color: #fff;
        padding: 24px;
        margin: 24px 0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        grid-row-gap: 18px;
        border-radius: 4px;
        width: 300px;
      }

      h4 {
        margin: 4px 0;
        padding: 4px 0;
      }

      mwc-textarea, mwc-textfield, mwc-select {
          --mdc-theme-primary: ${themePrimaryBlue};
      }

      mwc-button{
        --mdc-theme-primary: ${themePrimaryBlue};
        --mdc-theme-on-primary: white;
      }

      .app-version {
        font-size: 14px;
        color: ${themeMediumGrey};
        text-align: center;
      }

      @media (max-width: 600px) {
        .content {
          display: grid;
          justify-items: normal;
        }

        .login-container {
          width: auto;
        }
      }
    `
  }

  render() {
    return html`
      <div class="content">
        <mwc-snackbar id="loginSnackbar" labelText="Invalid username or password">
        </mwc-snackbar>
        <div class="login-container">
          <h4>Welcome!</h4>
          <mwc-textfield
            ?disabled=${this.disabled}
            label="Email"
            icon="person"
            id="email"
            value="${this.username}"
            @input=${(e:any) => this.username = e.target.value} />
          </mwc-textfield>
          <mwc-textfield
            id="password"
            ?disabled=${this.disabled}
            label="Password"
            type="password"
            icon="lock"
            value="${this.password}"
            @input=${(e:any) => this.password = e.target.value}
            @keypress="${(event) => {if(event.code === 'Enter') this.authClick()}}"/>
          </mwc-textfield>
          <mwc-button
              ?disabled=${this.disabled}
              label="Log in"
              raised
              @click="${this.authClick}">
          </mwc-button>
          <br>
          <language-select language="${this.language}"></language-select>
          <div class="app-version">Version 1.5 ~ updated 25 May 2021</div>
        </div>
      </div>
    `;
  }

  async authClick() {
    this.disabled = true;

    await this.updateComplete;
    var authGranted:Boolean = false;

    if (this.username !== "" && this.password !== "") {
      authGranted = await authenticator.authenticate(this.username, this.password);
    }

    this.password = "";
    this.disabled = false;

    if (authGranted) {
      localStorage.setItem('backpackEMR-usr', this.username);
      this.dispatchEvent(new AuthUpdateEvent(authGranted));
      this.dispatchEvent(new CustomEvent('user-logged-in', {
        bubbles: true,
        composed: true
      }))
      
      Router.go('/patients');

    } else {
      const snackbar = this.shadowRoot.querySelector('#loginSnackbar') as Snackbar;
      snackbar.show();
    }
  }

  // onBeforeEnter(location:RouterLocation, commands:PreventAndRedirectCommands, router:Router): Promise<unknown> | RedirectResult | undefined {
  //     if (!authenticator.isAuthenticated()) {
  //         return commands.redirect('/');
  //     }
  // }

}



export class AuthUpdateEvent extends Event {
  authGranted:Boolean

  constructor(authGranted:Boolean) {
    super("auth-updated");
    this.authGranted = authGranted;
  }
}