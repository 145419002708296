// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';
import logger, { Component } from '../../utils/logger'

// Models
import { Patient } from '../../models/patient'

// Components
import '@material/mwc-linear-progress'
import './patientListItem';

import { themeLightGreyBorders } from '../../styles/themeColors';

@customElement('patient-list-container')
export class PatientListContainer extends LitElement {

    static get styles() {
        return css`

            .patients {
                display: grid;
                grid-template-columns: 1fr;
                border-bottom: 1px solid ${themeLightGreyBorders};
            }
     
            @media (max-width: 768px) {

            }

            @media (max-width: 501px) {
                .patients {
                    grid-row-gap: 8px;
                }
            }
        `
        ;
    }

    @property({attribute: false})
    patients: Array<Patient>;

    render() {
        return html`
            <div class="patients">
                <!-- TODO: handle empty state (no patients returned) -->
                ${this.patients.map(patient => 
                    html`
                        <patient-list-item .patient="${patient}"></patient-list-item>
                    `
                )}
            </div>
        `;
    }
}