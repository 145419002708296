import { LitElement, customElement, html, css, property, internalProperty, query } from 'lit-element';

import { themePrimaryBlue, themeDarkGrey } from '../../styles/themeColors';

import '@material/mwc-button';
import '@material/mwc-menu';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-icon';
import { Button } from '@material/mwc-button';
import { Menu } from '@material/mwc-menu';
import { User } from '../../models/user';
import i18next from 'i18next';

@customElement('user-menu-button')
  export class userMenuButton extends LitElement {
    
    @property({attribute: false})
    user:User;

    @query('#menu-button')
    menuButton: Button;

    @query('#menu')
    menu: Menu;

    static get styles() {
      return css`
        #menu-button {
          --mdc-theme-primary: white;
          --mdc-button-outline-color: ${themePrimaryBlue};
        }

        .inverted {
          background-color: ${themeDarkGrey};
          color: white;
        }

        .secondary-button {
          color: ${themePrimaryBlue}
        }

        .big-icon {
          --mdc-icon-size: 40px;
        }
      `

    }
    render() {
      return html` 
        <div class="user-menu" style="position:relative;">
            <mwc-button      
              id="menu-button"
              label="${this.getFirstName(this.user.name)}"
              icon="arrow_drop_down"
              trailingIcon
              outlined
              @click=${() => this.menu.show()}
              >
            </mwc-button>

            <mwc-menu
              id="menu"
              absolute
              x="-75"
              y="20"
              .anchor=${this.menuButton}
            >
              <mwc-list-item twoline noninteractive graphic="avatar">
                <span>${this.user.name}</span>
                <span slot="secondary">${this.user.email}</span>
                <mwc-icon slot="graphic" class="inverted big-icon">person</mwc-icon>
              </mwc-list-item>

              <li divider padded role="separator"></li>

              <mwc-list-item graphic="icon" 
                @click=${this._handleLogout}
              >
                <span class="secondary-button">${i18next.t('logout')}</span>
                <mwc-icon slot="graphic" class="secondary-button">login</mwc-icon>
              </mwc-list-item>
            </mwc-menu>
        </div>
      `
    }

    _handleLogout() {
      this.dispatchEvent(new CustomEvent('handleLogout', {
        bubbles: true,
        composed: true
      }));
    }

    getFirstName(name:string) {
      return name.substr(0, name.indexOf(' '));
    }

  }