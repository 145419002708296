// Foundation
import { LitElement, customElement, html, css, property, query, internalProperty } from 'lit-element';

// Themes
import { themePrimaryBlue, themeRed, themeDarkGrey } from '../../styles/themeColors';

import i18next from 'i18next';

import '@material/mwc-dialog';
import '@material/mwc-button';
import '../common/customTextField';
import '../common/customSelect2';
import '../common/customDateTimePicker';

import { Campaign } from '../../models/campaignDetails';

@customElement('update-patient-visits-dialog-box')
export class UpdatePatientVisitsDialogBox extends LitElement {

    static get styles() {
        return css`

            .content {
                display: grid;
            }

            #visitHeader {
                color: rgba(0, 0, 0, 0.87);
                font-weight: 500;
                font-family: Roboto;
                font-size: 20px;
            }

            #customDialog {
                position: fixed;
                padding: 30px;
                left: 40%;
                top: 250px;
                background-color:white;
                z-index: 6;
            }

            .fields {
                display: grid;
                grid-template-columns: 1fr;
            }

            .save-button{
                --mdc-theme-primary: ${themePrimaryBlue};
            }

            .primary-btn-red {
                --mdc-theme-primary: ${themeRed};
            }

            .cancel-button{
                --mdc-theme-primary: ${themePrimaryBlue};
            }

            .delete-button{
                --mdc-theme-primary: ${themeRed};
                --mdc-theme-on-primary: ${themeRed};
                --mdc-button-outline-color: ${themeRed};
                justify-self: start;
            }

            .actions-row {
                display: grid;
                grid-template-columns: 1fr auto auto;
                grid-gap: 10px;
                margin-top: 30px;
                align-items: center;
            }

            .instructions {
                font-size: 20;
                color: ${themeDarkGrey};
            }

            @media (max-width: 768px) {

            }
        `
        ;
    }

    @property({type: Boolean, attribute: true})
    open:boolean = false;

    @property({type: String, attribute: true})
    visitID:string;

    @property({type: Boolean, attribute: false})
    creatingNewVisit:boolean = true;

    @property({type: String, attribute: true})
    patientID:string;

    @property({type: String, attribute: true})
    patientName:string;

    @property({type: Array, attribute: false})
    campaignList:Array<Campaign>

    @internalProperty()
    selectedLocationID:string;

    @internalProperty()
    selectedCampaignID:string;

    @property({type: String, attribute: false})
    visitDate:string;

    @internalProperty()
    formmatedListOfCampaigns: any;

    @internalProperty()
    listOfSelectedCampaignLocations: any;

    @internalProperty()
    deleteVisit: boolean = false;

    @internalProperty()
    confirmVisitDelete: boolean = false;

    firstUpdated() {
        const ele = document.getElementById("tintContainer");
        ele.style.background = "hsl(0deg 0% 0% / 44%)";
        ele.style.zIndex = "4";
        ele.style.position = "fixed";
        ele.style.height = "100%";
        ele.style.width = "100%";
        
        // if creating a new visit, ignore the selected campaign id and selected locatino id sent from parent component - they are used for update patient details only
        if(this.creatingNewVisit) {
            this.visitDate = new Date().toISOString();
            this.selectedCampaignID = null;
            this.selectedLocationID = null;
        }
        // whether you're creating a new visit or editing, populate the list of campaigns the user has access to
        this.formmatedListOfCampaigns = this.campaignList.map( campaign => {
            return  {
                key:  campaign.campaignID,
                value: campaign.campaignName
            };
        });

        // if the user only has access to one campaign, autopopulate the selected campaignID and generate the list of locations associated with the campaign
        if(this.campaignList.length === 1) {
            this.selectedCampaignID = this.campaignList[0].campaignID;
            
            if(this.campaignList[0].locations){
                this.listOfSelectedCampaignLocations = this.campaignList[0].locations?.map(location => {
                    return {
                        key: location.locationID,
                        value: location.locationName
                    }
                })
            } else {
                this.listOfSelectedCampaignLocations = null;
            }
            
        }
        
        // if you're editing an existing visit, populate the list of locations based on the selected campaign that was passed in
        if(!this.creatingNewVisit) {
            const selectedCampaign = this.campaignList.find(campaign => campaign.campaignID === this.selectedCampaignID);

            this.listOfSelectedCampaignLocations = selectedCampaign.locations.map(location => {
                return {
                    key: location.locationID,
                    value: location.locationName
                }
            })
        }
        
    }

    render() {
        return html`
            <div id="customDialog">
                <div class="content">
                    <h2 id="visitHeader">${this.creatingNewVisit ? i18next.t('Create a new visit').toString() : i18next.t('Update visit details').toString()}</h2>
                    <div class="fields">
                        <backpackemr-text-field
                            label="${i18next.t('patientName').toString()}"
                            value="${this.patientName}"
                            disabled>
                        </backpackemr-text-field>

                        <backpackemr-select-two
                            label="${i18next.t('campaign').toString()}"
                            disableCustomValue
                            ?disabled="${this.campaignList?.length === 1 || !this.creatingNewVisit}"
                            value="${this.selectedCampaignID || ''}"
                            .items="${this.formmatedListOfCampaigns}"
                            @value-changed="${(event) => this._handleCampaignSelection(event)}">
                        </backpackemr-select-two>

                        <backpackemr-select-two
                            label="${i18next.t('location').toString()}"
                            disableCustomValue
                            ?disabled="${!this.selectedCampaignID || this.listOfSelectedCampaignLocations == 0}"
                            value="${this.selectedCampaignID ? this.selectedLocationID : '' }"
                            .items="${this.listOfSelectedCampaignLocations}"
                            @value-changed="${(event) => this.selectedLocationID = event.detail.key}">
                        </backpackemr-select-two>

                        <custom-date-time-picker
                            label="${i18next.t('visitDate').toString()}"
                            value="${this.visitDate || ''}"
                            @date-selected="${(event) => this.visitDate = event.detail.date}">
                        </custom-date-time-picker>
                    </div>

                    ${!this.deleteVisit
                        ? html`
                            <div class="actions-row">
                                <mwc-button
                                    label="${i18next.t('removeThisVisit').toString()}"
                                    outlined
                                    ?disabled="${this.creatingNewVisit}"
                                    class="delete-button"
                                    @click="${() => this.deleteVisit = !this.deleteVisit}">
                                </mwc-button>
                                <mwc-button 
                                    label="${i18next.t('cancel').toString()}"
                                    class="cancel-button"
                                    dialogAction="cancel"
                                    @click="${this._handleDialogBoxCanceled}">
                                </mwc-button>
                                <mwc-button 
                                    class="save-button"
                                    label="${i18next.t('save').toString()}"
                                    ?disabled="${this.selectedCampaignID ? false : true}"
                                    dialogAction="ok"
                                    raised
                                    @click="${this._handleSavePatientVisitChanges}">
                                </mwc-button>
                            </div>
                        `
                        : html`
                            <div class="actions-row">
                                <div class="instructions">
                                    ${i18next.t('Are you sure?').toString()}
                                </div>
                                <mwc-button 
                                    label="${i18next.t('navigateBack').toString()}"
                                    class="cancel-button"
                                    @click="${() => this.deleteVisit = !this.deleteVisit}">
                                </mwc-button>
                                <mwc-button 
                                    label="${i18next.t('Yes, Remove').toString()}"
                                    class="primary-btn-red"
                                    dialogAction="ok"
                                    raised
                                    @click="${this._handleSavePatientVisitChanges}">
                                </mwc-button>
                            </div>
                        `
                    }

                    
                </div>
            </div>

        `;
    }

    _handleCampaignSelection(event:CustomEvent) {
        this.selectedCampaignID = event.detail?.key;
        this.selectedLocationID = null;

        if(this.selectedCampaignID && this.campaignList) {
            const selectedCampaign = this.campaignList.find( campaign => {
                return campaign.campaignID === this.selectedCampaignID;
            });
            
            this.listOfSelectedCampaignLocations = selectedCampaign.locations.map( location => {
                return {
                    key: location.locationID,
                    value: location.locationName
                }
            });
            
        } else {
            this.listOfSelectedCampaignLocations = [];
            this.selectedLocationID = '';
        }
    }

    _handleDialogBoxCanceled() {
        const ele = document.getElementById("tintContainer");
        ele.style.background = null;
        ele.style.zIndex = null;

        ele.style.height = null;
        ele.style.width = null;
        ele.style.position = null;
        if(this.campaignList.length > 1) {
            this.selectedCampaignID = '';
        }
        this.selectedLocationID = '';
        this.listOfSelectedCampaignLocations = [];
        this.dispatchEvent(new CustomEvent('dialog-box-closed', {
            detail: {
                key: 'canceled'
            }
        }))
    }

    _handleDialogBoxClosed(event) {
        if(event.detail.action === 'close') {
            this._handleDialogBoxCanceled();
        }
    }

    _handleSavePatientVisitChanges() {
        const ele = document.getElementById("tintContainer");
        ele.style.background = null;
        ele.style.zIndex = null;
        
        ele.style.height = null;
        ele.style.width = null;
        ele.style.position = null;
        this.dispatchEvent(new CustomEvent('dialog-box-closed', {
            detail: {
                key: 'submitted',
                creatingNewVisit: this.creatingNewVisit,
                variables: this.getMutationVariables()
            }
        }))
    }

    getMutationVariables() {
        if(this.creatingNewVisit) {
            const createNewVisitMutationVariables = {
                patientID: this.patientID,
                campaignID: this.selectedCampaignID,
                locationID: this.selectedLocationID,
                date: this.visitDate,
                deletingVisit: this.deleteVisit
            }
            return createNewVisitMutationVariables;
            
        } else {
            const updateVisitMutationVariables = {
                visitID: this.visitID,
                patientID: this.patientID,
                locationID: this.selectedLocationID,
                date: this.visitDate,
                deletingVisit: this.deleteVisit
            }
            return updateVisitMutationVariables;
        }
    }
}

