import { gql } from '@apollo/client/core';

export const updateVirtualCareDetails = gql`
	mutation UpdateVirtualCareDetails($id: String!, $input: VirtualCareDetailsInput!) {
		updateVirtualCareDetails(id: $id, input: $input) {
			code
			success
			message
			refreshedDetails {
				_id
				_rev
				campaignId
				channels
				patientId
				type
				updatedAt
				reasonForConsult
				requestedById
			}
		}
	}
`;

export const createVirtualCareDetails = gql`
	mutation CreateVirtualCareDetails($input: VirtualCareDetailsInput!, $visitID: String!) {
		createVirtualCareDetails(input: $input, visitID: $visitID) {
			code
			success
			message
			refreshedDetails {
				_id
				_rev
				campaignId
				channels
				patientId
				type
				updatedAt
				reasonForConsult
				requestedById
			}
		}
	}
`;

export const updateHistoryDetails = gql`
	mutation UpdateHistoryDetails($id: String!, $input: HistoryDetailsInput!) {
		updateHistoryDetails(id: $id, input: $input) {
			code
			success
			message
			refreshedDetails {
				_id
				_rev
				updatedAt
				type
				campaignId
				channels
				patientId
				pregnant
				hivPositive
				breastFeeding
				chronicHistories {
					history
					historyNotes
				}
				currentMedications {
					currentMedication
					medicationNotes
				}
				allergies {
					allergy
					allergyNotes
				}
			}
		}
	}
`;

export const createHistoryDetails = gql`
	mutation CreateHistoryDetails($input: HistoryDetailsInput!, $visitID: String!) {
		createHistoryDetails(input: $input, visitID: $visitID) {
			code
			success
			message
			refreshedDetails {
				_id
				_rev
				updatedAt
				type
				campaignId
				channels
				patientId
				pregnant
				hivPositive
				breastFeeding
				chronicHistories {
					history
					historyNotes
				}
				currentMedications {
					currentMedication
					medicationNotes
				}
				allergies {
					allergy
					allergyNotes
				}
			}
		}
	}
`;

export const updateComplaintDetails = gql`
	mutation UpdateComplaintDetails($id: String!, $input: ComplaintDetailsInput!) {
		updateComplaintDetails(id: $id, input: $input) {
			code
			success
			message
			refreshedDetails {
				_id
				_rev
				updatedAt
				type
				campaignId
				channels
				patientId
				complaints {
					complaint
					complaintNotes
				}
			}
		}
	}
`;

export const createComplaintDetails = gql`
	mutation CreateComplaintDetails($input: ComplaintDetailsInput!, $visitID: String!) {
		createComplaintDetails(input: $input, visitID: $visitID) {
			code
			success
			message
			refreshedDetails {
				_id
				_rev
				updatedAt
				type
				campaignId
				channels
				patientId
				complaints {
					complaint
					complaintNotes
				}
			}
		}
	}
`;

export const createVisionDetails = gql`
	mutation createVisionDetails($input: VisionDetailsInput!, $visitID: String!) {
		createVisionDetails(input: $input, visitID: $visitID) {
			code
			success
			message
			refreshedDetails {
				_id
				_rev
				type
				campaignId
				channels
				updatedAt
				patientId
				eyeDiagnoses {
					eyeDiagnosis
					eyeDiagnosisNotes
				}
				eyeTreatments {
					eyeTreatment
					patientReceived
					treatmentCompletedByandDate
				}
			}
		}
	}
`;

export const updateVisionDetails = gql`
	mutation UpdateVisionDetails($id: String!, $input: VisionDetailsInput!) {
		updateVisionDetails(id: $id, input: $input) {
			code
			success
			message
			refreshedDetails {
				_id
				_rev
				type
				campaignId
				channels
				updatedAt
				patientId
				eyeDiagnoses {
					eyeDiagnosis
					eyeDiagnosisNotes
				}
				eyeTreatments {
					eyeTreatment
					patientReceived
					treatmentCompletedByandDate
				}
			}
		}
	}
`;

export const createLabsDetails = gql`
	mutation CreateLabsDetails($input: LabsDetailsInput!, $visitID: String!) {
		createLabsDetails(input: $input, visitID: $visitID) {
			code
			success
			message
			refreshedDetails {
				_id
				_rev
				campaignId
				channels
				patientId
				updatedAt
				type
				labs {
					labComplete
					labCompletedDate
					labCompletedUser
					labFacility
					notes
					results
					test
					photoKey
					photos {
						fileName
						signedURL
					}
				}
			}
		}
	}
`;

export const updateLabsDetails = gql`
	mutation UpdateLabsDetails($id: String!, $input: LabsDetailsInput!) {
		updateLabsDetails(id: $id, input: $input) {
			code
			success
			message
			refreshedDetails {
				_id
				_rev
				campaignId
				channels
				patientId
				updatedAt
				type
				labs {
					labComplete
					labCompletedDate
					labCompletedUser
					labFacility
					notes
					results
					test
					photoKey
					photos {
						fileName
						signedURL
					}
				}
			}
		}
	}
`;

export const createPublicHealthDetails = gql`
	mutation CreatePublicHealthDetails($input: PublicHealthDetailsInput!, $visitID: String!) {
		createPublicHealthDetails(input: $input, visitID: $visitID) {
			code
			success
			message
			refreshedDetails {
				_id
				_rev
				campaignId
				channels
				updatedAt
				type
				patientId
				contacts
				cookingConditions
				drinkingWaterFiltered
				firstSymptomsDate
				knownCaseCloseContact
				livingConditions
				notes
				occupationalRiskGroup
				traveledInternationally
				visitedCountries
			}
		}
	}
`;

export const updatePublicHealthDetails = gql`
	mutation UpdatePublicHealthDetails($id: String!, $input: PublicHealthDetailsInput!) {
		updatePublicHealthDetails(id: $id, input: $input) {
			code
			success
			message
			refreshedDetails {
				_id
				_rev
				campaignId
				channels
				updatedAt
				type
				patientId
				contacts
				cookingConditions
				drinkingWaterFiltered
				firstSymptomsDate
				knownCaseCloseContact
				livingConditions
				notes
				occupationalRiskGroup
				traveledInternationally
				visitedCountries
			}
		}
	}
`;

export const updateVitalsDetails = gql`
	mutation UpdateVitalsDetails($id: String!, $input: VitalsDetailsInput) {
		updateVitalsDetails(id: $id, input: $input) {
			code
			success
			message
			refreshedDetails {
				_id
				_rev
				armCircumferenceCm
				armCircumferenceIn
				bloodPressurediastolic
				bloodPressureSystolic
				bmi
				campaignId
				channels
				headCircumferenceCm
				headCircumferenceIn
				heartRate
				heightCm
				heightIn
				o2Saturation
				patientId
				respiratoryRate
				temperatureC
				temperatureF
				type
				updatedAt
				vitalsNotes
				weightKg
				weightLbs
				zScoreArmCircumference
				zScoreBmi
				zScoreHeightWeight
			}
		}
	}
`;

export const CREATE_EXAM_DETAILS = gql`
	mutation CreateExamDetails($input: ExamDetailsInput!, $visitID: String!) {
		createExamDetails(input: $input, visitID: $visitID) {
			code
			success
			message
			refreshedDetails {
				_id
				_rev
				campaignId
				patientId
				channels
				updatedAt
				type
				examDoctor
				examNotes
				diagnoses {
					diagnosis
					diagnosisNotes
					examDoctor
					treatments {
						treatmentType
						drug
						dispense
						inClinicTreatment
						inClinicTreatmentNotes
						referralDetails
						referralLocation
						referralPlan
						referralTimeframe
						pharmacyCounted
						pharmacyCountedUserDate
						pharmacyFilled
						pharmacyFilledUserDate
					}
				}
				photos {
					fileName
					signedURL
				}
			}
		}
	}
`;

export const createVitalsDetails = gql`
	mutation CreateVitalsDetails($input: VitalsDetailsInput!, $visitID: String!) {
		createVitalsDetails(input: $input, visitID: $visitID) {
			code
			success
			message
			refreshedDetails {
				_id
				_rev
				campaignId
				channels
				patientId
				updatedAt
				type
				armCircumferenceCm
				armCircumferenceIn
				bloodPressureSystolic
				bloodPressurediastolic
				bmi
				headCircumferenceCm
				headCircumferenceIn
				heartRate
				heightCm
				heightIn
				o2Saturation
				respiratoryRate
				temperatureC
				temperatureF
				vitalsNotes
				weightKg
				weightLbs
				zScoreArmCircumference
				zScoreBmi
				zScoreHeightWeight
			}
		}
	}
`;

export const UPDATE_EXAM_DETAILS = gql`
	mutation UpdateExamDetails($id: String!, $input: ExamDetailsInput) {
		updateExamDetails(id: $id, input: $input) {
			code
			success
			message
			refreshedDetails {
				_id
				_rev
				campaignId
				patientId
				channels
				updatedAt
				type
				examDoctor
				examNotes
				diagnoses {
					diagnosis
					diagnosisNotes
					examDoctor
					treatments {
						treatmentType
						drug
						dosage
						dispense
						inClinicTreatment
						inClinicTreatmentNotes
						referralDetails
						referralLocation
						referralPlan
						referralTimeframe
						pharmacyCounted
						pharmacyCountedUserDate
						pharmacyFilled
						pharmacyFilledUserDate
					}
				}
				photos {
					fileName
					signedURL
				}
			}
		}
	}
`;

export const createNewVisit = gql`
	mutation CreateNewVisit($patientID: String!, $campaignID: String!, $locationID: String, $date: String!) {
		createNewVisit(patientID: $patientID, campaignID: $campaignID, locationID: $locationID, date: $date) {
			code
			success
			message
			refreshedDetails {
				visitID
			}
		}
	}
`;

export const updatePatientVisit = gql`
	mutation updatePatientVisit($visitID: String!, $patientID: String!, $locationID: String, $date: String!, $deletingVisit: Boolean!) {
		updatePatientVisit(visitID: $visitID, patientID: $patientID, locationID: $locationID, date: $date, deletingVisit: $deletingVisit) {
			code
			success
			message
			refreshedDetails {
				visitID
			}
		}
	}
`;

export const updatePatientQueueData = gql`
	mutation updatePatientQueueData($patientID: String!, $selectedCampaignID: String!, $queueId: String!, $status: String!, $timestamp: String!) {
		updatePatientQueueData(patientID: $patientID, selectedCampaignID: $selectedCampaignID, queueId: $queueId, status: $status, timestamp: $timestamp) {
			code
			success
			message
			refreshedDetails {
				queueId
				status
				timestamp
			}
		}
	}
`;