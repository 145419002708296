import { Router, Route } from '@vaadin/router';

import '../components/backpackEmrAuth.ts';
import '../components/backpackEmrNav.ts';
import '../components/backpackEmrMain.ts';
import '../components/patientList/patientList.ts';
import '../components/patientChart/patientChart.ts';
import '../components/patientProfile/patientProfile.ts';
import '../components/pageNotFound';
import '../components/sysAdmin/sysAdminHome';

export const routes: Route[] = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login',
        component: 'backpackemr-auth',
    },
    {
        path: '/sysAdmin-3790eeed-2e7d-47bb-ad11-cb440fe5e0f1',
        component: 'sys-admin-home',
    },
    {
        path:'/createNewPatient',
        component: 'patient-profile'
    },
    {
        path: '/patients',
        component: 'patient-list',
        // action: () => import('../components/patientList/patientList') //attempt to code split and just load JS when needed :)
    },
    {
        path: '/patients/:patientID',
        component: 'patient-chart',
    },
    {
        path: '/patients/:patientID/profile',
        component: 'patient-profile',
    },

    // {
    //     path:'/createNewPatient/:patientID/:creatingNewPatient',
    //     component: 'patient-profile'
    // },
    // {
    //     path: '/patients/:patientID/visits/:visitID',
    //     component: 'patient-chart',
    // },
    {
        path: '(.*)',
        component: 'page-not-found',
    }
]

export const router = new Router();