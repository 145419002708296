// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

// Themes
import { themeLightGreyBorders } from '../../styles/themeColors';

// Models 
import { PUBLICHEALTH, COMPLAINTS, HISTORY, VITALS, LABS, VISION, EXAM, PHARMACY, VIRTUALCARE} from '../../models/medicalCareTabs';

import { Visit } from '../../models/visit'

// Web Components
import './tabs/primaryCarePublicHealth.ts'
import './tabs/primaryCareComplaints.ts'
import './tabs/primaryCareHistory.ts'
import './tabs/primaryCareVitals.ts'
import './tabs/primaryCareVirtualCare.ts'
import './tabs/primaryCareLabs.ts'
import './tabs/primaryCareVision.ts'
import './tabs/primaryCareExam.ts'
import './tabs/primaryCarePharmacy.ts'

import '@material/mwc-button';
import i18next from 'i18next';
import { ExamDetails } from '../../models/clinical/exam';
import { ComplaintsDetails } from '../../models/clinical/complaints';
import { VisionDetails } from '../../models/clinical/vision';
import { PublicHealthDetails } from '../../models/clinical/publicHealth';
import { VitalsDetails } from '../../models/clinical/vitals';
import { HistoryDetails } from '../../models/clinical/history';
import { LabsDetails } from '../../models/clinical/labs';
import { VirtualCareDetails } from '../../models/clinical/virtualCare';

@customElement('visit-tab-content')
export class VisitTabContent extends LitElement {

    static get styles() {
        return css`
            * {
                margin: 0;
                padding: 0;
            }
            
            .content {
                display: grid;
                align-content: start;
                /* padding: 16px; */
                padding: 0 16px 16px 16px;
                background-color: #fff;
                border: 1px solid ${themeLightGreyBorders};
                /* border-top: none; */
                min-height: 600px;
            }

            .not-available-message {
                display: grid;
                padding-top: 16px;
                grid-gap: 16px;
            }

            @media (max-width: 768px) {
                .content {
                    display: grid;
                    grid-template-columns: 1fr;
                    margin-left: 0;
                    /* margin-top: 6px; */
                }

            }
        `
        ;
    }


    @property({attribute: false})
    selectedVisit:Visit;
    
    @property()
    selectedTabName:string;

    @property()
    patientID:string = '';

    @property()
    campaignID:string = '';

    @property()
    loggedInUser:string;

    @property({attribute: false})
    examDetails: ExamDetails

    @property({attribute: false})
    complaintDetails: ComplaintsDetails

    @property({attribute: false})
    publicHealthDetails: PublicHealthDetails;

    @property({attribute: false})
    vitalsDetails: VitalsDetails;

    @property({attribute: false})
    historyDetails: HistoryDetails;

    @property({attribute: false})
    labsDetails: LabsDetails;

    @property({attribute: false})
    visionDetails: VisionDetails;

    @property({attribute: false})
    virtualCareDetails: VirtualCareDetails;

    render() {        
        if(!this.selectedVisit){
            return html`
                <div class="content">
                    <p>Please select a visit.</p>
                </div>        
            `;
        }

        return html`
            <div class="content">
                ${this.selectedVisit?.medicalModule === 'primaryCare'? this.displaySelectedTab() : this.displayNonPrimaryCareVisit()}
            </div>
        `;
    }

    displayNonPrimaryCareVisit(){
        let medicalModuleTranslation = '';
        switch(this.selectedVisit.medicalModule){
            case ('cleftLip'):
                medicalModuleTranslation = 'cleftLipVisit';
                break;
            case ('orthopedic'):
                medicalModuleTranslation = 'orthopedic';
                break;
        }

        return html`
            <div class="not-available-message">
                <h3 cla>Visit details not available</h3>
                <p>
                    ${i18next.t(medicalModuleTranslation).toString()} visits are not currently avalable in this backpackEMR Web Portal.
                    <br />
                    To view the details of this visit, please use the BackpackEMR iOS app.
                </p>
            </div>
        `
    }

    displaySelectedTab(){
        switch(this.selectedTabName) {
            case PUBLICHEALTH.TABNAME:
                // apparently need to do a deep copy so the child component can edit the fields. 
                // in the future could pass in each field to the child component, just didn't want to take the time to run thru them all .. again :(
                const copyPublicHealthDetails:PublicHealthDetails = this.publicHealthDetails ? JSON.parse(JSON.stringify(this.publicHealthDetails)) : null;

                return html`
                    <tab-public-health 
                        campaignID="${this.campaignID}"
                        patientID="${this.patientID}"
                        visitID="${this.selectedVisit.patientCampaignID}"
                        .details="${copyPublicHealthDetails}"
                        >
                    </tab-public-health>
                `;
            case COMPLAINTS.TABNAME:
                const copyComplaintDetails:ComplaintsDetails = this.complaintDetails ? JSON.parse(JSON.stringify(this.complaintDetails)) : null;
                
                return html`
                    <tab-complaints 
                        campaignID="${this.campaignID}"
                        patientID="${this.patientID}"
                        visitID="${this.selectedVisit.patientCampaignID}"
                        .details="${copyComplaintDetails}"
                        >
                    </tab-complaints>
                `;
            case HISTORY.TABNAME:
                const copyHistoryDetails:HistoryDetails = this.historyDetails ? JSON.parse(JSON.stringify(this.historyDetails)) : null;
                
                return html`
                    <tab-history 
                        campaignID="${this.campaignID}"
                        patientID="${this.patientID}"
                        visitID="${this.selectedVisit.patientCampaignID}"
                        .details="${copyHistoryDetails}"
                        >
                    </tab-history>
                `;
            case VITALS.TABNAME:
                const copyVitalsDetails:VitalsDetails = this.vitalsDetails ? JSON.parse(JSON.stringify(this.vitalsDetails)) : null;

                return html`
                    <tab-vitals 
                        campaignID="${this.campaignID}"
                        patientID="${this.patientID}"
                        visitID="${this.selectedVisit.patientCampaignID}"
                        .details="${copyVitalsDetails}"
                        >
                    </tab-vitals>
                `;
            case LABS.TABNAME:
                const copyLabDetails:LabsDetails = this.labsDetails ? JSON.parse(JSON.stringify(this.labsDetails)) : null;
                
                return html`
                    <tab-labs 
                        campaignID="${this.campaignID}"
                        patientID="${this.patientID}"
                        visitID="${this.selectedVisit.patientCampaignID}"
                        .loggedInUser="${this.loggedInUser}"
                        .details="${copyLabDetails}"
                        >
                    </tab-labs>
                `;
            case VISION.TABNAME:
                const copyVisionDetails:VisionDetails = this.visionDetails ? JSON.parse(JSON.stringify(this.visionDetails)) : null;

                return html`
                    <tab-vision 
                        campaignID="${this.campaignID}"
                        patientID="${this.patientID}"
                        visitID="${this.selectedVisit.patientCampaignID}"
                        .loggedInUser="${this.loggedInUser}"
                        .details="${copyVisionDetails}"
                        >
                    </tab-vision>
                `;
            case EXAM.TABNAME:
                const copyExamDetails:ExamDetails = this.examDetails ? JSON.parse(JSON.stringify(this.examDetails)) : null;

                return html`
                    <tab-exam 
                        .patientID="${this.patientID}"
                        .campaignID="${this.campaignID}"
                        .visitID="${this.selectedVisit.patientCampaignID}"
                        .details="${copyExamDetails}">
                    </tab-exam>
                `;
            case PHARMACY.TABNAME:
                const copyExamDetailsForPharmacy:ExamDetails = this.examDetails ? JSON.parse(JSON.stringify(this.examDetails)) : null;
                
                return html`
                    <tab-pharmacy 
                        .details="${copyExamDetailsForPharmacy}"
                        .loggedInUser="${this.loggedInUser}"
                        >
                    </tab-pharmacy>
                `;
            case VIRTUALCARE.TABNAME:                
                const copyVirtualCareDetails:VirtualCareDetails = this.virtualCareDetails ? JSON.parse(JSON.stringify(this.virtualCareDetails)) : null;
            
                return html`
                    <tab-virtual-care 
                        .details="${copyVirtualCareDetails}" 
                        campaignID="${this.campaignID}"
                        patientID="${this.patientID}"
                        visitID="${this.selectedVisit.patientCampaignID}"
                        >
                    </tab-virtual-care>
                `;
        }
    }
}

// ._id="${this.selectedVisit.visitMedicalDetails?.examDetails?._id}"
// ._rev="${this.selectedVisit.visitMedicalDetails?.examDetails?._rev ?? ''}"
// .updatedAt="${this.selectedVisit.visitMedicalDetails?.examDetails?.updatedAt}"
// .examDoctor="${this.selectedVisit.visitMedicalDetails?.examDetails?.examDoctor ?? ''}"
// .examNotes="${this.selectedVisit.visitMedicalDetails?.examDetails?.examNotes ?? ''}"
// .photos="${this.selectedVisit.visitMedicalDetails?.examDetails?.photos ?? ''}"
// .diagnoses="${this.selectedVisit.visitMedicalDetails?.examDetails?.diagnoses ?? []}">