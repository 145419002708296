// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

import { Photo } from '../../models/clinical/exam';

import { themePrimaryBlue } from '../../styles/themeColors';

import './customPhotoUploader';

import i18next from 'i18next';

@customElement('backpackemr-photo-gallery')
export class PhotoGallery extends LitElement {
    static get styles() {
        return css`
            .container {
                padding-top: 16px;
                padding-bottom: 4px;
            }
            
            .photo-grid {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
                grid-gap: 12px;
            }

            .exam-photo {
                width: 100%;
                /* box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.4); */
                cursor: pointer;
            }

            img.hover-shadow {
                transition: 0.3s;
            }

            .hover-shadow:hover {
               box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            }

            .fade {
                animation-name: fade;
                animation-duration: 4s;
            }

            .label {
                color: var(--lumo-secondary-text-color);
                font-size: 14px;
                padding-bottom: 7px; /* standard vaadin label padding */
            }

            mwc-button {
                --mdc-theme-primary: ${themePrimaryBlue};
                --mdc-theme-on-primary: white;
                --mdc-button-outline-color: ${themePrimaryBlue};
            }

            @keyframes fade {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }

            @media (max-width: 768px) {
                .container {
                    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
                }
            }
        `
        ;
    }

    @property({type: Boolean, attribute: true})
    disabled:boolean = false;

    @property()
    label:string = '';

    @property({type: Array, attribute: false})
    photos:Array<Photo> = [];

    @internalProperty()
    showUploadDialog:boolean = false;

    render() {        
        return html`
            <div class="container">
                ${this.label && html`<div class="label">${this.label} ${this.formatPhotoCount()}</div>`}
                <div class="photo-grid">
                    <mwc-button
                        label="${i18next.t('uploadPhoto').toString()}"
                        icon="file_upload"
                        ?disabled="${this.disabled}"
                        outlined
                        @click="${ () => this.showUploadDialog = true }">
                    </mwc-button>
                    ${this.photos?.map( photo => {
                        if (this.getFileType(photo.fileName) !== '.pdf') {
                            return html`
                                <a  
                                    href="${photo.signedURL}"
                                    target="_blank"
                                    alt="Photo: ${photo.fileName}">
                                    <img 
                                        class="exam-photo hover-shadow"
                                        src="${photo.signedURL}"
                                        loading="lazy"/>
                                </a>
                            `
                        } else {
                            return html`
                                <a 
                                    href="${photo.signedURL}"
                                    style="text-decoration: none"
                                    target="_blank"
                                    alt="Exam photo: ${photo.fileName}"
                                    crossorigin>
                                    <mwc-button 
                                        class="exam-photo hover-shadow"
                                        label="PDF"
                                        src="${photo.signedURL}"
                                        loading="lazy"
                                        icon="attachment"
                                        crossorigin>
                                    </mwc-button>
                                </a> 
                            `
                        }
                        
                    })}
                </div>
                <backpackemr-photo-uploader
                    ?showUploadDialog="${this.showUploadDialog}"
                    @upload-dialog-closed="${ () => this.showUploadDialog = false}"
                ></backpackemr-photo-uploader>
            </div>
        `;
    }

    getFileType(input:string):any {
        let fileType = [];
		let charIndex = input.length-1;

		while (input.charAt(charIndex) !== '.' && charIndex > 0) {
			fileType.unshift(input.charAt(charIndex));
			charIndex--;
			if (input.charAt(charIndex) === '.') {
				fileType.unshift('.');
			}
		}
		let reducer = (previousValue, currentValue) => previousValue + currentValue; 

		return fileType.reduce(reducer);
    }

    formatPhotoCount() {
        return html`(${this.photos?.length ?? 0})`
    }
}