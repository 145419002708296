// Foundation
import { customElement, html, internalProperty, css, property } from 'lit-element';
import { ApolloLitElement } from '../apolloLitElement';

import authenticator from '../../utils/authenticator';
import { router } from '../../utils/routeRegistry';

import {
    PreventAndRedirectCommands,
    RedirectResult,
    Router,
    RouterLocation,
} from '@vaadin/router';

import '../../components/common/customTextField';
import '@material/mwc-button';
import '@material/mwc-linear-progress';
import '@vaadin/vaadin-text-field/vaadin-password-field';

import { updatePatientCustomLOVs } from '../../api/sysAdminMutations';
import { themePrimaryBlue } from '../../styles/themeColors';

@customElement('sys-admin-home')
export class SysAdminHome extends ApolloLitElement {

    static get styles() {
        return css`
                mwc-button {
                    --mdc-theme-primary: ${themePrimaryBlue};
                }
                
                mwc-linear-progress {
                    --mdc-theme-primary: ${themePrimaryBlue};
                }
            `
    }

    @internalProperty()
    campaignID: string = '';
    
    @internalProperty()
    password: string = '';

    @internalProperty()
    responseMessage: string = '';

    @internalProperty()
    resultsSuccessfulCount: number = 0;
    
    @internalProperty()
    resultsFailureCount: number = 0;
    
    @internalProperty()
    loading: boolean = false;

    render() {
        return html`
            <h1>System Admin Homepage</h1>
            <vaadin-password-field 
                label="Password" 
                value="${this.password}"
                @input="${ (event) => this.password = event?.target?.value }"
            ></vaadin-password-field>

            <h2>Update patient campaign info to current (custom LOVs, users, locations, etc.)</h2>
            <backpackemr-text-field
                label="Campaign ID"
                value="${this.campaignID}"
                @text-field-changed="${(event:CustomEvent) => { this.campaignID = event.detail.value}}"
            ></backpackemr-text-field>
            <mwc-button
                label="Update"
                raised
                @click="${this._handleUpdateCampaignClicked}"
            ></mwc-button>
            <br />
            <br />
            ${this.loading
                ? html`<div><mwc-linear-progress indeterminate></mwc-linear-progress></div>`
                : html`
                    <h3>Results</h3>
                    Message: ${this.responseMessage}
                    <br />
                    Successfully updated records: ${this.resultsSuccessfulCount}
                    <br />
                    Failed to update: ${this.resultsFailureCount}    
                `
            }
        `;
    }

    async _handleUpdateCampaignClicked(event:CustomEvent) {
        this.resultsSuccessfulCount = 0;
        this.resultsFailureCount = 0;
        this.responseMessage = '';

        this.loading = true;
        const result = await this.updateDocument(updatePatientCustomLOVs, {
            campaignID: this.campaignID,
            password: this.password
        });
        // this.results = JSON.stringify(result.updatePatientCustomLOVs);

        this.resultsSuccessfulCount = result.updatePatientCustomLOVs?.results?.patientsUpdatedCount;
        this.resultsFailureCount = result.updatePatientCustomLOVs?.results?.patientsFailedCount;
        this.responseMessage = result.updatePatientCustomLOVs?.message;

        console.log("🚀 ~ file: sysAdminHome.ts ~ line 52 ~ SysAdminHome ~ _handleBtnClick ~  result",  result)
        
        this.loading = false;
    }


    onBeforeEnter(location:RouterLocation, commands:PreventAndRedirectCommands, router:Router): Promise<unknown> | RedirectResult | undefined {
        if (!authenticator.isAuthenticated()) {
            return commands.redirect('/login');
        }
    }
}
