// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';
import * as moment from 'moment';

// Components

// Themes
import { themeSecondaryBlue } from '../../styles/themeColors';


@customElement('visit-station-status')
export class VisitStationStatus extends LitElement {
    static get styles() {
        return css`

            .clinic-station {
                display: inline-block;
                background-color: ${themeSecondaryBlue};
                color: #fff;
                padding: 2px 6px;
                border-radius: 10px;
            }

            .stationWaitTime {
                display: inline-block;
                font-weight: 700;
                text-decoration: underline;
            }
            
                @media (max-width: 768px) {
                    .queue-info {
                        justify-self: start;
                    }
                }
        `
        ;
    }

    // Properties
    @property()
    stationStatus: string

    @property()
    stationName: string

    @property()
    enteredStationDateTime: string

    render() {
        return html`
            Waiting at <div class="clinic-station">${this.stationName}</div> station for <div class="stationWaitTime">${moment(this.enteredStationDateTime).fromNow()}</div>
        `;
    }

    // Functions

}