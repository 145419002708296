import { LitElement, property, internalProperty, css, html, customElement } from 'lit-element';

import { themePrimaryBlue, themeRed } from '../../styles/themeColors';

import '@material/mwc-dialog';
import '@material/mwc-button';
import i18next from 'i18next';

@customElement('delete-patient-dialog-box')
export class DeletePatientDialogBox extends LitElement {
    static get styles() {
        return css`
            .content {
                display: grid;
            }

            .delete-button{
                --mdc-theme-primary: ${themeRed};
                --mdc-theme-primary: ${themeRed};
                --mdc-theme-on-primary: white;
            }

            .cancel-button{
                --mdc-theme-primary: white;
                --mdc-theme-on-primary: ${themePrimaryBlue};
            }
        `
    }

    @property({type: Boolean, attribute: true})
    open: boolean = false;

    render() {
        return html`
            <mwc-dialog
                ?open="${this.open}"
                heading="${i18next.t('archivePatient').toString()}"
                @closed="${() => this.handleDialogBoxClosed()}"
            >
                <div class="content">
                    ${i18next.t('archivePatientDialog1')} <br />
                    ${i18next.t('archivePatientDialog2')}
                </div>

                <mwc-button 
                    label="${i18next.t('cancel').toString()}"
                    class="cancel-button"
                    slot="secondaryAction"
                    dialogAction="cancel"
                    raised
                    @click="${this.handleCancelDelete}">
                </mwc-button>

                <mwc-button 
                    label="${`${i18next.t('yes').toString()}, ${i18next.t('archivePatient')}`}"
                    class="delete-button"
                    slot="primaryAction"
                    dialogAction="ok"
                    raised
                    @click="${this.handleConfirmDelete}">
                </mwc-button>
            </mwc-dialog>
                
            
        `
    }

    handleDialogBoxClosed() {
      this.handleCancelDelete();
    }

    handleConfirmDelete() {
        this.dispatchEvent(new CustomEvent('confirm-delete', {
            bubbles: true,
            composed: true
        }))
    }

    handleCancelDelete() {
        this.dispatchEvent(new CustomEvent('cancel', {
            bubbles: true,
            composed: true
        }))
    }

}