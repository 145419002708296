import i18next from 'i18next';

export const labTests:Array<string> = [
  'bloodTyping',
  'BG Test',
  'chlamydia',
  'coronavirusTest',
  'fullBloodCount',
  'helicobacterPylori',
  'hemogram',
  'hepatitisA',
  'hepatitisB',
  'hepatitisC',
  'hivTest',
  'pregnancyTest',
  'prostateCancerScreening',
  'rapidMalariaTest',
  'rapidStrepTest',
  'stoolAnalysis',
  'syphilis',
  'Ultrasound Eval',
  'UA Test',
  'vaginalSmear'
]