import { property, LitElement, internalProperty } from 'lit-element';
import { DocumentNode, GraphQLError } from 'graphql';
import { client } from '../api/graphQL';

export class ApolloLitElement extends LitElement {
    @property({ attribute: false})
    query: DocumentNode

    @property({ attribute: false})
    variables: any;

    @internalProperty()
    fetchPolicy: any;

    @property({ attribute: false})
    loading: boolean;

    @property({ attribute: false})
    errors: ReadonlyArray<GraphQLError>;

    @property()
    data: any;

    @internalProperty()
    mutation: DocumentNode;

    connectedCallback() {
        super.connectedCallback();
        // this.refetch(); // When an Apollo element mounts, it will always fetch (aka REFETCH) data
    }

    refetch() {        
        this.loading = true;
        this.errors = null;
        // this.data = null;
        const p = client.query({
            query: this.query,
            variables: this.variables,
            fetchPolicy: this.fetchPolicy
        }).then(result => {
            const { loading, errors, data } = result;
            this.loading = loading;
            this.errors = errors;
            this.data = data;
        })
    }

    async updateDocument(mutation: any, variables:any): Promise<any> {      
        const variablesStrippedOfTypename = this.stripTypenames(variables,'__typename');

        try {
            const result = await client.mutate({
                mutation,
                variables: variablesStrippedOfTypename,
            });
            return result.data;

        } catch (err) {
            console.error('ApolloElement - mutation error:', err.networkError.result.errors);
            console.error('ApolloElement - mutation error:', err);
            return err;
        }
    }

    // Found this online. Wish i was this sweet lol.
    // This is SUPER annoying, but there are a number of issues filed with Apollo about this. 
    // Queries return __typename properties.. but if you turn them around to do a Mutation, you can't send __typename. 
    // and you can't add it to the INPUT schema because "__" is a reserved keyword.
    stripTypenames(obj: any, propToDelete: string) {
        for (const property in obj) {
          if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
            delete obj.property;
            const newData = this.stripTypenames(obj[property], propToDelete);
            obj[property] = newData;
          } else {
            if (property === propToDelete) {
              delete obj[property];
            }
          }
        }
        return obj;
      }
}