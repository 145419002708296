import { LitElement, html, css, property, query, internalProperty, customElement } from 'lit-element';

import '@vaadin/vaadin-checkbox';
import '@vaadin/vaadin-checkbox/vaadin-checkbox-group';

import i18next from 'i18next';

@customElement('custom-checkbox-group')
export class CustomCheckboxGroup extends LitElement {
  static get styles() {
    return css`
      .container {
          display: grid;
      }

      @media (max-width: 768px) {

      }
    `
  }

  @property({type: String, attribute: false})
  label:string = '';

  @property({type: Boolean, attribute: true})
  disabled:boolean = false

  @property({type:Array, attribute: false})
  buttonOptions:Array<any> = [];

  @property({type: Number})
  itemIndex:number = 0;

  @property()
  selectedValue: string = '';

  render() {
      // console.log('customCheckboxGroup.ts ~ render ~ state',{
      //   selectedValue: this.selectedValue,
      //   buttonOptions: this.buttonOptions,
      //   itemIndex: this.itemIndex
      // });

    return html`
      <div class="container">
        <vaadin-checkbox-group
          .label="${this.label}"
          .value="${[this.selectedValue]}"
          ?disabled="${this.disabled}"
          @change="${this.handleOptionSelection}">
          ${this.buttonOptions.map( option => {
            return html`
                <vaadin-checkbox
                    value="${option.key}">
                        ${option.value}
                </vaadin-checkbox>
            `})}
        </vaadin-checkbox-group>
      </div>
    `
  }

  handleOptionSelection(event) {
    // console.log('customCheckboxGroup ~ checkbox changed ~ checked?', event.target.checked);
    // console.log('customCheckboxGroup ~ checkbox changed ~ value:', event.target.value);

    const isChecked:boolean = event?.target?.checked;
    const value:string = event?.target?.value;

    const newSelectedValue:string = isChecked ? value : null;

    this.dispatchEvent(new CustomEvent('check-changed', {
      detail: {
        value: newSelectedValue,
        index: this.itemIndex
      },
      bubbles: true,
      composed: true
    }))
  }
}