export enum SORTFIELD {
    REGISTRATION = "REGISTRATION",
    NAMEAZ = "NAMEAZ",
    NAMEZA = "NAMEZA"
  }
  
export enum SORTDIRECTION {
    ASC = "ASC",
    DESC = "DESC"
}
  