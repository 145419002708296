import { LitElement, customElement, html, internalProperty, css, property, query } from 'lit-element';
import '@vaadin/vaadin-date-time-picker';
import * as moment from 'moment'

@customElement('custom-date-time-picker')
export class CustomDateTimePicker extends LitElement {

  @property({type: String, attribute: true})
  label:string = '';

  @property({type: String, attribute: true})
  value:string = '';

  @property({type: Boolean, attribute: true})
  disabled:boolean = false;

  @property({type: Number, attribute: true})
  itemIndex:number;

  render() {
      return html`
        <vaadin-date-time-picker
          id="output"
          label=${this.label}
          @change="${this._dateSelected}"
          ?disabled="${this.disabled}"
          value="${this.formatDateForVaadin(this.value || '')}">
        </vaadin-date-time-picker>
      `;
  }

  _dateSelected(event) {
    if (event?.target?.value) {
      // do this so when we display the date for someone across the globe, it'll convert!
      const dateWithUTCindicator = moment(event?.target?.value).toISOString(); 
      
      this.dispatchEvent(new CustomEvent('date-selected', {
        detail: {
          date: dateWithUTCindicator,
          index: this.itemIndex
        },
        bubbles: true,
        composed: true
      }));
    }
  }

  formatDateForVaadin(date:string) {
    return moment(date).format('YYYY-MM-DD[T]HH:mm');
  }

}