// Foundation
import { LitElement, customElement, html, internalProperty, css, property, query } from 'lit-element';

import '@material/mwc-button';
import '@material/mwc-linear-progress';
import { themePrimaryBlue } from '../../styles/themeColors';

import photoService from '../../utils/photoService'; 

import i18next from 'i18next';

const maxFileSizeInMB = 5;
const maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024;

@customElement('backpackemr-photo-uploader')
export class CustomFileUploader extends LitElement {

    static get styles() {
        return css`
            .container {
                display: grid;
            }

            mwc-button {
                --mdc-theme-primary: ${themePrimaryBlue};
                --mdc-theme-on-primary: white;
            }

            mwc-dialog {
                --mdc-dialog-min-width: 600px;
            }

            mwc-linear-progress {
                --mdc-theme-primary: ${themePrimaryBlue};
            }

            .error-messaage {
                padding: 20px 0px;
                color: red;
            }

            @media (max-width: 768px) {
                mwc-dialog {
                    --mdc-dialog-min-width: 280px;
                }
            }
        `;
    }


    @query('#fileChooser')
    fileChooser:HTMLInputElement;

    @internalProperty()
    private file: File;

    @internalProperty()
    private loading: boolean = false;
    
    @internalProperty()
    private errorMessage: string = '';

    @property({type: Boolean})
    showUploadDialog: boolean = false;

    render() {
        return html`
            <mwc-dialog 
                ?open="${this.showUploadDialog}"
                heading="${i18next.t('uploadPhoto').toString()}"
                @closed="${event => this._handleDialogBoxClosed(event)}"
                scrimClickAction="${this.loading ? "" : "close"}"
                escapeKeyAction="${this.loading ? "" : "close"}"
            >
                ${!this.loading
                    ? html`
                        <div class="container">
                            Accepted file formats: .png .jpeg .jpg .pdf
                            <br />
                            Maximum file size: ${maxFileSizeInMB} MB
                            <br />
                            <br />
                            <input 
                                type="file" 
                                id="fileChooser"
                                accept="image/png, image/jpeg, application/pdf"
                                @change="${this._handleImageSelected}"
                            />
                            <br />
                            
                            ${this.errorMessage
                                ? html `<div class="error-messaage">${this.errorMessage}</div>`
                                : html ``
                            }                            
                        </div>
                        <mwc-button 
                            label="${i18next.t('cancel').toString()}"
                            raised
                            ?disabled="${this.loading}"
                            slot="primaryAction" 
                            dialogAction="cancel">
                        </mwc-button>
                        `
                    : html`
                        <p>Uploading...</p>
                        <mwc-linear-progress indeterminate></mwc-linear-progress>
                        <p>This may take a few moments depending on the file size and your internet connection speed.</p>
                    `
                }
            </mwc-dialog>
        `;
    }

    private async _handleImageSelected(event) {
        this.file = event?.target?.files[0];
        
        if (!this.file) return;

        if (this.file.size > maxFileSizeInBytes) {
            this.errorMessage = `Photo too large. Must not exceed ${maxFileSizeInMB} MB.`;
            return;
        }

        if (this.file.type === 'image/png' || this.file.type === 'image/jpeg' || this.file.type === 'application/pdf') {            
            this.loading = true;
            const uploadFileResp:any = await photoService.uploadPhoto(this.file);

            this.loading = false;

            this.imageUploadResult(uploadFileResp, this.file.size, this.file);

            this.showUploadDialog = false;
        }
        else {
            this.errorMessage = 'Photo must be of type .png .jpg or .jpeg';
            return;
        }
    }

    private imageUploadResult(imageUploadResp:any, imageFileSize:number, image:any){

        this.dispatchEvent(new CustomEvent('image-upload-result', {
            detail: {
                success: imageUploadResp?.success,
                message: imageUploadResp?.message,
                fileName: imageUploadResp?.fileName,
                fileSize: imageFileSize,
                localFile: image
            },
            bubbles: true,
            composed: true 
        }));
    }

    private _handleDialogBoxClosed(event) {
        //dispatch event to parent to hide the dialog
        this.errorMessage = '';
        this.file = null;
        this.loading = false;
        this.fileChooser.value = '';

        this.dispatchEvent(new CustomEvent('upload-dialog-closed', {
            bubbles: true,
            composed: true 
        }))
    }
}