// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

// Themes
import { themeSecondaryBlue, themeLightGreyBorders } from '../../../styles/themeColors';

// Components
import '../medicalTabHeader.ts';
import '../../common/photoGallery.ts';
import '../../common/customTextField';
import '../../common/customTextArea';
import '../../common/customSelect2';
import './examTreatments/examTreatments';
import '../../common/customAddRemoveButtons.ts';
import '../../common/customRadioButtons.ts';

// Translations
import i18next from 'i18next';
import { generateTranslatedList } from '../../../I18n/i18nUtil';
import { diagnosesList, treatmentTypes } from '../../../constants/primaryCare-exam';

// Models
import { ExamDetails, Treatment, Diagnosis  } from '../../../models/clinical/exam';
import { campaignCustomLists } from '../../../api/graphQL';

import photoService from '../../../utils/photoService'; 

@customElement('tab-exam')
export class TabExam extends LitElement {
    static get styles() {
        return css`
            h4 {
                margin: 12px 0;
            }

            h5 {
                margin: 12px 0;
            }

            .content {
                display: grid;
            }

            .diagnosis-section {
                display: grid;
                margin-bottom: 24px;
                border: 1px solid ${themeLightGreyBorders};
                border-radius: 4px;
                margin: 8px 0px;
                padding: 12px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
            }

            .title-and-delete {
                display: grid;
                grid-template-columns: 1fr auto;
                align-items: center;
            }

            backpackemr-remove-item-button {
                justify-self: end;
                vertical-align: middle;
            }

            .treatment-section {
                display: grid;
                margin-bottom: 24px;
                padding-left: 20px;
                border-left: 2px solid ${themeSecondaryBlue};
            }

            .treatment-title-and-delete {
                display: grid;
                grid-template-columns: 1fr auto;
            }

            .treatments {
                display: grid;
                margin-left: 20px;
                width: 90%;
            }

            .rowOneColumn {
                display: grid;
                grid-template-columns: 1fr;
                padding-bottom: 16px;
            }

            .rowTwoColumn {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 16px;
                align-items: start;
            }
            
            /* @media (max-width: 768px) { */
            @media (max-width: 900px) {
                .rowTwoColumn {
                    grid-template-columns: 1fr;
                }
            }
        `
        ;
    }

    @internalProperty()
    readOnlyMode:boolean = true;

    @property()
    patientID:string;

    @property()
    campaignID:string = '';

    @property()
    visitID:string = '';

    @property({attribute:false})
    details:ExamDetails;

    firstUpdated() {
        if (!this.details) {
            this.details = {
                type: 'patient-primaryCareExam',
                campaignId: this.campaignID,
                channels: [this.campaignID],
                patientId: this.patientID,
                updatedAt: null,
                examDoctor: null,
                examNotes: null,
                photos: [],
                diagnoses: [
                    {
                        diagnosis: null,
                        diagnosisNotes: null,
                        treatments: [
                            {
                                treatmentType: null
                            }
                        ]
                    }
                ]
            };
        }

        if(!this.details?.photos) {
            this.details.photos = [];
        }

        if(!this.details?.diagnoses || this.details?.diagnoses.length === 0) {
            
            this.details.diagnoses  = [
                {
                    diagnosis: null,
                    diagnosisNotes: null,
                    treatments: [
                        {
                            treatmentType: null
                        }
                    ]
                }
            ]
        }

        this.readOnlyMode = this.details._id ? true : false; //TODO is this the best spot in the lifecycle?
    }

    render() {
        if (!this.details){            
            return html`<div>Exam info not available</div>`;
        }

        return html`
            <div class="content">
                <medical-tab-header
                    tabName="${i18next.t('exam').toString() || ''}"
                    .updatedDateTime="${this.details?.updatedAt}"
                    ?readOnlyMode="${this.readOnlyMode}"
                    @edit-content="${this._handleEditContent}" 
                    @save="${this.tabDetailsUpdated}">
                </medical-tab-header>

                <div class="rowTwoColumn">
                    <div class="rowOneColumn">
                        <backpackemr-text-field
                            label="${i18next.t('examDoctor').toString()}"
                            .value="${this.details?.examDoctor || ''}"
                            ?disabled="${this.readOnlyMode}"
                            @text-field-changed="${(event:CustomEvent) => {
                                this._handleExamDoctorUpdate(event);
                            }}">
                        </backpackemr-text-field>
                        <backpackemr-text-area
                            label="${i18next.t('examNotes').toString()}"
                            .value="${this.details?.examNotes || ''}"
                            ?disabled="${this.readOnlyMode}"
                            @text-changed="${(event:CustomEvent) => {
                                this.details.examNotes = event.detail.value;
                                this.tabDetailsUpdated();
                            }}">
                        </backpackemr-text-area>
                    </div>
                    <div>
                        <backpackemr-photo-gallery
                            label="Exam photos"
                            .photos="${this.details?.photos}"
                            ?disabled="${this.readOnlyMode}"
                            @image-upload-result="${this._handleImageUploadResult}">
                        </backpackemr-photo-gallery>
                    </div>
                </div>

                ${this.details?.diagnoses.map( (diagnosis, diagnosisIndex) => {
                    return html`
                        <div class="diagnosis-section">
                            <div class="title-and-delete">
                                <h4>${i18next.t('diagnosis')} #${diagnosisIndex+1}</h4>
                                <custom-add-remove-buttons
                                    ?disabled="${this.readOnlyMode}"
                                    .itemIndex="${diagnosisIndex}"
                                    .lastItem="${diagnosisIndex === this.details?.diagnoses.length-1 ? true : false}"
                                    @remove-item="${() => this._removeDiagnosis(diagnosisIndex)}"
                                    @add-item="${this._addDiagnosis}">
                                </custom-add-remove-buttons>
                            </div>
                            <div class="rowTwoColumn">
                                <backpackemr-select-two
                                    label="${i18next.t('diagnosis').toString()}"
                                    .items="${campaignCustomLists().primaryCareDiagnosis || generateTranslatedList(diagnosesList)}"
                                    ?disabled="${this.readOnlyMode}"
                                    .value="${diagnosis.diagnosis || ''}"
                                    @value-changed="${(event:CustomEvent) => {
                                        this.details.diagnoses[diagnosisIndex].diagnosis = event.detail.key;
                                        this.tabDetailsUpdated();    
                                    }}">
                                </backpackemr-select-two>
                                <backpackemr-text-area
                                    label="${i18next.t('notes').toString()}"
                                    ?disabled="${this.readOnlyMode}"
                                    .value="${diagnosis.diagnosisNotes || ''}"
                                    @text-changed="${(event:CustomEvent) => {
                                        this.details.diagnoses[diagnosisIndex].diagnosisNotes = event.detail.value
                                        this.tabDetailsUpdated();
                                    }}">
                                </backpackemr-text-area>
                            </div>

                            <div class="treatments">
                                ${diagnosis.treatments.map( (treatment, treatmentIndex) => {
                                    return html`
                                        <div class="treatment-section">
                                            <div class="treatment-title-and-delete">
                                                <h5>${i18next.t('treatment')} #${treatmentIndex+1}</h5>
                                                <custom-add-remove-buttons
                                                    ?disabled="${this.readOnlyMode}"
                                                    .itemIndex="${treatmentIndex}"
                                                    .lastItem="${treatmentIndex === this.details.diagnoses[diagnosisIndex]?.treatments.length-1 ? true : false}"
                                                    @remove-item="${() => this._removeTreatment(diagnosisIndex, treatmentIndex)}"
                                                    @add-item="${() => this._addTreatment(diagnosisIndex)}">
                                                </custom-add-remove-buttons>
                                            </div>
                                            
                                            <div class="rowOneColumn">
                                                <custom-checkbox-group
                                                    .label="${i18next.t('type').toString()}"
                                                    ?disabled="${this.readOnlyMode}"
                                                    .buttonOptions="${generateTranslatedList(treatmentTypes)}"
                                                    .selectedValue="${treatment.treatmentType === 'Other' ? 'inOffice' : treatment.treatmentType || ''}"
                                                    .itemIndex="${treatmentIndex}"
                                                    @check-changed="${event => {
                                                        this._handleTreatmentSelectionChanged(event, diagnosisIndex);
                                                    }}">
                                                </custom-checkbox-group>
                                            </div>
                                            <exam-treatments
                                                .treatmentType="${treatment.treatmentType}"
                                                .treatmentDetails="${treatment}"
                                                .readOnlyMode="${this.readOnlyMode}"
                                                .diagnosisIndex="${diagnosisIndex}"
                                                .itemIndex="${treatmentIndex}"
                                                @treatment-info-updated="${this._handleTreatmentInfoUpdate}">
                                            </exam-treatments>
                                        </div>
                                    `
                                })}
                            </div>
                            <br />
                        </div>
                    `
                })}

                <br />
                <br />

                <!-- save footer went here -->
            </div>
        `;
    }

    _handleEditContent(){
        this.readOnlyMode = !this.readOnlyMode;
    }

    _handleExamDoctorUpdate(event) {
        this.details.examDoctor = event.detail.value;
        this.details?.diagnoses.map(diagnosis => diagnosis.examDoctor = event.detail.value);
        this.tabDetailsUpdated();
    }

    _handleTreatmentInfoUpdate(event) {
        this.details.diagnoses[event.detail.diagnosisIndex].treatments[event.detail.treatmentIndex] = event.detail.updatedTreatmentDetails;
        
        this.requestUpdate(); //TODO - needed?
        this.tabDetailsUpdated();
    }

    _handleTreatmentSelectionChanged(event, diagnosisIndex) {
        let selectedTreatment:string;
        if(event.detail.value === 'inOffice'){
            selectedTreatment = 'Other';
        } else {
            selectedTreatment = event.detail.value;
        }
        const emptyTreatmentWithType:Treatment = {
            treatmentType: selectedTreatment,
            dispense: null,
            dosage: null,
            drug: null,
            inClinicTreatment: null,
            inClinicTreatmentNotes: null,
            pharmacyCounted: false,
            pharmacyCountedUserDate: null,
            pharmacyFilled: false,
            pharmacyFilledUserDate: null,
            referralDetails: null,
            referralLocation: null,
            referralPlan: null,
            referralTimeframe: null
        }
        this.details.diagnoses[diagnosisIndex].treatments[event.detail.index] = emptyTreatmentWithType;
        this.requestUpdate();  // probably don't need this because it's going to re-render from the top after we save()..
        this.tabDetailsUpdated();
    }

    _addDiagnosis() {
        const treatment:Treatment = {
            dispense: null,
            dosage: null,
            drug: null,
            inClinicTreatment: null,
            inClinicTreatmentNotes: null,
            pharmacyCounted: false,
            pharmacyCountedUserDate: null,
            pharmacyFilled: false,
            pharmacyFilledUserDate: null,
            referralDetails: null,
            referralLocation: null,
            referralPlan: null,
            referralTimeframe: null,
            treatmentType: null
        };

        const diagnosis:Diagnosis = {
            diagnosis: null,
            diagnosisNotes: null,
            examDoctor: this.details?.examDoctor,
            treatments: [treatment]
        };

        this.details?.diagnoses.push(diagnosis);
        this.requestUpdate();
    }

    _addTreatment(diagnosisIndex) {
        const treatment:Treatment = {
            dispense: null,
            dosage: null,
            drug: null,
            inClinicTreatment: null,
            inClinicTreatmentNotes: null,
            pharmacyCounted: false,
            pharmacyCountedUserDate: null,
            pharmacyFilled: false,
            pharmacyFilledUserDate: null,
            referralDetails: null,
            referralLocation: null,
            referralPlan: null,
            referralTimeframe: null,
            treatmentType: null
        }

        this.details?.diagnoses[diagnosisIndex].treatments.push(treatment)
        this.requestUpdate();
    }

    _removeDiagnosis(index:number) {
        const diagnosisIndex = index;
        if(this.details?.diagnoses.length !== 1) {
            this.details.diagnoses = this.details?.diagnoses.filter((item, index) => index !== diagnosisIndex);
        }

        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    _removeTreatment(diagnosisIndex:number, treatmentIndex:number){
        if(this.details?.diagnoses[diagnosisIndex].treatments.length !== 1) {
            this.details.diagnoses[diagnosisIndex].treatments = this.details?.diagnoses[diagnosisIndex].treatments.filter((item, index) => index !== treatmentIndex);
        }

        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    getUpdateMutationVariables() {
        const updatedExamInfo:ExamDetails = {
            patientId: this.patientID,
            campaignId: this.campaignID,
            channels: [this.campaignID],
            _id: this.details?._id,
            _rev: this.details?._rev,
            updatedAt: new Date().toISOString(),
            type: 'patient-primaryCareExam',

            examDoctor: this.details?.examDoctor,
            examNotes: this.details?.examNotes,
            photos: null,
            diagnoses: this.details?.diagnoses
        }

        if (this.details?.photos?.length > 0) {
            updatedExamInfo.photos = this.details?.photos.map(photo => {
                return {
                    fileName: photo.fileName
                }
            });
        }

        return {
            tab: 'EXAM',
            id: this.details?._id,
            tabDetails: updatedExamInfo,
            visitID: this.visitID,
        }
    }

    async _handleImageUploadResult(event) {
        if (event?.detail?.success && event?.detail?.fileName && event?.detail?.fileSize) {

            this.details.photos.push({
                fileName: event.detail.fileName
            });

            const updateImmediately = true;
            this.tabDetailsUpdated(updateImmediately);

            //needed so the app can properly download and display the photos uploaded from the web portal.
            photoService.createPhotoRecord(this.getPhotoUploadVariables(event.detail.fileName, event.detail.fileSize));
        }
        else {
            //todo - add error handling
            console.error('_handleImageUpload ~ failed to upload ~ message:', event?.detail?.message);
        }
    }

    tabDetailsUpdated(saveImmediately:boolean = false) {
        this.dispatchEvent(new CustomEvent('tab-info-updated', {
            detail: {
                variables: this.getUpdateMutationVariables(),
                saveImmediately: saveImmediately
            },
            bubbles: true,
            composed: true
        }));
    }

    getPhotoUploadVariables(fileName:string, fileSize:number) {
        return {
            channels: [this.campaignID],
            fileName,
            fileSize,
            isVertical: false,
            patientCampaignId: this.visitID,
            patientId: this.patientID,
            pendingUploadToS3: false,
            photoTag: "primaryCareExamPhotos",
            type: "photo",
            updatedAt: new Date().toISOString(),
        }
    }

    saveTabDetailsNow(){
        this.dispatchEvent(new CustomEvent('save-tab-info', {
            detail: {
                variables: this.getUpdateMutationVariables()
            },
            bubbles: true,
            composed: true
        }));
    }
}