import { LitElement, customElement, html, internalProperty, css, property, query } from 'lit-element';
import '@vaadin/vaadin-date-picker/vaadin-date-picker';
import * as moment from 'moment';

@customElement('custom-date-picker')
export class CustomDatePicker extends LitElement {
  static get styles() {
    return css`
      .container {
        display: grid;
      }

    @media (max-width: 768px) {

    }
    `;}

  @property({type: String, attribute: true})
  label:string = '';

  @property({type: String, attribute: true})
  value:string = '';

  @property({type: Boolean, attribute: true})
  disabled:boolean = false;

  @property({type: Number, attribute: true})
  itemIndex:number;

  render() {
      return html`
        <div class="container">
          <vaadin-date-picker
            id="output"
            label=${this.label}
            ?readonly="${this.disabled}"
            value="${this.formatDateForVaadin(this.value || '')}"
            @change="${this._dateSelected}"
            >
          </vaadin-date-picker>
        </div>
      `;
  }

  _dateSelected(event) {
    const dateWithUTCindicator = moment(event?.target?.value).toISOString(); 

    this.dispatchEvent(new CustomEvent('date-selected', {
      detail: {
        date: dateWithUTCindicator,
        index: this.itemIndex
      },
      bubbles: true,
      composed: true
    }))
  }

  formatDateForVaadin(date:string) {
    if (date.length > 10) return date.substr(0, date.indexOf('T'));
    else return date;
  }



}