// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

// Themes
import { themePrimaryBlue, themeLightGreyBorders } from '../../../styles/themeColors';

// Components
import '../medicalTabHeader.ts';
import '../../common/customAddNewItemButton.ts';
import '../../common/removeItemButton.ts';
import '../../common/customSelect2.ts';
import '../../common/customCheckbox.ts';
import '../../common/customDatePicker.ts';
import '../../common/customTextField.ts';
import '../../common/customTextArea.ts';
import '../../common/customDateTimePicker.ts';
import '../../common/photoGallery.ts';

//translations
import i18next from 'i18next';
import { generateTranslatedList } from '../../../I18n/i18nUtil';
import { labTests } from '../../../constants/primaryCare-labs';

// Models
import { LabsDetails } from '../../../models/clinical/labs';
import photoService from '../../../utils/photoService';

//Utility
import { v4 as uuidv4 } from 'uuid';

@customElement('tab-labs')
export class TabLabs extends LitElement {

    static get styles() {
        return css`
            .content {
                display: grid;
            }

            .section {
                display: grid;   
                border: 1px solid ${themeLightGreyBorders};
                border-radius: 4px;
                margin: 8px 0px;
                padding: 12px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
            }

            h4 {
                margin: 8px 0;
            }
            
            .test-info {
                display: grid;
                grid-template-columns: 1fr; // 1fr;
                grid-column-gap: 16px; 
            }

            backpackemr-remove-item-button {
                justify-self: end;
                vertical-align: middle;
            }

            .title-and-add-remove {
                display: grid;
                grid-template-columns: 1fr auto;
                align-items: center;
            }

            .lab-info-row {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 5px;
                align-items: center;
                margin-top: 15px;
            }

            .lab-info-row-one {
                align-self: center;
            }


            .lab-complete-button {
                justify-self: center;
            }

            .notes-container {
                height: 100%;
            }


            .notes-and-photos {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }

            @media (max-width: 768px) {
                /* TODO: make 1 column.*/
                .test-info {
                    grid-template-columns: 1fr;
                    padding-bottom: 16px;
                }

                .lab-complete-data {
                    grid-template-columns: 1fr;
                }
            }
        `
        ;
    }

    @property({attribute: false})
    details: LabsDetails;

    @internalProperty()
    readOnlyMode:boolean = true;

    @property()
    loggedInUser: string;

    @property()
    patientID:string = '';

    @property()
    visitID:string = '';

    @property()
    campaignID:string = '';

    firstUpdated() {
        if (!this.details) {
            this.details = {
                type: 'patient-primaryCareLabs',
                campaignId: this.campaignID,
                channels: [this.campaignID],
                patientId: this.patientID,
                updatedAt: null,
                labs: [
                    {
                        labComplete: false,
                        labCompletedDate: null,
                        labCompletedUser: null,
                        labFacility: null,
                        notes: null,
                        results: null,
                        test: null,
                        photoKey: uuidv4(),
                        photos: []
                    }
                ]
            };
        }

        this.readOnlyMode = this.details?._id ? true : false;
    }

    render() {
        if(!this.details){
            return html`
                <h3>${i18next.t('labs')}</h3>
                <p>${i18next.t('noLabsData')}</p>
            `;
        }
        
        return html`
            <div class="content">
                <medical-tab-header
                    tabName="${i18next.t('labs').toString() || ''}"
                    .updatedDateTime="${this.details?.updatedAt}"
                    ?readOnlyMode="${this.readOnlyMode}"
                    @edit-content="${this._handleEditContent}">
                </medical-tab-header>

                ${this.details?.labs.map( (lab, index) => {
                    return html`
                        <div class="section">    
                            <div class="title-and-add-remove">
                                <h4>${i18next.t(`${i18next.t('lab').toString()} #${index + 1}`)}</h4>
                                <custom-add-remove-buttons
                                    ?disabled="${this.readOnlyMode}"
                                    .itemIndex="${index}"
                                    .lastItem="${index === this.details.labs.length-1 ? true: false}"
                                    @remove-item="${this._removeLab}"
                                    @add-item="${this._addLab}">
                                </custom-add-remove-buttons>
                            </div>

                            <div class="test-info">
                                <div class="lab-info-row">
                                    <backpackemr-select-two
                                        label="${i18next.t('test').toString()}"
                                        .items="${generateTranslatedList(labTests)}"
                                        ?disabled="${this.readOnlyMode}"
                                        .value="${lab?.test}"
                                        @value-changed="${(event:CustomEvent) => {
                                            this.details.labs[event.detail.itemIndex].test  = event.detail.key;
                                            this.tabDetailsUpdated();
                                        }}"
                                        itemIndex="${index}">
                                    </backpackemr-select-two>

                                    <backpackemr-checkbox 
                                        label="${i18next.t('labComplete').toString()}"
                                        ?checked="${lab?.labComplete}" 
                                        ?disabled="${this.readOnlyMode}"
                                        @check-changed="${() => this._toggleLabComplete(index)}">
                                    </backpackemr-checkbox>
                                </div>

                                <div class="lab-info-row">
                                    <backpackemr-text-field
                                        label="${i18next.t('results').toString()}"
                                        .value="${lab?.results || ' '}"
                                        ?disabled="${this.readOnlyMode}"
                                        @text-field-changed="${(event:CustomEvent) => {
                                            this.details.labs[event.detail.itemIndex].results = event.detail.value;
                                            this.tabDetailsUpdated();
                                        }}"
                                        itemIndex="${index}">
                                    </backpackemr-text-field>

                                    <custom-date-time-picker
                                        label="${i18next.t('labCompletedDate').toString()}"
                                        .value="${lab?.labCompletedDate}"
                                        ?disabled="${this.readOnlyMode}"
                                        @date-selected="${this._handleDateSelected}"
                                        itemIndex=${index}>
                                    </custom-date-time-picker>
                                </div>

                                <div class="lab-info-row">
                                    <backpackemr-text-field
                                        label="${i18next.t('labFacility').toString()}"
                                        .value="${lab?.labFacility || ' '}"
                                        ?disabled="${this.readOnlyMode}"
                                        @text-field-changed="${(event:CustomEvent) => {
                                            this.details.labs[event.detail.itemIndex].labFacility = event.detail.value;
                                            this.tabDetailsUpdated();
                                        }}"
                                        itemIndex="${index}">
                                    </backpackemr-text-field>

                                    <backpackemr-text-field
                                        label="${i18next.t('labCompletedUser').toString()}"
                                        .value="${lab?.labCompletedUser}"
                                        ?disabled="${true}"
                                        itemIndex=${index}>
                                    </backpackemr-text-field>
                                </div>

                                <div class="lab-info-row">
                                    <backpackemr-text-area
                                        class="notes-container"
                                        label="${i18next.t('notes').toString()}"
                                        .value="${lab?.notes || ' '}"
                                        ?disabled="${this.readOnlyMode}"
                                        @text-changed="${(event:CustomEvent) => {
                                            this.details.labs[event.detail.itemIndex].notes = event.detail.value;
                                            this.tabDetailsUpdated();
                                        }}"
                                        itemIndex="${index}">
                                    </backpackemr-text-area>

                                    <backpackemr-photo-gallery
                                        label="${i18next.t('labPhotos').toString()}"
                                        .photos="${this.details?.labs[index].photos}"
                                        ?disabled="${this.readOnlyMode}"
                                        @image-upload-result="${event => this._handleImageUploadResult(event, index)}">
                                    </backpackemr-photo-gallery>
                                </div>
                            </div>
                        </div>
                    `
                })}
            </div>
        `;
    }

    async _handleImageUploadResult(event, index) {
        if (event?.detail?.success && event?.detail?.fileName && event?.detail?.fileSize) {
            this.details.labs[index].photos.push({
                fileName: event.detail.fileName
            });

            const updateImmediately = true;
            this.tabDetailsUpdated(updateImmediately);

            //needed so the app can properly download and display the photos uploaded from the web portal.
            photoService.createPhotoRecord(this.getPhotoUploadVariables(event.detail.fileName, event.detail.fileSize));
        }
        else {
            //todo - add error handling
            console.error('_handleImageUpload ~ failed to upload ~ message:', event?.detail?.message);
        }
    }

    getPhotoUploadVariables(fileName:string, fileSize:number) {
        return {
            channels: [this.campaignID],
            fileName,
            fileSize,
            isVertical: false,
            patientCampaignId: this.visitID,
            patientId: this.patientID,
            pendingUploadToS3: false,
            photoTag: "primaryCareExamPhotos",
            type: "photo",
            updatedAt: new Date().toISOString(),
        }
    }

    _handleEditContent() {
        this.readOnlyMode = !this.readOnlyMode;
    }

    _toggleLabComplete(index:number) {
        if(!this.details.labs[index].labComplete){
            this.details.labs[index].labCompletedUser = `${this.loggedInUser}`;
            this.details.labs[index].labCompletedDate = `${new Date().toISOString()}`;
        } else {
            this.details.labs[index].labCompletedUser = '';
            this.details.labs[index].labCompletedDate = '';
        }
        this.details.labs[index].labComplete = !this.details.labs[index].labComplete;
        
        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    _handleDateSelected(event:any) {
        this.details.labs[event.detail.index].labCompletedDate = event.detail.date;
        
        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    _addLab() {
        this.details.labs.push({
            labComplete: false,
            labCompletedDate: '',
            labCompletedUser: '',
            labFacility: '',
            notes: '',
            results: '',
            test: '',
            photoKey: uuidv4(),//Date.now().toString(),
            photos: []
        })

        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    _removeLab(event) {
        const itemIndex = event.detail.itemIndex;
        if(this.details.labs.length !== 1) {
            this.details.labs = this.details.labs.filter((item, index) => index !== itemIndex);
        }
        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    getUpdateMutationVariables() {
        return {
            tab: 'LABS',
            id: this.details?._id,
            visitID: this.visitID,
            tabDetails: {
                _id: this.details?._id,
                _rev: this.details?._rev,
                campaignId: this.details.campaignId,
                channels: this.details.channels,
                patientId: this.details.patientId,
                updatedAt: new Date().toISOString(),
                type: 'patient-primaryCareLabs',
                labs: this.details.labs
            }
        }
    }

    tabDetailsUpdated(saveImmediately:boolean = false){
        this.dispatchEvent(new CustomEvent('tab-info-updated', {
            detail: {
                variables: this.getUpdateMutationVariables(),
                saveImmediately: saveImmediately
            },
            bubbles: true,
            composed: true
        }));
    }
}