// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

// Themes

// Components
import '../medicalTabHeader.ts';
import '../../common/customSelect.ts';
import '../../common/customAddRemoveButtons.ts';

//translations
import i18next from 'i18next';
import { generateTranslatedList } from '../../../I18n/i18nUtil';
import { complaintsList } from '../../../constants/primaryCare-complaints';

// Models
import { ComplaintsDetails } from '../../../models/clinical/complaints';

@customElement('tab-complaints')
export class TabComplaints extends LitElement {

    static get styles() {
        return css`
            .content {
                display: grid;
            }
            
            .row {
                display: grid;
                grid-column-gap: 16px;
                grid-template-columns: 1fr 3fr ;
                align-items: start;
                padding-bottom: 18px;
            }

            .notes-add-remove {
                display: grid;
                grid-column-gap: 16px;
                grid-template-columns: 1fr auto;
            }

            @media (max-width: 768px) {
                .row {
                    display: grid;
                    grid-template-columns: 1fr;
                }
            }
        `
        ;
    }

    @property({attribute: false})
    details: ComplaintsDetails;
    
    @internalProperty()
    readOnlyMode:boolean = true;

    @property()
    patientID:string = '';

    @property()
    visitID:string = '';

    @property()
    campaignID:string = '';

    firstUpdated() {        
        if (!this.details) {
            this.details = {
                type: 'patient-primaryCareComplaint',
                campaignId: this.campaignID,
                channels: [this.campaignID],
                patientId: this.patientID,
                updatedAt: null,
        
                complaints: [
                    {
                        complaint: null,
                        complaintNotes: null
                    }
                ]
            };
        }

        this.readOnlyMode = this.details?._id ? true : false;
    }

    render() {
        if(!this.details){
            return html`
                <h3>${i18next.t('complaints')}</h3>
                <p>${i18next.t('noComplaintsData')}.</p>
            `;
        }
        return html`
            <div class="content">
                <medical-tab-header
                    tabName="${i18next.t('complaints').toString() || ''}"
                    .updatedDateTime="${this.details?.updatedAt}"
                    ?readOnlyMode="${this.readOnlyMode}"
                    @edit-content="${this._handleEditContent}">
                </medical-tab-header>

                <div class="section">    
                    ${this.details?.complaints.map( (complaint, index) => {
                        return html`
                            <div class="row">
                                <backpackemr-select-two
                                    .items="${generateTranslatedList(complaintsList)}"
                                    ?disabled="${this.readOnlyMode}"
                                    .value="${complaint?.complaint}"
                                    @value-changed="${(event:CustomEvent) => {
                                        this.details.complaints[event.detail.itemIndex].complaint = event.detail.key
                                        this.tabDetailsUpdated();
                                    }}"
                                    itemIndex="${index}">
                                </backpackemr-select-two>

                                <div class="notes-add-remove">
                                    <backpackemr-text-area
                                        placeholder="${i18next.t("notes").toString()}"
                                        ?disabled="${this.readOnlyMode}"
                                        .value="${complaint?.complaintNotes || ''}"
                                        @text-changed="${(event:CustomEvent) => {
                                            this.details.complaints[event.detail.itemIndex].complaintNotes = event.detail.value;
                                            this.tabDetailsUpdated();
                                        }}"
                                        itemIndex="${index}">
                                    </backpackemr-text-area>
                                    
                                    <custom-add-remove-buttons
                                        ?disabled="${this.readOnlyMode}"
                                        .itemIndex="${index}"
                                        .lastItem="${index === this.details.complaints.length-1 ? true : false}"
                                        @remove-item="${this._removeComplaintItem}"
                                        @add-item="${this._addComplaint}">
                                    </custom-add-remove-buttons>
                                </div>
                            </div>
                        `
                    })}
                </div>
            </div>
        `;
    }

    _handleEditContent(){
        this.readOnlyMode = !this.readOnlyMode;
    }

    _addComplaint() {
        this.details.complaints.push({
            complaint: '',
            complaintNotes: ''
        });

        this.requestUpdate();
    }

    _removeComplaintItem(evt) {
        const itemIndex = evt.detail.itemIndex;
        if(this.details.complaints.length !== 1) {
            this.details.complaints = this.details.complaints.filter( (item, index) => index !== itemIndex);
        }

        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    getUpdateMutationVariables() {
        return {
            tab: 'COMPLAINTS',
            id: this.details?._id,
            visitID: this.visitID,
            tabDetails: {
                patientId: this.details?.patientId,
                campaignId: this.details?.campaignId,
                channels: this.details?.channels,
                _id: this.details._id,
                _rev: this.details._rev,
                updatedAt: new Date().toISOString(),
                type: 'patient-primaryCareComplaint',
                
                complaints: this.details?.complaints,
            }
        };
    }

    tabDetailsUpdated(){
        this.dispatchEvent(new CustomEvent('tab-info-updated', {
            detail: {
                variables: this.getUpdateMutationVariables()
            },
            bubbles: true,
            composed: true
        }));
    }
}