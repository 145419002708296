import { gql } from '@apollo/client/core';

export const QUERY_PATIENT_CHART = gql`
	query PatientQuery($patientID: String!, $visitID: String, $tab: String, $selectedCampaignID: String) {
		patient(patientID: $patientID, visitID: $visitID, tab: $tab, selectedCampaignID: $selectedCampaignID) {
			patientID
			patientProfileSummary {
				firstName
				lastName
				gender
				governmentID
				humanID
				dateOfBirth
				profilePhoto {
					fileName
					signedURL
				}
				currentCampaignData {
					clinicNumber
					queue {
						queueId
						status
						timestamp
					}
				}
			}
			visits {
				visitDateTimeEpoch
				visitDateTime
				campaignID
				locationID
				medicalModule
				patientCampaignID
			}
			selectedVisit {
				visitDateTimeEpoch
				visitDateTime
				campaignID
				locationID
				medicalModule
				patientCampaignID
				campaignCustomLists {
					primaryCareDiagnosis
					primaryCareTreatments
					primaryCareDrugs
				}
				visitMedicalDetails {
					complaintDetails {
						_id
						_rev
						updatedAt
						type
						campaignId
						channels
						patientId
						complaints {
							complaint
							complaintNotes
						}
					}
					historyDetails {
						_id
						_rev
						updatedAt
						type
						campaignId
						channels
						patientId
						pregnant
						hivPositive
						breastFeeding
						chronicHistories {
							history
							historyNotes
						}
						currentMedications {
							currentMedication
							medicationNotes
						}
						allergies {
							allergy
							allergyNotes
						}
					}
					vitalsDetails {
						_id
						_rev
						campaignId
						channels
						patientId
						updatedAt
						type
						armCircumferenceCm
						armCircumferenceIn
						bloodPressureSystolic
						bloodPressurediastolic
						bmi
						headCircumferenceCm
						headCircumferenceIn
						heartRate
						heightCm
						heightIn
						o2Saturation
						respiratoryRate
						temperatureC
						temperatureF
						vitalsNotes
						weightKg
						weightLbs
						zScoreArmCircumference
						zScoreBmi
						zScoreHeightWeight
					}
					publicHealthDetails {
						_id
						_rev
						campaignId
						channels
						updatedAt
						type
						patientId
						contacts
						cookingConditions
						drinkingWaterFiltered
						firstSymptomsDate
						knownCaseCloseContact
						livingConditions
						notes
						occupationalRiskGroup
						traveledInternationally
						visitedCountries
					}
					labsDetails {
						_id
						_rev
						campaignId
						channels
						patientId
						updatedAt
						type
						labs {
							labComplete
							labCompletedDate
							labCompletedUser
							labFacility
							notes
							results
							test
							photoKey
							photos {
								fileName
								signedURL
							}
						}
					}
					visionDetails {
						_id
						_rev
						type
						campaignId
						channels
						updatedAt
						patientId
						eyeDiagnoses {
							eyeDiagnosis
							eyeDiagnosisNotes
						}
						eyeTreatments {
							eyeTreatment
							patientReceived
							treatmentCompletedByandDate
						}
					}
					examDetails {
						_id
						_rev
						campaignId
						patientId
						channels
						updatedAt
						type
						examDoctor
						examNotes
						photos {
							fileName
							signedURL
						}
						diagnoses {
							diagnosis
							diagnosisNotes
							examDoctor
							treatments {
								treatmentType
								drug
								dispense
								dosage
								inClinicTreatment
								inClinicTreatmentNotes
								referralDetails
								referralLocation
								referralPlan
								referralTimeframe
								pharmacyCounted
								pharmacyCountedUserDate
								pharmacyFilled
								pharmacyFilledUserDate
							}
						}
					}
					virtualCareDetails {
						_id
						_rev
						campaignId
						channels
						patientId
						reasonForConsult
						type
						updatedAt
					}
				}
			}
		}
	}
`;

export const patientProfileQuery = gql`
	query PatientQuery($patientID: String!, $selectedCampaignID: String) {
		patientProfile(patientID: $patientID, selectedCampaignID: $selectedCampaignID) {
			patientID
			updatedAt
			patientProfileSummary {
				firstName
				lastName
				gender
				governmentID
				humanID
				dateOfBirth
				profilePhoto {
					fileName
					signedURL
				}
				currentCampaignData {
					clinicNumber
					queue {
						queueId
						status
						timestamp
					}
				}
			}
			patientProfileDetails {
				middleName
				occupation
				phoneNumber
				contactName
				email
				addressLine1
				addressLine2
				addressLine3
				insuranceType
				insuranceID
				patientConsented
				patientConsentedUserDate
				archived
			}
		}
	}
`;
