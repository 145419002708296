export class WebConsoleLog {
    segments:Array<WebConsoleLogSegment> = [];
    
    constructor() {
    }

    segment(segment:WebConsoleLogSegment): WebConsoleLog {
        this.segments.push(segment);
        return this;
    }
  
    log() {
        var logStatement = "";
        var styles = [];
        this.segments.forEach(s => {
            logStatement += "%c" + s.consoleText + " ";
            styles.push(this.createStyle(s));
        });
        // console.log(logStatement, ...styles)
    }    

    private createStyle(segment:WebConsoleLogSegment): String {
        var boltStatement = ""
        if (segment.isBold) {
            boltStatement = `font-weight:bold;`;
        }

        var backgroundColorStatement = ""
        if (segment.backgroundColor) {
            backgroundColorStatement = `background:${segment.backgroundColor};`
        }

        var foregroundColorStatement = ""
        if (segment.foregroundColor) {
            foregroundColorStatement = `color:${segment.foregroundColor};`
        }

        return `${boltStatement}${backgroundColorStatement}${foregroundColorStatement}`;
    }
}

export class WebConsoleLogSegment {
    backgroundColor:String;
    foregroundColor:String;
    isBold:Boolean;
    consoleText:String;

    constructor(text:String = null) {
        this.consoleText = text;
    }

    object(o:any): WebConsoleLogSegment {
        this.consoleText = JSON.stringify(o);
        return this;
    }
    
    bold(): WebConsoleLogSegment {
        this.isBold = true;
        return this;
    }

    color(color:String): WebConsoleLogSegment {
        this.foregroundColor = color;
        return this;
    }

    background(color:String): WebConsoleLogSegment {
        this.backgroundColor = color;
        return this;
    }    
}