import { LitElement, customElement, html, css, property } from 'lit-element';

import { themeSecondaryBlue } from '../../styles/themeColors';

import '@material/mwc-button';

@customElement('custom-add-remove-buttons')
export class CustomAddRemoveButton extends LitElement {
  static get styles() {
    return css`
      .container {
        display: grid;
        grid-template-columns: 1fr auto;
        padding-top: 6px;
        justify-self: center;
      }

      mwc-button {
          --mdc-theme-primary: ${themeSecondaryBlue};
      }
      
    `
  }

  @property({type: Boolean, attribute: true})
  disabled:boolean = false;

  @property({type:Number, attribute: false})
  itemIndex:number;

  @property({type: Boolean, attribute: false})
  lastItem:boolean = false;

  render() {
    return html`
        <div class="container">
            <mwc-button 
                icon="remove_circle_outline"
                ?disabled="${this.disabled}"
                @click="${this._removeItem}">
            </mwc-button>

            ${this.lastItem ? 
                html`
                  <mwc-button
                      icon="add_circle_outline"
                      ?disabled=${this.disabled}
                      @click="${this._addItem}">
                  </mwc-button>
                ` 
                : html ``
            }
        </div>
    `
  }

  _removeItem() {
    this.dispatchEvent(new CustomEvent('remove-item', {
      detail: {
        itemIndex: this.itemIndex
      },
      bubbles: true,
      composed: true
    }));
  }

  _addItem() {
    this.dispatchEvent(new CustomEvent('add-item', {
      bubbles: true,
      composed: true
    }));
  }
}