// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

// Themes
import { themePrimaryBlue, themeSecondaryBlue, themeHoverGrey, themeLightGreyBorders, themeDarkGrey } from '../../../../styles/themeColors';

// Components
import '@material/mwc-list/mwc-list-item';
import '../../../common/customCheckbox.ts';


// Models
import { Treatment } from '../../../../models/clinical/exam';

// Util
import i18next from 'i18next';
import { getCurrentNumericDateTime } from '../../../../utils/dateTimeFormatter';
@customElement('in-clinic-card')
export class InClinicCard extends LitElement {

    static get styles() {
        return css`
            .card {
                display: grid;
                border: 1px solid ${themeLightGreyBorders};
                border-radius: 4px;
                margin: 6px 0;
                padding: 12px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
            }

            .card-header {
                display: grid;
                grid-template-columns: 1fr;
                font-size: 18px;
                font-weight: 500;
                margin: 4px 0 8px;
            }

            .card-body {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 12px;
            }

            .followup-details {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: 12px;
            }

            .filled-section {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: 8px;
                font-size: 14px;
                justify-content: start;
                margin-top: 10px;
            }
            
            .row {
                display: grid;
                grid-column-gap: 16px;
                padding-bottom: 16px;
            }

            mwc-checkbox {
                --mdc-theme-secondary: ${themePrimaryBlue}
            }

            .icon-info {
                display: flex;
                gap: 10px;
                align-items: start;
            }

            .icon-text-content {
                padding-top: 3px;
            }

            @media (max-width: 768px) {
                .row {
                    display: grid;
                    grid-template-columns: 1fr;
                }

                .card-body {
                    grid-template-columns: 1fr;
                }
                
                .followup-details {
                    margin-bottom: 8px;
                }
            }
        `
        ;
    }

    @property({attribute: false})
    treatment: Treatment;

    @property({type: String, attribute: true})
    examDoctor: string;

    @property({type: Boolean, attribute: true})
    readOnly: boolean = true;

    @property({type: Number, attribute: true})
    treatmentIndex: number;

    @property({type: Number, attribute: true})
    diagnosisIndex: number;

    @property({type: String, attribute: true})
    loggedInUser:string;
    
    render() {

        if (!this.treatment) {
            return html`
                <p>No info available</p>
            `;
        }
        
        return html`
            <div class="card">
                <div class="card-header">
                    ${i18next.t(this.treatment.inClinicTreatment)}
                </div>
                <div class="card-body">
                    <div class="followup-details">
                        <div class="icon-info">
                            <mwc-icon slot="graphic" class="inverted big-icon">notes</mwc-icon>
                            <div class="icon-text-content">${i18next.t('details')}: ${this.treatment.inClinicTreatmentNotes}</div>
                        </div>
                        <div class="icon-info">
                            <mwc-icon slot="graphic" class="inverted big-icon">person</mwc-icon>
                            <div class="icon-text-content">${i18next.t('saw')} ${this.examDoctor}</div>
                        </div>
                    </div>
                    <div class="filled-section">
                    <backpackemr-checkbox
                        label="${this._formatCheckboxLabel('reviewed', this.treatment.pharmacyFilledUserDate, this.treatment.pharmacyFilled)}"
                        ?checked="${this.treatment.pharmacyFilled}"
                        @check-changed="${() => this._toggleReviewed()}"
                        ?disabled="${this.readOnly}">
                    </backpackemr-checkbox>
                    </div>
                </div>
            </div>
        `;
    }

    _formatCheckboxLabel(label:string, byUserDate:string, checked:boolean) {
        return byUserDate && checked ? `${i18next.t(label)} (${i18next.t('completedUser')} ${byUserDate})` : i18next.t(label);
    }

    _toggleReviewed(){
        if(!this.treatment.pharmacyFilledUserDate){
            this.treatment.pharmacyFilledUserDate = `${this.loggedInUser} ${getCurrentNumericDateTime()}`;
        } else {
            this.treatment.pharmacyFilledUserDate = ''
        }
        this.treatment.pharmacyFilled = !this.treatment.pharmacyFilled;
        this.requestUpdate();
        this._dispatchCheckChange();
    }

    _dispatchCheckChange(){
        this.dispatchEvent(new CustomEvent('treatment-info-updated', {
            detail: {
                diagnosisIndex: this.diagnosisIndex,
                treatmentIndex: this.treatmentIndex,
                updatedTreatmentInfo: this.treatment
            },
            bubbles: true,
            composed: true
        }))
    }
}