export const occupationalRiskGroups:Array<string> = [
  'None',
  'emergencyRoom',
  'intensiveCareUnit',
  'generalMedicalWard',
  'isolationCenter',
  'quarantineCenter',
  'otherHealthcareWorker',
  'groupHomeFacility'
]

export const livingConditions:Array<string> = [
  'apartment',
  'groupHome',
  'house'
]

export const cookingConditions:Array<string> = [
  'electricStove',
  'gasStove',
  'openFire'
]

export const booleanResponses:Array<string> = [
  'yes',
  'no'
]