export const complaintsList:Array<string> = [
    'Abdominal Pain/Bloating',
    'Acne',
    'Altered consciousness/confusion',
    'Anal-Rectal Issue',
    'Anemia',
    'Back Pains',
    'Bleeding (Haemorrhage)',
    'Body Pains',
    'Burn',
    'Check Up',
    'Chest Pain',
    'Constipation',
    'Cough',
    'Cough - Dry',
    'Cough with bloody sputum',
    'Cough with sputum production',
    'Diarrhea',
    'Ear pain',
    'Erectile dysfunction',
    'Eye problem',
    'Family Planning',
    'Fatigue',
    'Fever',
    'Headache',
    'Heartburn',
    'Heel Pain',
    'Itching',
    'Joint Pain',
    'Lower chest wall indrawing',
    'Medication Refill',
    'Muscle aches',
    'Nasal Stuffiness/Runny',
    'Nausea',
    'Nipple Discharge',
    'Numbness',
    'Poor Appetite',
    'Runny nose',
    'Seizures',
    'Shortness of breath',
    'Skin rash/infection',
    'Skin ulcers',
    'Sneezing',
    'Sore Throat',
    'Toothache',
    'Urine Issues',
    'Vaginal Discharge/Itching',
    'Vision Blurred',
    'Vomiting',
    'Wheezing',
    'Wound'
]