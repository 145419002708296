import { LitElement, html, property, css, customElement, TemplateResult } from 'lit-element';
// import { outlet } from 'lit-element-router';

import './patientList/patientList.ts';

@customElement('backpackemr-main')
export class BackpackEmrMain extends LitElement {
// export class BackpackEmrMain extends outlet(LitElement) {
  
  static get styles() {
    return css`
        .centered-app{
            display: grid;
            grid-template-columns: 1fr minmax(auto, 1200px) 1fr;
            padding-left: 12px;
            padding-right: 12px;
            background: #f4f4f4;
        }

        .main-content {
            grid-column: 2;
        }
 
        @media (max-width: 768px) {

        }
    `
    ;
  }

  render():TemplateResult {
    return html`
      <main class="centered-app">
        <div class="main-content">
          <slot></slot>
        </div>
      </main>
    `;    
  }
}