// Foundation
import { LitElement, customElement, html, css, property } from 'lit-element';

//components
import '@vaadin/vaadin-text-field/vaadin-text-area';

@customElement('backpackemr-text-area')
export class CustomTextArea extends LitElement {

    static get styles() {
        return css`
            .container {
                display: grid;
                height: 100%;
            }

            @media (max-width: 768px) {

            }
        `
        ;
    }

    @property()
    value:string;
    
    @property({type: Boolean, attribute: true})
    disabled:boolean = false;
    
    @property()
    label:string = '';

    @property()
    placeholder:string = '';

    @property({type: Number, attribute: true})
    itemIndex:number

    render() {
        return html`
            <div class="container">
                <vaadin-text-area
                    label="${this.label}"
                    value="${this.value || ''}"
                    placeholder="${this.placeholder}"
                    ?readonly="${this.disabled}"
                    @change=${this.textChanged}
                    >
                </vaadin-text-area>
            </div>
        `;
    }

    textChanged(event) {
        if(event?.target){
            this.dispatchEvent(new CustomEvent('text-changed', {
                detail: {
                    value: event.target.value,
                    itemIndex: this.itemIndex
                },
                bubbles: true,
                composed: true 
            }));
        }
    }
}