export const referralPlans:Array<string> = [
  'referralPlanReferral',
  'referralPlanReturnVisit',
]

export const referralTimeframes:Array<string> = [
  'referralTimeframe2days',
  'referralTimeframe1week',
  'referralTimeframe2weeks',
  'referralTimeframe1month',
]

export const diagnosesList:Array<string> = [
'Abdominal Pain',
'Allergic Rhinitis',
'Anemia',
'Arthritis',
'Asthma',
'Back Pain',
'Bacterial Vaginosis',
'Body Aches/Pain',
'Bronchitis',
'Cataract',
'Cellulitis',
'Cerumen Impaction',
'Chest Pain',
'Conjunctivitis',
'Constipation',
'Cough',
'Dehydration',
'Dental Abscess/Infection',
'Depression',
'Dermatitis',
'Diabetes',
'Diarrhea',
'Dry eyes',
'Eczema',
'Elephantiasis',
'Epilepsy',
'Fever',
'Fracture',
'Gastritis/Acid Reflux/Heartburn',
'Goiter',
'Gonorrhea/Chlamydia',
'Headache',
'Heart Palpitations',
'Hernia',
'HIV',
'Hypertension',
'Impetigo',
'Insomnia',
'Intestinal Parasites',
'Knee Pain',
'Laceration',
'Malaria',
'Malnutrition',
'Otitis Externa',
'Otitis Media',
'Pneumonia',
'Peptic Ulcer',
'Pregnancy',
'Pterygium',
'Shingles',
'Sickle Cell Anemia',
'Sinusitis',
'STD',
'Syphyllis',
'Thrush',
'Tinea Coporis',
'Tinea Cruris',
'Tinea Pedis',
'Typhoid Fever',
'Urinary Tract Infection',
'Uterine Prolapse',
'Vaginal Mycosis/Yeast Infection',
'Waist Pain',
'Wound'
]

export const treatmentTypes:Array<string> = [
'Medication',
'inOffice',
'followUp',
]

export const clinicTreatmentsList:Array<string> = [
'Dental Candidate',
'Occupational Therapy',
'Pastoral Counseling',
'Prayer',
'Surgical Candidate - Cataract',
'Surgical Candidate - Dental',
'Surgical Candidate - Gyn',
'Surgical Candidate - Hernia',
'Surgical Candidate - Infection',
'Surgical Candidate - Ortho',
'Wound Care',
]

export const drugsList:Array<string> = [
'Allergy/Asthma: Albuterol Solution mg',
"Allergy/Asthma: Children's Chewable Claritin 5mg (7)",
"Allergy/Asthma: Children's Liquid Zrytec 1mg/mLmg (7)",
'Allergy/Asthma: Claritin/Zyrtec 10mg (20)',
'Allergy/Asthma: Cough Drops (10)',
'Allergy/Asthma: Prednisone 10mg (Variable)',
'Allergy/Asthma: Salbutamol (Albuterol Inhaler) mg (Variable)',
'Analgesic: Acetaminophen 325mg (30)',
'Analgesic: Acetaminophen (Paracetamol) 500mg (30)',
'Analgesic: Acetaminophen Chewable Tylenol 160mg (12)',
'Analgesic: Acetaminophen Chewable Tylenol 80mg (30)',
'Analgesic: Acetaminophen Liquid 5 mLmg',
'Analgesic: Ibuprofen 200mg (30)',
'Analgesic: Naproxen 220mg (28)',
'Analgesic: Siklobenzaprin (Flexeril) 10mg (9)',
'Analgesic: Tramadol 50mg (15)',
'Anti-Hypertensives: Hydrochlorothiazide 25mg (30)',
'Anti-Hypertensives: Lisinopril 10mg (180)',
'Antibiotic/Antifungal/Protozoal: Flucanozole - Tinea A (6) 150mg',
'Antibiotic/Antifungal/Protozoal: Flucanozole - Tinea C (3-6) 150mg',
'Antibiotic/Antifungal/Protozoal: Azithromycin 250mg (6) * This dosing needs to be prepared by pharmacist',
'Antibiotic/Antifungal/Protozoal: Albendazole 400mg ()',
'Antibiotic/Antifungal/Protozoal: Amoxicillin 250mg (21)',
'Antibiotic/Antifungal/Protozoal: Amoxicillin 500mg (30)',
'Antibiotic/Antifungal/Protozoal: Amoxicillin Chewable 250mg (21)',
'Antibiotic/Antifungal/Protozoal: Amoxicillin Chewable 500mg (30)',
'Antibiotic/Antifungal/Protozoal: Clindamycin 300mg (20)',
'Antibiotic/Antifungal/Protozoal: Co-trimoxazole (Bactrim) 400/80mg (6)',
'Antibiotic/Antifungal/Protozoal: Doxycycline Hyclate 100mg (20)',
'Antibiotic/Antifungal/Protozoal: Flucanozole - Yeast(1) 150mg',
'Antibiotic/Antifungal/Protozoal: Mebendazole 100mg (5)',
'Antibiotic/Antifungal/Protozoal: Metronidazole 250mg (8)',
'GI: Lopermide 2mg (21)',
'GI: Ranitidine 150mg (60)',
'GI: TUMS 500mg (10)',
'GI: TUMS 500mg (20)',
'Glycemics: Metformin 500mg (360)',
'Topicals: Biofreeze (5)',
'Topicals: Clotrimazole 20mg (1 tube)',
'Topicals: Gold Bond (1 Bottle)',
'Topicals: Hydrocortisone Cream 1% Foil Pack (10)',
'Topicals: Permethrin Cream (1% 100cc Cream Rinse) 30mg (1 Bottle)',
'Topicals: Triple Antbiotic Foil Pack (10)',
'Vitamins: Adult Vitamins w/ Iron (90)',
"Vitamins: Children's Chewable Vitamins (90)",
'Vitamins: Polyvisol for Infants (1)',
'None',
]