// Foundation
import { LitElement, customElement, html, property, css, internalProperty } from 'lit-element';
import { query } from 'lit-element/lib/decorators.js';
// Themes
import { themePrimaryBlue, themeLightGreyBorders, themeSecondaryBlue, themeRed } from '../../styles/themeColors';

// Models
import { COMPLAINTS, HISTORY, VITALS, LABS, VISION, EXAM, PHARMACY, VIRTUALCARE } from '../../models/medicalCareTabs';
import { QUEUECATEGORIES, QUEUESTATUS } from '../../models/queueCategories';

import i18next from 'i18next';

// Components
import './visitStationStatus.ts'
import '@material/mwc-button';
import '@material/mwc-menu';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-icon';
import { Button } from '@material/mwc-button';
import { Menu } from '@material/mwc-menu';

// Utility
import { formatDateTime, getTimeSinceDate, formatGetTimeSinceDate } from '../../utils/dateTimeFormatter';
import CurrentPatient from '../../models/currentPatient';
import { currentTabDetailChangesVar } from '../../api/graphQL';
import { isEmpty } from 'lodash';

const queueCategoryTranslation = {
    primaryCareComplaint: COMPLAINTS.TABNAME,
    primaryCareHistory: HISTORY.TABNAME,
    primaryCareVitals: VITALS.TABNAME,
    primaryCareLabs: LABS.TABNAME,
    primaryCareGlasses: VISION.TABNAME,
    primaryCareExam: EXAM.TABNAME,
    primaryCarePharmacy: PHARMACY.TABNAME,
    primaryCareComplete: 'Discharge',
    primaryCareVirtualCare: VIRTUALCARE.TABNAME
}

@customElement('visit-header-queue-data')
export class VisitHeaderQueueData extends LitElement {

    static get styles() {
        return css`
            .visit-header {
                display: grid;
                align-items: start;
                padding: 16px;
                font-size: 14px;
                grid-gap: 8px;
                background-color: #fff;
                border: 1px solid ${themeLightGreyBorders};
                border-bottom: none;
            }
                
            .visit-type {
                display: grid;
                grid-template-columns: 1fr auto;
                font-size: 18px;
                margin-bottom: 12px;
                align-items: center;
            }
            
            .visit-date-campaign-location-and-queue-data {
                display: grid;
                grid-gap: 10px;
                grid-template-columns: 1fr max-content;
                justify-items: left;
                align-items: center;
                grid-column-gap: 20px;
            }

            .visit-date {
                display: grid;
                grid-template-columns: max-content 1fr;
                align-items: center;
            }
            
            .visit-campaign-location {
                display: grid;
                grid-template-columns: max-content 1fr;
                align-items: center;
                word-wrap: break-word;
            }

            .edit-button {
                --mdc-theme-primary: ${themePrimaryBlue};
                --mdc-theme-on-primary: white;
                --mdc-button-outline-color: ${themePrimaryBlue};
                justify-self: end;
                text-align: right;
            }

            #queue-category-menu > [mwc-list-item]:not([selected]) [slot="graphic"] {
                display: none;
            }

            #queue-status-menu > [mwc-list-item]:not([selected]) [slot="graphic"] {
                display: none;
            }

            mwc-button{
                --mdc-theme-primary: ${themePrimaryBlue};
                --mdc-theme-on-primary: white;
            }

            .queue-data-and-waiting-time {
                display: grid;
                grid-gap: 5px;
                justify-self: end;

            }

            .queue-data {
                --mdc-theme-primary: ${themePrimaryBlue};
                --mdc-theme-on-primary: white;
                display: flex;
                justify-self: end;
                align-items: center;
            }

            .waiting-time {
                display: grid;
                grid-template-columns: max-content 1fr;
                align-items: center;
            }

            .remove-queue {
              color: ${themeRed}
            }

            .queue-selection {
                display: grid;
                grid-template-columns: auto 1fr;
                float: right;
                align-items: center;
            }

             /* tablet */
             @media (max-width: 768px) {
                .visit-header {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-gap: 8px;

                    padding: 16px;
                    font-size: 14px;

                    background-color: #fff;
                    border: 1px solid ${themeLightGreyBorders};
                    border-bottom: none;
                }

                .visit-date-campaign-location {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 8px;
                }

             }

        `
        ;
    }

    @query('#queue-status')
    queueStatusButton: Button

    @query('#queue-categories')
    queueCategoriesButton: Button

    @query('#queue-category-menu')
    queueCategoryMenu: Menu
    
    @query('#queue-status-menu')
    queueStatusMenu: Menu;

    @internalProperty()
    selectedQueueCategory: string;

    @internalProperty()
    selectedQueueStatus: string;

    @internalProperty()
    queueWaitingTimestamp: string;

    // happens after the first render only
    async firstUpdated() {
        if (!this.selectedQueueStatus) {
            this.queueCategoryMenu.anchor = this.queueCategoriesButton;
        } else {
            this.queueStatusMenu.anchor = this.queueStatusButton;
            this.queueCategoryMenu.anchor = this.queueCategoriesButton;
        }
        const navigatedFromQueueList = window.localStorage.getItem('patientQueueTab') !== '';
        if (navigatedFromQueueList) {
            this.selectedQueueStatus = QUEUESTATUS.BEING_SEEN;
            this.storeUpdatedQueueData();
            await CurrentPatient.saveQueuedDetails('queue-data');
            this.storeUpdatedQueueData();
        }
    }

    render() {
        return html`
            ${this.selectedQueueStatus ? 
                html`
                <div class="queue-data-and-waiting-time">
                    <div class="queue-data" style="position:relative">
                    ${this.selectedQueueStatus === QUEUESTATUS.WAITING ? html`
                        <div class="waiting-time" style="margin-right: 7px">
                            <mwc-icon>alarm</mwc-icon>
                            ${this.queueWaitingTimestamp && getTimeSinceDate(new Date(parseInt(this.queueWaitingTimestamp)).toISOString())}
                        </div>
                    ` : html ``}
                        <div class="queue-status-and-time">
                            <div class="queue-status-button" style="margin-right: 7px">
                                <mwc-button
                                    id="queue-status"
                                    label="${this.selectedQueueStatus}"
                                    outlined
                                    icon="arrow_drop_down"
                                    trailingIcon
                                    @click="${() => this.queueStatusMenu.show()}">
                                </mwc-button>
                                <mwc-menu
                                    id="queue-status-menu"
                                    .anchor="${this.queueStatusButton}"
                                    corner="BOTTOM_START"
                                    fixed
                                    @action="${event => this.handleQueueStatusChange(event)}"
                                >
                                    <mwc-list-item
                                        graphic="icon"
                                        ?selected="${this.selectedQueueStatus === QUEUESTATUS.WAITING}">
                                        <mwc-icon slot="graphic">check</mwc-icon>
                                        <span>${i18next.t(QUEUESTATUS.WAITING)}</span>
                                    </mwc-list-item>
                                    <li divider role="separator"></li>
                                    <mwc-list-item
                                        graphic="icon"
                                        ?selected="${this.selectedQueueStatus === QUEUESTATUS.BEING_SEEN}">
                                        <mwc-icon slot="graphic">check</mwc-icon>
                                        <span>${i18next.t(QUEUESTATUS.BEING_SEEN)}</span>
                                    </mwc-list-item>
                                </mwc-menu>
                            </div>
                        </div>

                        ${i18next.t('in')}

                        ${this.getPrimaryCareQueueCategories()}
                    </div>
                </div>
            ` : html`
                    <div class="queue-selection">
                        ${i18next.t('queue')}

                        ${this.getPrimaryCareQueueCategories()}
                    </div>
                `}
                    
        `;
    }

    handleQueueStatusChange(event) {
        if (event.detail.index === 0) {
            this.selectedQueueStatus = QUEUESTATUS.WAITING
        } else {
            this.selectedQueueStatus = QUEUESTATUS.BEING_SEEN
        }
        this.storeUpdatedQueueData();
    }

    handleQueueCategoryChange(event) {
        // whenever queue category changes, the status is default to waiting.
        this.selectedQueueStatus = QUEUESTATUS.WAITING;

        switch(event.detail.index) {
            case 0:
                this.selectedQueueCategory = QUEUECATEGORIES.COMPLAINTS;
                break;
            case 1:
                this.selectedQueueCategory = QUEUECATEGORIES.HISTORY;
                break;
            case 2:
                this.selectedQueueCategory = QUEUECATEGORIES.VITALS;
                break;
            case 3:
                this.selectedQueueCategory = QUEUECATEGORIES.LABS;
                break;
            case 4:
                this.selectedQueueCategory = QUEUECATEGORIES.VISION;
                break;
            case 5:
                this.selectedQueueCategory = QUEUECATEGORIES.EXAM;
                break;
            case 6:
                this.selectedQueueCategory = QUEUECATEGORIES.PHARMACY;
                break;
            case 7:
                this.selectedQueueCategory = QUEUECATEGORIES.DISCHARGE;
                break;
            case 8:
                this.selectedQueueCategory = QUEUECATEGORIES.VIRTUALCARE;
                break;
            case 9:
                this.selectedQueueCategory = '';
                this.selectedQueueStatus = '';
                break;
            default:
                this.selectedQueueCategory = '';
                break;
        }
        this.storeUpdatedQueueData();
    }

    async storeUpdatedQueueData() {
        this.dispatchEvent(new CustomEvent('queue-status-and-category-update', {
            detail: {
                selectedQueueStatus: this.selectedQueueStatus,
                selectedQueueCategory: this.selectedQueueCategory, 
                timestamp: new Date().getTime().toString(),
            },
            bubbles: true,
            composed: true
        }))
    }

    getPrimaryCareQueueCategories() {
        return html`
            <div class="queue-category-button" style="margin-left: 7px">
                <mwc-button
                    id="queue-categories"
                    label="${this.selectedQueueCategory ? queueCategoryTranslation[this.selectedQueueCategory] : i18next.t('none')}"
                    outlined
                    icon="arrow_drop_down"
                    trailingIcon
                    @click="${() => this.queueCategoryMenu.show()}">
                </mwc-button>
                <mwc-menu
                    id="queue-category-menu"
                    corner="BOTTOM_START"
                    .anchor="${this.queueCategoriesButton}"
                    fixed
                    @action="${event => this.handleQueueCategoryChange(event)}"
                >
                    <mwc-list-item
                        graphic="icon"
                        ?selected="${this.selectedQueueCategory === QUEUECATEGORIES.COMPLAINTS}">
                        <mwc-icon slot="graphic">check</mwc-icon>
                        <span>${i18next.t('complaints')}</span>
                    </mwc-list-item>

                    <mwc-list-item
                        graphic="icon"
                        ?selected="${this.selectedQueueCategory === QUEUECATEGORIES.HISTORY}">
                        <mwc-icon slot="graphic">check</mwc-icon>
                        <span>${i18next.t('history')}</span>
                    </mwc-list-item>

                    <mwc-list-item
                        graphic="icon"
                        ?selected="${this.selectedQueueCategory === QUEUECATEGORIES.VITALS}">
                        <mwc-icon slot="graphic">check</mwc-icon>
                        <span>${i18next.t('vitals')}</span>
                    </mwc-list-item>

                    <mwc-list-item
                        graphic="icon"
                        ?selected="${this.selectedQueueCategory === QUEUECATEGORIES.LABS}">
                        <mwc-icon slot="graphic">check</mwc-icon>
                        <span>${i18next.t('labs')}</span>
                    </mwc-list-item>

                    <mwc-list-item
                        graphic="icon"
                        ?selected="${this.selectedQueueCategory === QUEUECATEGORIES.VISION}">
                        <mwc-icon slot="graphic">check</mwc-icon>
                        <span>${i18next.t('vision')}</span>
                    </mwc-list-item>

                    <mwc-list-item
                        graphic="icon"
                        ?selected="${this.selectedQueueCategory === QUEUECATEGORIES.EXAM}">
                        <mwc-icon slot="graphic">check</mwc-icon>
                        <span>${i18next.t('exam')}</span>
                    </mwc-list-item>

                    <mwc-list-item
                        graphic="icon"
                        ?selected="${this.selectedQueueCategory === QUEUECATEGORIES.PHARMACY}">
                        <mwc-icon slot="graphic">check</mwc-icon>
                        <span>${i18next.t('pharmacy')}</span>
                    </mwc-list-item>

                    <mwc-list-item
                        graphic="icon"
                        ?selected=${this.selectedQueueCategory === QUEUECATEGORIES.DISCHARGE}>
                        <mwc-icon slot="graphic">check</mwc-icon>
                        <span>${i18next.t('discharge')}</span>
                    </mwc-list-item>

                    <mwc-list-item
                        graphic="icon"
                        ?selected="${this.selectedQueueCategory === QUEUECATEGORIES.VIRTUALCARE}">
                        <mwc-icon slot="graphic">check</mwc-icon>
                        <span>${i18next.t('virtualCare')}</span>
                    </mwc-list-item>

                    ${this.selectedQueueStatus ? html`
                      <li divider role="separator"></li>
                      <mwc-list-item
                          class="remove-queue"
                          graphic="icon"
                          ?selected="${true}">
                          <mwc-icon slot="graphic" class="remove-queue">close</mwc-icon>
                          <span>${i18next.t('removeQueue')}</span>
                      </mwc-list-item>
                    ` : html``}
                </mwc-menu>
            </div>
        `
    }


}