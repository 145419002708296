import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

import { Treatment } from '../../../../models/clinical/exam';

// Translations

import i18next from 'i18next';
import { generateTranslatedList } from '../../../../I18n/i18nUtil';
import { referralPlans, referralTimeframes, clinicTreatmentsList, drugsList } from '../../../../constants/primaryCare-exam';
import { campaignCustomLists } from '../../../../api/graphQL';

@customElement('exam-treatments')
export class ExamTreatments extends LitElement {
  static get styles() {
    return css`
      .content {
        display: grid;
      }
      .rowOneColumn {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 16px;
        padding-bottom: 16px;
        /* overflow: scroll; */
      }

      .rowTwoColumn {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 16px;
          align-items: start;
          /* padding-bottom: 16px; */
          /* overflow: scroll; */
      }
      
      @media (max-width: 768px) {
          .rowOneColumn {
              display: grid;
              grid-template-columns: 1fr;
          }
          .rowTwoColumn {
              display: grid;
              grid-template-columns: 1fr;
          }

      }
      
      `
  }

  @property()
  treatmentType:string = '';

  @property({attribute: false})
  treatmentDetails:Treatment;

  @property({attribute: false})
  readOnlyMode:boolean = true;

  @property({type: Number, attribute:false})
  itemIndex:number = 0;

  @property({type: Number, attribute:false})
  diagnosisIndex:number = 0;

  render() {
    console.log('examTreatmens.ts ~ render ~ detail', this.treatmentDetails);

    if (this.treatmentType === 'Medication') {
      return html`
        <div class="content">
          <div class="rowTwoColumn">
              <backpackemr-select-two
                  label="${i18next.t('drug').toString()}"
                  .items="${campaignCustomLists().primaryCareDrugs ?? generateTranslatedList(drugsList)}"
                  ?disabled="${this.readOnlyMode}"
                  .value="${this.treatmentDetails.drug}"
                  @value-changed="${(event:CustomEvent) => {
                      this.treatmentDetails.drug = event.detail.key; 
                      this._handleMedicationInfoUpdate();
                    }}">
              </backpackemr-select-two>
              <backpackemr-text-field
                    label="${i18next.t('dispense').toString()}"
                    placeholder="e.g. 1 package, 40 pills"
                    .value="${this.treatmentDetails.dispense || ''}"
                    ?disabled="${this.readOnlyMode}"
                    @text-field-changed="${(event:CustomEvent) => {
                        this.treatmentDetails.dispense = event.detail.value; 
                        this._handleMedicationInfoUpdate()}}">
              </backpackemr-text-field>
          </div>
          <div class="rowOneColumn">
              <backpackemr-text-area
                  label="${i18next.t('dose').toString()}"
                  placeholder="e.g. Take 2 pills with food, as needed, for 20 days"
                  ?disabled="${this.readOnlyMode}"
                  .value="${this.treatmentDetails?.dosage || ''}"
                  @text-changed="${(event:CustomEvent) => {
                        this.treatmentDetails.dosage = event.detail.value;
                        this._handleMedicationInfoUpdate()}}">
              </backpackemr-text-area>
          </div>
        </div>
      `;
  } 
  else if (this.treatmentType === 'Other') { //In-clinic
      return html`
          <div class="rowTwoColumn">
              <backpackemr-select-two
                  label="${i18next.t('inClinicTreatment').toString()}"
                  .items="${campaignCustomLists().primaryCareTreatments || generateTranslatedList(clinicTreatmentsList)}"
                  ?disabled="${this.readOnlyMode}"
                  .value="${this.treatmentDetails.inClinicTreatment}"
                  @value-changed="${(event:CustomEvent) => {
                      this.treatmentDetails.inClinicTreatment = event.detail.key; 
                      this._handleOtherInfoUpdate()}}">
              </backpackemr-select-two>
              <backpackemr-text-area
                label="${i18next.t('notes').toString()}"
                .value="${this.treatmentDetails.inClinicTreatmentNotes || ''}"
                ?disabled="${this.readOnlyMode}"
                @text-changed="${(event:CustomEvent) => {
                    this.treatmentDetails.inClinicTreatmentNotes = event.detail.value; 
                    this._handleOtherInfoUpdate()
                }}">
            </backpackemr-text-area>
          </div>
      `;
  }
  else if (this.treatmentType === 'followUp') {
      return html`
          <div class="rowTwoColumn">
              <backpackemr-select-two
                  label="${i18next.t('referralPlan').toString()}"
                  .items="${generateTranslatedList(referralPlans)}"
                  ?disabled="${this.readOnlyMode}"
                  .value="${this.treatmentDetails.referralPlan}"
                  @value-changed=${(event:CustomEvent) => {
                      this.treatmentDetails.referralPlan = event.detail.key; 
                      this._handleFollowUpUpdate();
                    }}>
              </backpackemr-select-two>
              <backpackemr-text-area
                label="${i18next.t('referralDetails').toString()}"
                .value="${this.treatmentDetails.referralDetails || ''}"
                ?disabled="${this.readOnlyMode}"
                @text-changed="${(event:CustomEvent) => {
                    this.treatmentDetails.referralDetails = event.detail.value;
                    this._handleFollowUpUpdate()
                }}">
            </backpackemr-text-area>
          </div>
          <div class="rowTwoColumn">
              <backpackemr-text-field
                  label="${i18next.t('referralLocation').toString()}"
                  .value="${this.treatmentDetails.referralLocation || ''}"
                  ?disabled="${this.readOnlyMode}"
                  @text-field-changed="${(event:CustomEvent) => {
                      this.treatmentDetails.referralLocation = event.detail.value; 
                      this._handleFollowUpUpdate()}}">
              </backpackemr-text-field>
              <backpackemr-select-two
                  label="${i18next.t('referralTimeframe').toString()}"
                  .items="${generateTranslatedList(referralTimeframes)}"
                  ?disabled="${this.readOnlyMode}"
                  .value="${this.treatmentDetails.referralTimeframe}"
                  @value-changed=${(event:CustomEvent) => {
                      this.treatmentDetails.referralTimeframe = event.detail.key; 
                      this._handleFollowUpUpdate()
                    }}>
              </backpackemr-select-two>
          </div>
      `;
  } 

  return html`
  `;
  }

  _handleMedicationInfoUpdate() {
    //   console.log('examTreatments.ts ~ _handleMeedicationInfoUpdat() ~ details:', this.treatmentDetails);

    const medicationInfo:Treatment = {
      treatmentType: this.treatmentType,
      dispense: this.treatmentDetails.dispense,
      dosage: this.treatmentDetails.dosage,
      drug: this.treatmentDetails.drug,
      inClinicTreatment: null,
      inClinicTreatmentNotes: null,
      pharmacyCounted: this.treatmentDetails?.pharmacyCounted,
      pharmacyCountedUserDate: this.treatmentDetails?.pharmacyCountedUserDate,
      pharmacyFilled: this.treatmentDetails?.pharmacyFilled,
      pharmacyFilledUserDate: this.treatmentDetails?.pharmacyFilledUserDate,
      referralDetails: null,
      referralLocation: null,
      referralPlan: null,
      referralTimeframe: null
  }
  
    this._dispatchTreatmentInfoUpdate(medicationInfo);
  }

  _handleOtherInfoUpdate() {
    const otherInfo:Treatment = {
      treatmentType: this.treatmentType,
      dispense: null,
      dosage: null,
      drug: null,
      inClinicTreatment: this.treatmentDetails.inClinicTreatment,
      inClinicTreatmentNotes: this.treatmentDetails.inClinicTreatmentNotes,
      pharmacyCounted: this.treatmentDetails?.pharmacyCounted,
      pharmacyCountedUserDate: this.treatmentDetails?.pharmacyCountedUserDate,
      pharmacyFilled: this.treatmentDetails?.pharmacyFilled,
      pharmacyFilledUserDate: this.treatmentDetails?.pharmacyFilledUserDate,
      referralDetails: null,
      referralLocation: null,
      referralPlan: null,
      referralTimeframe: null
  }
    this._dispatchTreatmentInfoUpdate(otherInfo);
  }

  _handleFollowUpUpdate() {
    const followUpInfo:Treatment = {
      treatmentType: this.treatmentType,
      dispense: null,
      dosage: null,
      drug: null,
      inClinicTreatment: null,
      inClinicTreatmentNotes: null,
      pharmacyCounted: this.treatmentDetails?.pharmacyCounted,
      pharmacyCountedUserDate: this.treatmentDetails?.pharmacyCountedUserDate,
      pharmacyFilled: this.treatmentDetails?.pharmacyFilled,
      pharmacyFilledUserDate: this.treatmentDetails?.pharmacyFilledUserDate,
      referralPlan: this.treatmentDetails.referralPlan,
      referralDetails: this.treatmentDetails.referralDetails,
      referralLocation: this.treatmentDetails.referralLocation,
      referralTimeframe: this.treatmentDetails.referralTimeframe
  }
    this._dispatchTreatmentInfoUpdate(followUpInfo)
  }

  _dispatchTreatmentInfoUpdate(updatedDetails:Treatment) {
    this.dispatchEvent(new CustomEvent('treatment-info-updated', {
      detail: {
        key: this.treatmentType,
        treatmentIndex: this.itemIndex,
        diagnosisIndex: this.diagnosisIndex,
        updatedTreatmentDetails: updatedDetails
      }
    }))
  }
}