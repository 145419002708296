import { currentTabDetailChangesVar, currentPatientProfileDataChangesVar, currentPatientQueueDataChangesVar } from '../api/graphQL';
import { isEmpty } from 'lodash';

import { client } from '../api/graphQL';
import { QUERY_PATIENT_CHART } from '../api/queries'
import { CREATE_EXAM_DETAILS, UPDATE_EXAM_DETAILS,
    createVirtualCareDetails, updateVirtualCareDetails, 
    createHistoryDetails, updateHistoryDetails, 
    createComplaintDetails, updateComplaintDetails,
    createVisionDetails, updateVisionDetails,
    createLabsDetails, updateLabsDetails,
    createPublicHealthDetails, updatePublicHealthDetails,
    createVitalsDetails, updateVitalsDetails,
    createNewVisit, updatePatientVisit, updatePatientQueueData
} from '../api/mutations';
import { updatePatientProfile } from '../api/patientProfileMutations';

import { stripTypenames } from '../utils/graphqlHelper';

import { PUBLICHEALTH, COMPLAINTS, HISTORY, VITALS, LABS, VISION, EXAM, PHARMACY, VIRTUALCARE} from '../models/medicalCareTabs';

class CurrentPatient {

    public async fetchPatientFromServer(variables: any): Promise<any> {
        try {
            const request = await client.query({
                query: QUERY_PATIENT_CHART,
                variables: variables,
                fetchPolicy: 'network-only' //we always want the latest data in case it changes from anothere device
            });

            
            //todo error handling. need to be able to inform the UI request.error
            const response = request.data.patient;
            
            this.clearListOfPendingChanges();
            return response;
        }
        catch(error) {
            console.error('ApolloElement - mutation error:', error.networkError.result.errors);
            console.error('currentPatient ~ fetchPatientFromServer ~ error:', error);
        }
    }

    public updateDetailsInLocalState(variables: any, type: any) {
        if (type === 'tab-update') currentTabDetailChangesVar(variables);
        else if (type === 'profile-update') currentPatientProfileDataChangesVar(variables);
        else if (type === 'queue-data-update') currentPatientQueueDataChangesVar(variables);
    }

    public async saveQueuedDetails(type: string): Promise<any> {     
        if (type === 'tab-details') {
            if(isEmpty(currentTabDetailChangesVar())) return;

            try {
                const result = await this.saveTabDetailsToServer(currentTabDetailChangesVar());
                this.clearListOfPendingChanges();
                return result;
            }
            catch(error) {
                console.error("🚀 ~ file: currentPatient.ts ~ line 125 ~ CurrentPatient ~ saveAnyPendingMedicalTabs ~ error", error);
            }
        } else if (type === 'patient-profile-details') {
            if (isEmpty(currentPatientProfileDataChangesVar())) return;

            try {
                const result = await this.savePatientProfileDetailsToServer(currentPatientProfileDataChangesVar());
                this.clearListOfPendingChanges();
                return result;
            }
            catch(error) {
                console.log('FAILED TO SAVE PATIENT PROFILE DATA', error);
            }
        } else if (type === 'queue-data') {
            if (isEmpty(currentPatientQueueDataChangesVar())) return; 
            try {
                const result = await this.savePatientQueueDataToServer(currentPatientQueueDataChangesVar())
                currentPatientQueueDataChangesVar({});
                return result;
            } catch (error) {
                console.log('FAILED TO UPDATE PATIENT QUEUE DATA: ', error);
            }
        }
        
    }
    
    public async createNewVisit(variables:any): Promise<any> {
        try {
            return await this.updateDocument(createNewVisit, variables);
        } 
        catch(error) {
            console.error("🚀 ~ file: currentPatient.ts ~ line 64 ~ CurrentPatient ~ createNewVisit ~ error", error);
        }
    }

    public async updatePatientVisit(variables:any): Promise<any> {
        try {
            return await this.updateDocument(updatePatientVisit, variables);
        } 
        catch(error) {
            console.error("🚀 ~ file: currentPatient.ts ~ line 72 ~ CurrentPatient ~ updatePatientVisit ~ error", error);
        }
    }

    private clearListOfPendingChanges() {
        currentTabDetailChangesVar({});
        currentPatientProfileDataChangesVar({});
    }

    private async savePatientQueueDataToServer(currentPatientQueueData: any) {
        console.log('QUEUED PATIENT QUEUE DATA TO BE SAVED: ', currentPatientQueueData);
        try {
            const result = await this.updateDocument(updatePatientQueueData, currentPatientQueueData);
            return result;
        } catch (error) {
            console.log('FAILED TO SAVE QUEUE DATA');
            return error;
        }
    }

    private async savePatientProfileDetailsToServer(currentPatientProfileDetails: any): Promise<any> {
        console.log('QUEUED PATIENT PROFILE DATA TO BE SAVED: ', currentPatientProfileDetails);
        try {
            const result = await this.updateDocument(updatePatientProfile, currentPatientProfileDetails);

            return result;
        } catch (error) {
            console.log('FAILED TO SAVE PATIENT PROFILE');
            return error;
        }
    }

    // new medical tab docs do not have an id assigned to them yet. No id --> create, id --> update
    private async saveTabDetailsToServer(currentTabDetails: any): Promise<any> {
        console.log('QUEUED CHANGES TO BE SAVED: ', currentTabDetails);
        try {
            if (currentTabDetails?.id) {
                const mutationQuery = this.selectMutationQuery(currentTabDetails.tab).updateMutation;            
                
                const result = await this.updateDocument(mutationQuery, {
                    id: currentTabDetails.id,
                    input: currentTabDetails.tabDetails,
                });

                return result;

            } else {
                const mutationQuery = this.selectMutationQuery(currentTabDetails.tab).createMutation;
                
                const result = await this.updateDocument(mutationQuery, {
                    visitID: currentTabDetails.visitID,
                    input: currentTabDetails.tabDetails,
                });
                
                return result;
            }
        }
        catch(error) {
            console.error("🚀 ~ file: currentPatient.ts ~ line 175 ~ CurrentPatient ~ saveTabDetailsToServer ~ error", error)
        }
    }

    private selectMutationQuery(tab:string) {
        // the name is used to grab the refreshed data from the backend after the modification was saved to the database. 
        // it is returned from the backend with the query name as a property

        switch(tab) {
            case HISTORY.TABNAME:
                return {
                    createMutation: createHistoryDetails,
                    createMutationName: HISTORY.CREATE_MUTATION_NAME,
                    updateMutation: updateHistoryDetails, 
                    updateMutationName: HISTORY.UPDATE_MUTATION_NAME, 
                    detailCategory: HISTORY.VISIT_MEDICAL_DETAILS_CATEGORY
                };
            case COMPLAINTS.TABNAME:
                return {
                    createMutation: createComplaintDetails,
                    createMutationName: COMPLAINTS.CREATE_MUTATION_NAME,
                    updateMutation: updateComplaintDetails, 
                    updateMutationName: COMPLAINTS.UPDATE_MUTATION_NAME, 
                    detailCategory: COMPLAINTS.VISIT_MEDICAL_DETAILS_CATEGORY
                }; 
            case VIRTUALCARE.TABNAME:
                return {
                    createMutation: createVirtualCareDetails,
                    createMutationName: VIRTUALCARE.CREATE_MUTATION_NAME,
                    updateMutation: updateVirtualCareDetails, 
                    updateMutationName: VIRTUALCARE.UPDATE_MUTATION_NAME, 
                    detailCategory: VIRTUALCARE.VISIT_MEDICAL_DETAILS_CATEGORY
                };
            case VISION.TABNAME:
                return {
                    createMutation: createVisionDetails,
                    createMutationName: VISION.CREATE_MUTATION_NAME,
                    updateMutation: updateVisionDetails,
                    updateMutationName: VISION.UPDATE_MUTATION_NAME,
                    detailCategory: VISION.VISIT_MEDICAL_DETAILS_CATEGORY
                };
            case LABS.TABNAME:
                return {
                    createMutation: createLabsDetails,
                    createMutationName: LABS.CREATE_MUTATION_NAME,
                    updateMutation: updateLabsDetails,
                    updateMutationName: LABS.UPDATE_MUTATION_NAME,
                    detailCategory: LABS.VISIT_MEDICAL_DETAILS_CATEGORY
                };
            case PUBLICHEALTH.TABNAME:
                return {
                    createMutation: createPublicHealthDetails,
                    createMutationName: PUBLICHEALTH.CREATE_MUTATION_NAME,
                    updateMutation: updatePublicHealthDetails,
                    updateMutationName: PUBLICHEALTH.UPDATE_MUTATION_NAME,
                    detailCategory: PUBLICHEALTH.VISIT_MEDICAL_DETAILS_CATEGORY
                };
            case VITALS.TABNAME:
                return {
                    createMutation: createVitalsDetails,
                    createMutationName: VITALS.CREATE_MUTATION_NAME,
                    updateMutation: updateVitalsDetails,
                    updateMutationName: VITALS.UPDATE_MUTATION_NAME,
                    detailCategory: VITALS.VISIT_MEDICAL_DETAILS_CATEGORY
                };
            case EXAM.TABNAME:
                return {
                    createMutation: CREATE_EXAM_DETAILS,
                    createMutationName: EXAM.CREATE_MUTATION_NAME,
                    updateMutation: UPDATE_EXAM_DETAILS,
                    updateMutationName: EXAM.UPDATE_MUTATION_NAME,
                    detailCategory: EXAM.VISIT_MEDICAL_DETAILS_CATEGORY
                };
            case PHARMACY.TABNAME:
                return {
                    // updateMutation: updateExamDetails,
                    updateMutationName: PHARMACY.UPDATE_MUTATION_NAME,
                    detailCategory: PHARMACY.VISIT_MEDICAL_DETAILS_CATEGORY
                }
        }
    }

    private async updateDocument(mutation: any, variables: any): Promise<any> {
        const variablesStrippedOfTypename = stripTypenames(variables, "__typename");

        try {
            const result = await client.mutate({
                mutation,
                variables: variablesStrippedOfTypename,
            });
            return result.data;
        } catch (error) {
            console.error("currentPatient.ts ~ updateDocument", error);
            console.error("currentPatient.ts ~ updateDocument ~ networkError:", error.networkError.result.errors);
            // return error;
        }
    }

}

export default new CurrentPatient();