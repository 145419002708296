// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

// Themes
import { themePrimaryBlue, themeSecondaryBlue } from '../../../styles/themeColors';

// Components
import './pharmacyItems/medicationCard.ts'
import './pharmacyItems/followUpCard.ts'
import './pharmacyItems/inClinicCard.ts'
import '../../common/customSelect.ts';
import '../medicalTabHeader.ts';

//translations
import i18next from 'i18next';

// Models
import { ExamDetails } from '../../../models/clinical/exam';
// import { treatments } from '../../../constants/primaryCare-vision';

@customElement('tab-pharmacy')
export class TabPharmacy extends LitElement {

    static get styles() {
        return css`
            .content {
                display: grid;
            }

            h5 {
                margin: 24px 0 0 0;
                font-size: 16px;
            }
            
            .row {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 16px;
                padding-bottom: 16px;
                /* overflow: scroll; */
            }

            .diagnosis-section {
                margin-bottom: 24px;
            }

            .treatment-section {
                margin-bottom: 24px 0;
            }

            .treatments {
                padding-left: 20px;
                margin-left: 20px;
                border-left: 2px solid ${themeSecondaryBlue};
            }

            .rowOneColumn {
                display: grid;
                grid-template-columns: 1fr;
                padding-bottom: 16px;
            }

            @media (max-width: 768px) {
                /* TODO: make 1 column.*/
                .row {
                    display: grid;
                    grid-template-columns: 1fr;
                }
            }
        `
        ;
    }

    @property({attribute: false})
    details: ExamDetails;

    @internalProperty()
    readOnlyMode:boolean = true;

    @property({attribute: false})
    loggedInUser:string;

    @internalProperty()
    medicationTreatments:any = [];

    @internalProperty()
    followUpTreatments:any = [];

    @internalProperty()
    inClinicTreatments:any = [];

    firstUpdated() {
        this.addEventListener('treatment-info-updated', this._handleTreatmentInfoUpdated);
        this.refreshTreatments();
    }

    render() {      
        if (!this.details) {
            return html`
                <h3>${i18next.t('pharmacy')}</h3>
                <p>${i18next.t('noPharmacyData')}</p>
            `;
        }
        
        this.refreshTreatments();

        return html`
            <div class="content">
                <medical-tab-header
                    tabName="${i18next.t('pharmacy').toString() || ''}"
                    .updatedDateTime="${this.details?.updatedAt}"
                    ?readOnlyMode="${this.readOnlyMode}"
                    @edit-content="${this._handleEditContent}">
                </medical-tab-header>

                <h5>${i18next.t('medications')}</h5>
                ${this.medicationTreatments.map( treatment => {
                    return html`
                        <medication-card
                            .treatment="${treatment.treatment}"
                            examDoctor="${treatment.examDoctor}"
                            ?readOnly="${this.readOnlyMode}"
                            treatmentIndex="${treatment.treatmentIndex}"
                            diagnosisIndex="${treatment.diagnosisIndex}"
                            loggedinUser="${this.loggedInUser}">
                        </medication-card>
                    `;
                })}


                <h5>${i18next.t('followups')}</h5>
                ${this.followUpTreatments.map( treatment => {
                    return html`
                        <followup-card
                            .treatment="${treatment.treatment}"
                            examDoctor="${treatment.examDoctor}"
                            ?readOnly="${this.readOnlyMode}"
                            treatmentIndex="${treatment.treatmentIndex}"
                            diagnosisIndex="${treatment.diagnosisIndex}"
                            loggedinUser="${this.loggedInUser}">
                        </followup-card>
                    `
                })}


                <h5>${i18next.t('inOffice')} ${i18next.t('treatment')}</h5>
                ${this.inClinicTreatments.map( treatment => {
                    return html`
                        <in-clinic-card
                            .treatment="${treatment.treatment}"
                            examDoctor="${treatment.examDoctor}"
                            ?readOnly="${this.readOnlyMode}"
                            treatmentIndex="${treatment.treatmentIndex}"
                            diagnosisIndex="${treatment.diagnosisIndex}"
                            loggedinUser="${this.loggedInUser}">
                        </in-clinic-card>
                    `
                })}
            </div>
        `;
    }

    _handleEditContent(){
        this.readOnlyMode = !this.readOnlyMode;
    }

    _handleTreatmentInfoUpdated(event){
        this.details.diagnoses[event.detail.diagnosisIndex].treatments[event.detail.treatmentIndex] = event.detail.updatedTreatmentInfo;
        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    getUpdateMutationVariables() {
        const updatedExamInfo:ExamDetails = {
            _id: this.details?._id,
            _rev: this.details?._rev,
            campaignId: this.details.campaignId,
            patientId: this.details.patientId,
            channels: this.details.channels,
            updatedAt: new Date().toISOString(),
            type: this.details.type,
            examDoctor: this.details.examDoctor,
            examNotes: this.details.examNotes,
            photos: null,
            diagnoses: this.details.diagnoses
        }

        if (this.details?.photos){
            updatedExamInfo.photos = this.details.photos.map(photo => {
                return {
                    fileName: photo.fileName
                }
            });
        }

        return {
            tab: 'EXAM',
            id: this.details?._id,
            // visitID: this.visitID,
            tabDetails: updatedExamInfo
        }
    }

    tabDetailsUpdated(){
        this.dispatchEvent(new CustomEvent('tab-info-updated', {
            detail: {
                variables: this.getUpdateMutationVariables()
            },
            bubbles: true,
            composed: true
        }));
    }

    refreshTreatments() {        
        this.medicationTreatments = [];
        this.inClinicTreatments = [];
        this.followUpTreatments = [];
        
        if (this.details?.diagnoses?.length > 0) {
            for(let i=0; i<this.details?.diagnoses.length; i++) {
                for(let j=0; j<this.details?.diagnoses[i].treatments.length; j++) {
                    if(this.details.diagnoses[i].treatments[j].treatmentType === 'Medication') {
                        this.medicationTreatments.push({
                            treatment: this.details.diagnoses[i].treatments[j],
                            diagnosisIndex: i,
                            treatmentIndex: j,
                            examDoctor: this.details.diagnoses[i].examDoctor
                        })
                    } else if(this.details.diagnoses[i].treatments[j].treatmentType === 'followUp') {
                        this.followUpTreatments.push({
                            treatment: this.details.diagnoses[i].treatments[j],
                            diagnosisIndex: i,
                            treatmentIndex: j,
                            examDoctor: this.details.diagnoses[i].examDoctor
                        })
                    } else if(this.details.diagnoses[i].treatments[j].treatmentType === 'Other') {
                        this.inClinicTreatments.push({
                            treatment: this.details.diagnoses[i].treatments[j],
                            diagnosisIndex: i,
                            treatmentIndex: j,
                            examDoctor: this.details.diagnoses[i].examDoctor
                        })
                    }
                }
            }
            this.requestUpdate();
        }
    }
}