export const eyeRx:Array<string> = [
  'Cataracts',
  'Dry Eyes',
  'Farsighted ',
  'Nearsighted',
  'Sun Protection Needed',
  'Possible Infection- See Provider',
  'Unable To Determine',
]

export const treatments:Array<string> = [
  '+0.05 readers',
  '+0.75 readers',
  '+1.00 readers',
  '+1.25 readers',
  '+1.50 readers',
  '+1.75 readers',
  '+2.00 readers',
  '+2.25 readers',
  '+2.50 readers',
  '+2.75 readers',
  '+3.00 readers',
  '+3.25 readers',
  '+3.50 readers',
  '+3.75 readers',
  '+4.00 readers',
  '+4.25 readers',
  '+4.50 readers',
  '+4.75 readers',
  '+5.00 readers',
  '-0.50',
  '-0.75',
  '-1.00',
  '-1.25',
  '-1.50',
  '-1.75',
  '-2.00',
  '-2.25',
  '-2.50',
  '-2.75',
  '-3.00',
  '-3.25',
  '-3.50',
  '-3.75',
  '-4.00',
  '-4.25',
  '-4.50',
  '-4.75',
  '-5.00',
  'Children’s Glasses',
  'Lubricant Eye Drops',
  'Surgical Candidate - Cataract',
  'Sunglasses'
]