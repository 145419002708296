import { config } from "../config"

class Config {
    private envConfig:any;
    constructor() {
        const isDevEnv = (process.env.NODE_ENV == "development");
        const stagingTargetEnv = (process.env.TARGET_ENV == "staging");
        // console.log('Loading config for', isDevEnv ? "dev" : stagingTargetEnv ? "staging" : "prod");
        if (isDevEnv) {
            this.envConfig = config.dev;
        } else if (stagingTargetEnv) {
            this.envConfig = config.staging;
        } else {
            this.envConfig = config.prod;
        }
    }

    public getConfig(key:string, defaultValue:any = null) {
        if (!key) {
            throw "invalid config key";
        }

        var c = this.envConfig[key];
        if (c == null) {
            return this.getNestedConfig(key, defaultValue);
        }
        return c;
    }

    private getNestedConfig(key:string, defaultValue:any = null) {
        var splitKey = key.split(".");

        var c = this.envConfig[splitKey[0]];
        if (c != null) {
            var cc = c;
            for (var i =1; i < splitKey.length; ++i) {
                cc = cc[splitKey[i]];
            }
            return cc;
        }
        return defaultValue;
    }
}

export default new Config();
