import { LitElement, html, css, property, query, internalProperty, customElement } from 'lit-element';

import '@vaadin/vaadin-checkbox';
import '@vaadin/vaadin-checkbox/vaadin-checkbox-group';

import { generateTranslatedList } from '../../I18n/i18nUtil';

const options:Array<string> = ['yes', 'no'];

@customElement('backpackemr-checkbox-group-yes-no')
export class CustomCheckboxGroupYesNo extends LitElement {
  static get styles() {
    return css`
      .container {
          display: grid;
      }

      @media (max-width: 768px) {

      }
    `
  }

  @property({type: String, attribute: false})
  label:string = '';

  @property({type: Boolean, attribute: true})
  disabled:boolean = false;

  @property({type: Boolean, attribute: true})
  selectedValue: boolean;

  @property({type: Number})
  itemIndex:number = 0

  render() {
    return html`
        <div class="container">
            <vaadin-checkbox-group
            .label="${this.label}"
            .value="${[this.translateSelectedValueFromBoolean(this.selectedValue)]}"
            ?disabled="${this.disabled}"
            @change="${this.handleOptionSelection}">
                ${generateTranslatedList(options).map( option => {
                    return html`
                        <vaadin-checkbox
                            value="${option.key}">
                                ${option.value}
                        </vaadin-checkbox>
                `})}
            </vaadin-checkbox-group>
        </div>
        `
    }

    translateSelectedValueFromBoolean(selectedValue: boolean): string {
        switch(selectedValue) {
            case true:
                return 'yes';
            case false:
                return 'no';
            default:
                return '';
        }
    }

    translateSelectedValueToBoolean(selectedValue: string): boolean {
        switch(selectedValue) {
            case 'yes':
                return true;
            case 'no':
                return false;
            default:
                return null;
        }
    }

    handleOptionSelection(event) {
        const isChecked:boolean = event?.target?.checked;
        const value:string = event?.target?.value;
        const newSelectedValue:string = isChecked ? value : null;
        
        this.dispatchEvent(new CustomEvent('check-changed', {
            detail: {
                value: this.translateSelectedValueToBoolean(newSelectedValue),
                index: this.itemIndex
            },
            bubbles: true,
            composed: true
        }));
    }
}