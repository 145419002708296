export interface VitalsDetails {
	_id?: string;
	_rev?: string;
	campaignId: string;
	channels: Array<string>;
	patientId: string;
	updatedAt?: string;
	type?: string;

	armCircumferenceCm?: string;
	armCircumferenceIn?: string;
	bloodPressurediastolic?: string;
	bloodPressureSystolic?: string;
	bmi?: string;
	headCircumferenceCm?: string;
	headCircumferenceIn?: string;
	heartRate?: string;
	heightCm?: string;
	heightIn?: string;
	o2Saturation?: string;
	respiratoryRate?: string;
	temperatureC?: string;
	temperatureF?: string;
	vitalsNotes?: string;
	weightKg?: string;
	weightLbs?: string;
	zScoreArmCircumference?: string;
	zScoreBmi?: string;
	zScoreHeightWeight?: string;
}

export const unitOfMeasure = {
	imperial: 'imperial',
	metric: 'metric',
};
