// Foundation
import { LitElement, customElement, html, css, property } from 'lit-element';

// Themes
import { themePrimaryBlue, themeSecondaryBlue, themeHoverGrey, themeLightGreyBorders, themeDarkGrey } from '../../styles/themeColors';

import '@material/mwc-button';

/** 
 * Events:
 * 
 * - `@click`
 */

@customElement('backpackemr-add-item-button')
export class CustomAddNewItemButton extends LitElement {

    static get styles() {
        return css`
            mwc-button {
                --mdc-theme-primary: ${themePrimaryBlue};
            }

            @media (max-width: 768px) {

            }
        `
        ;
    }

    @property({type: Boolean, attribute: true})
    disabled:boolean = false;

    @property({type: String, attribute: true})
    label:string = "";

    render() {
        return html`
            <mwc-button 
                outlined
                icon="add_circle_outline"
                label="${this.label}"
                ?disabled="${this.disabled}"
            ></mwc-button>
        `;
    }
}