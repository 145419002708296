import * as moment from "moment";
import config from "./config";

import { WebConsoleLog, WebConsoleLogSegment } from "./webConsoleLogger";

export enum Component
{
    None = 0,
    Auth = 1,
    Service = 2,
    Store = 3,
    Element = 4,
    EventDispatch = 5,
    EventReceipt = 6,
}

enum LogLevel
{
    Debug = 0,
    Info = 1,
    Warn = 2,
    Error = 3,
    Off = 99
}

class Logger {
    private loggingLevel = 0;

    static NS_PER_SEC = 1e9; // convert to nanoseconds
    static NS_TO_MS = 1e6; // convert to milliseconds

    constructor() {
        this.loggingLevel = config.getConfig("logLevel", LogLevel.Debug);
        this.debug(Component.None, "loggingLevel: " + this.loggingLevel);
    }

    public debug(component:Component, ...message) {
        this.logToConsole(component, LogLevel.Debug, message)
    }

    public info(component:Component, ...message) {
        this.logToConsole(component, LogLevel.Info, message)
    }

    public warn(component:Component, ...message) {
        this.logToConsole(component, LogLevel.Warn, message)
    }

    public error(component:Component, ...message: any[]) {
        if (message[0] instanceof Error) {
            var error = this.formatError(message[0]);
            var errArray = message.slice(1)
            error.push(...errArray);
            this.logToConsole(component, LogLevel.Error, errArray)
        } else if (message[message.length - 1] instanceof Error) {
            var error = this.formatError(message[message.length - 1]);
            var errArray = message.slice(0, message.length - 1)
            errArray.push(...error);
            this.logToConsole(component, LogLevel.Error, errArray)
        } else {
            this.logToConsole(component, LogLevel.Error, message);
        }
    }

    private formatError(e: Error):string[]{
        return [e.message, e.stack]
    }

    private logToConsole(component:Component, logLevel:LogLevel, message:any) {
        if (logLevel < this.loggingLevel) {
            return;
        }

        var log = new WebConsoleLog()
        log.segment(new WebConsoleLogSegment(`[${moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ')}]`).bold().background("black").color("white"))
        
        var logLevelString = LogLevel[logLevel]
        if (logLevel == LogLevel.Error) {
            log.segment(new WebConsoleLogSegment(` ${logLevelString}`).bold().background("red").color("white"));
        } else if (logLevel == LogLevel.Warn) {
            log.segment(new WebConsoleLogSegment(` ${logLevelString}`).bold().background("black").color("yellow"));
        } else if (logLevel == LogLevel.Info) {
            log.segment(new WebConsoleLogSegment(` ${logLevelString}`).bold());
        } else {
            log.segment(new WebConsoleLogSegment(` ${logLevelString}`).color("gray"));
        }
        log.segment(new WebConsoleLogSegment(`|`));
        
        var componentString = Component[component]
        if (component == Component.Auth) {
            log.segment(new WebConsoleLogSegment(`${componentString}:`).bold().color("#ED4949"));
        } if (component == Component.Service) {
            log.segment(new WebConsoleLogSegment(`${componentString}:`).bold().color("#3CD626"));
        } else if (component == Component.Store) {
            log.segment(new WebConsoleLogSegment(`${componentString}:`).bold().color("#49A2ED"));
        } else if (component == Component.Element) {
            log.segment(new WebConsoleLogSegment(`${componentString}:`).bold().color("#A749ED"));
        } else if (component == Component.EventDispatch) {
            log.segment(new WebConsoleLogSegment(`${componentString}:`).bold().color("yellow"));
        } else if (component == Component.EventReceipt) {
            log.segment(new WebConsoleLogSegment(`${componentString}:`).bold().color("#ED9449"));
        } 

        message.forEach(element => {
            if (typeof element == "object") {
                log.segment(new WebConsoleLogSegment().object(element));
            } else {
                log.segment(new WebConsoleLogSegment(`${element} `));
            }
        });
        log.log();
    }    
}

export default new Logger();