// Foundational
import { customElement, html, internalProperty, css, property } from 'lit-element';
import { ApolloLitElement } from '../apolloLitElement';

// utils
import authenticator from '../../utils/authenticator';
import { Snackbar } from '@material/mwc-snackbar';
import { v4 as uuidv4 } from 'uuid';

// graph QL
import { updatePatientProfile, createNewPatient } from '../../api/patientProfileMutations';
import { patientProfileQuery } from '../../api/queries';

import CurrentPatient from '../../models/currentPatient';

import { router } from '../../utils/routeRegistry';
import {
    PreventAndRedirectCommands,
    PreventResult,
    RedirectResult,
    Router,
    RouterLocation,
  } from '@vaadin/router';

// Web Components
import '@material/mwc-linear-progress'
import './patientProfileData.ts';

// Translations
import i18next from 'i18next';

// Global Themes
import { themeDarkGrey } from '../../styles/themeColors';

// Models
import { PatientProfileDetails, PatientProfileSummary, Queue, CurrentCampaignData } from '../../models/patient';

@customElement('patient-profile')
export class PatientProfile extends ApolloLitElement {
    // GQL
    query = patientProfileQuery;

    static get styles() {
        return css`
            .content {
                display: grid;
                justify-items: center;
                grid-template-columns: 1fr;
            }
            
            .profile-container {
                display: grid;
                grid-template-columns: 1fr;
                background-color: #fff;
                padding: 12px 12px;
                margin: 24px 0;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
                grid-row-gap: 12px;
                border-radius: 4px;
                width: 500px;
            }

            .section-header {
                margin: 12px 0px 6px 0px;
                font-size: 14px;
                color: ${themeDarkGrey};
            }

            .date-of-birth-age {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }

            backpackemr-select-two {
                --mdc-select-disabled-ink-color: ${themeDarkGrey};
            }
            
            mwc-textarea, backpackemr-text-field {
                --mdc-text-field-disabled-ink-color: ${themeDarkGrey};
            }

            @media (max-width: 600px) {
                .content {
                    display: grid;
                    justify-items: normal;
                }

                .profile-container {
                    width: auto;
                }
            }
        `;
    }
    
    @property({type: Object}) 
    location = router.location;

    @internalProperty()
    patient: any;

    @property({type: Boolean})
    createNewPatient: boolean = false;

    @internalProperty()
    isEditMode: boolean = false;

    get variables() {
        //TODO - we shouldn't be firing off an empty request to the API just cuz Apollo does so by default. Need to look into this more later!
        let patientID = '';
        if(this.location.pathname !== '/createNewPatient') {
            patientID = this.location?.params?.patientID.toString() || '';
            // console.log('variables used to fetch patient profile data: ', patientID);
        }
        const selectedCampaignID = window.localStorage.getItem('selectedCampaignID');
        
        return { patientID, selectedCampaignID };
    }

    connectedCallback(){
        super.connectedCallback();
        this.refetch();
    }

    firstUpdated() {
        if(this.location.pathname === '/createNewPatient') {
            this.createNewPatient = true;

            const newQueue:Queue = {
                queueId: '',
                status: '',
                timestamp: null
              }
            
              const campaignData:CurrentCampaignData = {
                  clinicNumber: null,
                  queue: newQueue,
              }

            const newProfileSummary:PatientProfileSummary = {
                firstName: '',
                lastName: '',
                gender: '',
                governmentID: '',
                humanID: this._generateHumanId(),
                dateOfBirth: '',
                profilePhoto: null,
                currentCampaignData: campaignData,
            }

            const newProfileDetails:PatientProfileDetails = {
                middleName: '',
                occupation: '',
                phoneNumber: '',
                contactName: '',
                email: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                insuranceType: '',
                insuranceID: '',
                patientConsented: false,
                patientConsentedUserDate: '',
            }

            const initializedNewPatientData = {
                patientID: `${this.location.params.patientID}`,
                patientProfileSummary: newProfileSummary,
                patientProfileDetails: newProfileDetails,
                visits: null
            }

            this.patient = initializedNewPatientData;
        }

        this._notifyHeaderBarShowBackButton();  //todo- this was a hack. fix on a rainy/snowy day.
    }

    render() {
        //TODO: stop clearing the screen out every time we fetch data. What's the best way?
        if (this.loading) {
            return html` 
                <div>
                    <mwc-linear-progress indeterminate></mwc-linear-progress>
                </div>
            `;
        }
        if (this.errors) {
            return html`
                <div>Error: ${this.errors[0].message}</div>
            `;
        }

        if(!this.data && this.location.params.creatingNewPatient !== 'true' && !this.data.patientProfile ){
            return html`<div>${i18next.t('patientProfileIsUnavailable')}</div>`
        }

        if(this.data.patientProfile){
            this.patient = JSON.parse(JSON.stringify(this.data.patientProfile)); //need to make a deep copy of the data from the api in order to edit it. But its resetting this.patient every render so it's not allowing any value updates for example patientConsent boolean toggle
        }

        return html`
            <div class="content">
                <mwc-snackbar 
                    id="saveSnackbar"
                    leading
                >
                    <mwc-icon-button icon="close" slot="dismiss"></mwc-icon-button>
                </mwc-snackbar>
            
                <patient-profile-data
                    .creatingNewPatient="${this.createNewPatient}"
                    .patientData="${this.patient}"
                    @new-patient-created="${this._createNewPatient}"
                    @edit-content="${() => this.isEditMode = true }"
                    @patient-profile-details-updated="${this._handlePatientProfileDataUpdate}">
                </patient-profile-data>
            </div>
        `;
    }

    async _handlePatientProfileDataUpdate(event:CustomEvent) {
        // update temp data
        CurrentPatient.updateDetailsInLocalState(event.detail.variables, 'profile-update');

        // only enters if "return to patient chart" button clicked and temp data has been stored (verified in child component)
        if(event?.detail?.updateImmediately || event.detail?.archived) {
            const resp = await CurrentPatient.saveQueuedDetails('patient-profile-details');

            // dispatches event to parent snackbar to display result while navigating to patient chart
            this.dispatchEvent(new CustomEvent('saved-queued-details', {
                detail: {
                  success: resp.updatePatientProfile.success,
                  type: 'patient-profile-update',
                  archived: event.detail?.archived
                },
                bubbles: true,
                composed: true
              }))
            
            if (!event.detail?.archived) {
                Router.go(`/patients/${resp.updatePatientProfile.refreshedDetails.patientID}`);
            } else {
                Router.go('/patients');
            }
        } 
    }

    //todo - this needs to be changed.. just a hack!
    _notifyHeaderBarShowBackButton() {
        this.dispatchEvent(new CustomEvent('show-header-back-button', {
            detail: {},
            bubbles: true,
            composed: true 
        }));
    }

    _generateHumanId() {
        let uuidStrippedOfNonNumbers = uuidv4().replace(/[A-z-]/gi, '');
        while (uuidStrippedOfNonNumbers.length < 8) {
            uuidStrippedOfNonNumbers += '0';
        }
        return uuidStrippedOfNonNumbers.substring(0, 4) + '-' + uuidStrippedOfNonNumbers.substring(4,8);
    }

    async _createNewPatient(event:CustomEvent) {
        const mutationName = 'createNewPatient'
        if(event.detail?.variables) {
            try {
                const resp = await this.updateDocument(createNewPatient, event.detail.variables);
                if(resp[mutationName]?.success) {
                    this.isEditMode = false;
                    Router.go(`/patients/${resp[mutationName].refreshedDetails.newPatientID}`);

                    const snackbar = this.shadowRoot.querySelector('#saveSnackbar') as Snackbar;
                    snackbar.timeoutMs = 5000;
                    snackbar.labelText = 'Saved!';
                    snackbar.show();
                } else {
                    const snackbar = this.shadowRoot.querySelector('#saveSnackbar') as Snackbar;
                    snackbar.timeoutMs = 10000;
                    snackbar.labelText = `Failed to create patient.`;
                    snackbar.show();
                }
            } catch (error) {
                console.error('MUTATION - create patient error: ', error);
                
            }
        }
    }

    onBeforeEnter(location:RouterLocation, commands:PreventAndRedirectCommands, router:Router): Promise<unknown> | RedirectResult | undefined { 
        if (!authenticator.isAuthenticated()) {
            return commands.redirect('/login');
        }
    }
    
    onBeforeLeave(location:RouterLocation, commands:PreventAndRedirectCommands, router:Router): Promise<unknown> | PreventResult | undefined { 

        if (this.createNewPatient && this.isEditMode) {
            if (!window.confirm('Unsaved changes! Are you sure you want to leave without saving this patient?')) {
                return commands.prevent();
            }
        }
    }
}