import { gql } from '@apollo/client/core';

export const GET_SIGNED_PUT_URL = gql`
    mutation($fileType: String!) {
        getSignedPutURL(fileType: $fileType) {
            code,
            success,
            message,
            photoUploadDetails {
                fileName,
                signedURL
            }
        }
    }
`;

export const CREATE_PHOTO_RECORD = gql`
    mutation createPhotoRecord($input: PhotoRecordInput){
        createPhotoRecord(input:$input){
            code,
            success,
            message
        }
    }
`;