import { customElement, html, property, internalProperty, css, LitElement } from 'lit-element';
import * as moment from 'moment';

// Components
import '../common/customSelect.ts';
import '../common/customTextField';
import '../common/customTextArea';
import '../common/customDatePicker.ts';
import '../common/customCheckbox.ts';
import '../patientChart/medicalTabHeader.ts';
import '../patientChart/medicalTabFooterSave.ts';
import './deletePatientDialogBox.ts';
import '../common/customRadioButtons.ts';
import '../common/customPhotoUploader';

//Models
import { Patient, PatientProfileDetails, PatientProfileSummary, Queue, CurrentCampaignData } from '../../models/patient';
import { Campaign } from '../../models/campaignDetails';

import { themeDarkGrey, themePrimaryBlue, themeRed } from '../../styles/themeColors';

import { currentPatientProfileDataChangesVar  } from '../../api/graphQL';
import { isEmpty } from 'lodash';

// Util
import i18next from 'i18next';
import { formatDateTime, convertDateToAge } from '../../utils/dateTimeFormatter';
import { occupations, gender, insuranceOptions } from '../../constants/patientProfile';
import { generateTranslatedList } from '../../I18n/i18nUtil';
import authenticator from '../../utils/authenticator';

import { Router } from '@vaadin/router';

@customElement('patient-profile-data')
export class PatientProfileData extends LitElement {
    static get styles() {
        return css`
            .content {
                display: grid;
                justify-items: center;
                grid-template-columns: 1fr;
            }

            .delete-header-edit {
            display: grid;
            grid-template-columns: 1fr;
            }

            .delete-button {
                --mdc-theme-primary: ${themeRed};
                --mdc-theme-on-primary: white;
                --mdc-button-outline-color: ${themeRed};
            }

            h4 {
            margin: 0;
            font-size: 16px;
            }
            
            .profile-container {
                display: grid;
                grid-template-columns: 1fr;
                background-color: #fff;
                padding: 12px 12px;
                margin: 24px 0;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
                grid-row-gap: 12px;
                border-radius: 4px;
                width: 500px;
            }

            .profile-photo-container {
                display: grid;
                grid-template-columns: 1fr;
                justify-items: center;
            }

            .campaign-selection {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 10px;
            }

            .section-header {
                margin: 12px 0px 6px 0px;
                font-size: 14px;
                color: ${themeDarkGrey};
            }

            .two-column-row {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 16px;
            }

            .update-button {
                --mdc-theme-primary: ${themePrimaryBlue};
                --mdc-theme-on-primary: white;
                justify-self: center;
            }

            backpackemr-checkbox {
            margin-top: 16px;
            }

            footer-save-bar {
            position: sticky; 
            bottom: 0;
            }

            .return-button {
                --mdc-theme-primary: ${themePrimaryBlue};
                --mdc-theme-on-primary: white;
                --mdc-button-outline-color: ${themePrimaryBlue};
            }

            mwc-button {
                --mdc-theme-primary: ${themePrimaryBlue};
                --mdc-theme-on-primary: white;
                --mdc-button-outline-color: ${themePrimaryBlue};
            }

            @media (max-width: 600px) {
                .content {
                    display: grid;
                    justify-items: normal;
                }

                .profile-container {
                    width: auto;
                }

                .campaign-selection {
                grid-template-columns: 1fr;
                }

                .two-column-row {
                grid-template-columns: 1fr;
                }
            }
        `;
    }

    @property({type: Boolean, attribute: true})
    readOnlyMode: boolean = true;

    @property({type: Boolean, attribute: false})
    creatingNewPatient: boolean;

    @internalProperty()
    dialogBoxOpen: boolean = false;

    @property({attribute: false})
    patientData: Patient;

    @internalProperty()
    loggedInUser:string = authenticator.getAuthenticatedUser().name;

    @internalProperty()
    loggedInUserID:string = authenticator.getAuthenticatedUser().userID;

    @internalProperty()
    consentReceivedByUserDate:string;

    @internalProperty()
    campaignList: Array<Campaign> = authenticator.getAuthenticatedUser().campaignList;

    @internalProperty()
    selectedCampaignID:string;
    
    @internalProperty()
    selectedLocationID:string;

    @internalProperty()
    formattedCampaignList:any;

    @internalProperty()
    listOfSelectedCampaignLocations: Array<any>;

    @internalProperty()
    showUploadDialog:boolean = false;

    @internalProperty()
    photoPreview:any;

    firstUpdated(){   
        if(!this.patientData.createdBy){
        this.patientData.createdBy = this.loggedInUserID;
        }

        if(this.creatingNewPatient) {
        this.readOnlyMode = false;
        }

        if(this.campaignList.length === 1) {
        this.selectedCampaignID = this.campaignList[0].campaignID;
        if(this.campaignList[0].locations) {
            this.listOfSelectedCampaignLocations = this.campaignList[0].locations.map(location => {
            return {
                key: location.locationID,
                value: location.locationName
            }
            })
        } else {
            this.listOfSelectedCampaignLocations = [];
        }
        }
        
        this.formattedCampaignList = this.campaignList.map(campaign => {
        return {
            key: campaign.campaignID,
            value: campaign.campaignName
        }
        })

        // const campaignInput = this.shadowRoot.getElementById("campaignInput");
        // campaignInput.focus();
    }

    render() {
        return html `
        <div class="content">
            <div class="profile-container">
                ${this.creatingNewPatient === true 
                    ? html`<h2 style="justify-self: center">${i18next.t('newPatient')}</h2>` 
                    : html `   
                        <div class="return-button">
                            <mwc-button
                                label="${i18next.t('returnToPatientChart').toString()}"
                                icon="replay"
                                outlined
                                @click="${this._handleReturnToPatientChart}">
                            </mwc-button>
                        </div>
                        <medical-tab-header
                            tabName="${i18next.t('patientProfile').toString() || ''}"
                            .updatedDateTime="${this.patientData?.updatedAt}"
                            ?readOnlyMode="${this.readOnlyMode}"
                            @edit-content="${() => this.readOnlyMode = false}" 
                            @save="${this.saveDoc}">
                        </medical-tab-header>
                    `
                }
                <div class="profile-photo-container">
                    <profile-photo
                        style="justify-self: center"
                        .photo="${this.patientData.patientProfileSummary.profilePhoto}"
                        .previewPhoto="${this.photoPreview}"
                        large>
                    </profile-photo>
                    ${!this.patientData?.patientProfileSummary?.profilePhoto
                        ? html`
                            <mwc-button
                                label="${i18next.t('uploadPhoto').toString()}"
                                icon="file_upload"
                                ?disabled="${this.readOnlyMode}"
                                outlined
                                @click="${ () => this.showUploadDialog = true }">
                            </mwc-button>
                        `
                        : html`
                            <mwc-button
                                label="${i18next.t('removePhoto').toString()}"
                                ?disabled="${this.readOnlyMode}"
                                class="delete-button"
                                outlined
                                @click="${ this.handleDeleteProfilePhoto }">
                            </mwc-button>
                        `
                    }
                    
                    <backpackemr-photo-uploader
                        ?showUploadDialog="${this.showUploadDialog}"
                        @upload-dialog-closed="${ () => this.showUploadDialog = false}"
                        @image-upload-result="${this._handleImageUploadResult}"
                    ></backpackemr-photo-uploader>
                </div>
                <!-- Campaign Selection -->
                ${this.creatingNewPatient 
                    ? html `
                        <div class="campaign-selection">
                            <backpackemr-select-two 
                                autofocus
                                label="${i18next.t('campaign').toString()}"
                                disableCustomValue
                                ?disabled="${this.readOnlyMode || this.campaignList?.length === 1 }"
                                value="${this.selectedCampaignID}"
                                required
                                errorMessage="${i18next.t('thisFieldIsRequired').toString()}"
                                .items="${this.formattedCampaignList}"
                                @value-changed="${event => {this._handleCampaignSelection(event); this.patientProfileDetailsUpdate}}">
                            </backpackemr-select-two>
                            <backpackemr-select-two
                                label="${i18next.t('location').toString()}"
                                disableCustomValue
                                ?disabled="${this.readOnlyMode || !this.selectedCampaignID}"
                                value="${this.selectedCampaignID ? this.selectedLocationID : ''}"
                                .items="${this.listOfSelectedCampaignLocations}"
                                @value-changed="${event => this.selectedLocationID = event.detail.key}">
                            </backpackemr-select-two>
                        </div>` 
                    : html ``}
                
                <!-- Personal Info -->
                <div class="section-header">
                    <h4>${i18next.t('personalInfo')}</h4>
                    <div class="two-column-row">
                        <backpackemr-text-field
                            label="${i18next.t('humanId').toString()}"
                            value="${this.patientData.patientProfileSummary.humanID || ''}"
                            @text-field-changed="${(event:CustomEvent) => {
                                this.patientData.patientProfileSummary.humanID = event.detail.value;
                                this.patientProfileDetailsUpdate()}}"
                            ?disabled="${this.readOnlyMode}">
                        </backpackemr-text-field>
                        <backpackemr-text-field
                            label="${i18next.t('govId').toString()}"
                            value="${this.patientData.patientProfileSummary.governmentID || ''}"
                            @text-field-changed="${(event:CustomEvent) => {
                                this.patientData.patientProfileSummary.governmentID = event.detail.value;
                                this.patientProfileDetailsUpdate()}}"
                            ?disabled="${this.readOnlyMode}">
                        </backpackemr-text-field>
                    </div>
                    <backpackemr-text-field
                        label="${i18next.t('firstName').toString()}"
                        value="${this.patientData.patientProfileSummary.firstName || ''}"
                        @text-field-changed="${(event) => {
                            this.patientData.patientProfileSummary.firstName = event.detail.value; 
                            this.patientProfileDetailsUpdate()}}"
                        ?disabled="${this.readOnlyMode}">
                    </backpackemr-text-field>
                    <backpackemr-text-field
                        label="${i18next.t('middleName').toString()}"
                        value="${this.patientData.patientProfileDetails.middleName || ''}"
                        @text-field-changed="${(event) => {
                            this.patientData.patientProfileDetails.middleName = event.detail.value;
                            this.patientProfileDetailsUpdate()}}"
                        ?disabled="${this.readOnlyMode}">
                    </backpackemr-text-field>
                    <backpackemr-text-field
                        label="${i18next.t('lastName').toString()}"
                        value="${this.patientData.patientProfileSummary.lastName || ''}"
                        @text-field-changed="${(event) => {
                            this.patientData.patientProfileSummary.lastName = event.detail.value;
                            this.patientProfileDetailsUpdate()}}"
                        ?disabled="${this.readOnlyMode}">
                    </backpackemr-text-field>
                    <div class="two-column-row">
                        <custom-date-picker
                            label="${i18next.t('dateOfBirth').toString()}"
                            value="${this.patientData.patientProfileSummary.dateOfBirth}"
                            @date-selected="${(event:CustomEvent) => this._handleBirthDateSelected(event)}"
                            ?disabled="${this.readOnlyMode}">
                        </custom-date-picker>
                        <backpackemr-text-field
                            label="${i18next.t('age').toString()}"
                            value="${this.formatAge(this.patientData.patientProfileSummary.dateOfBirth)}"
                            disabled>
                        </backpackemr-text-field>
                    </div>

                    <!-- <backpackemr-select-two
                        label="${i18next.t('gender').toString()}"
                        .items="${generateTranslatedList(gender)}"
                        disableCustomValue
                        value="${this.patientData.patientProfileSummary.gender}"
                        @value-changed="${(event:CustomEvent) => this.patientData.patientProfileSummary.gender = event.detail.key}"
                        ?disabled="${this.readOnlyMode}">
                    </backpackemr-select-two> -->
                    <custom-checkbox-group
                        .label="${i18next.t('gender').toString()}"
                        ?disabled="${this.readOnlyMode}"
                        .buttonOptions="${generateTranslatedList(gender)}"
                        .selectedValue="${this.patientData.patientProfileSummary.gender}"
                        @check-changed="${event => {
                            this.patientData.patientProfileSummary.gender = event.detail?.value;
                            this.patientProfileDetailsUpdate()
                            this.requestUpdate();
                    }}">
                    </custom-checkbox-group>
                    <backpackemr-select-two
                        label="${i18next.t('occupation').toString()}"
                        .items="${generateTranslatedList(occupations)}"
                        disableCustomValue
                        value="${this.patientData.patientProfileDetails.occupation}"
                        @value-changed="${(event:CustomEvent) => {
                            this.patientData.patientProfileDetails.occupation = event.detail.key;
                            this.patientProfileDetailsUpdate()}}"
                        ?disabled="${this.readOnlyMode}">
                    </backpackemr-select-two>
                </div>

                <!-- Contact Info -->
                <div class="section-header">
                    <h4>${i18next.t('contactInformation')}</h4>
                    <backpackemr-text-field
                        label="${i18next.t('address1').toString()}"
                        value="${this.patientData.patientProfileDetails.addressLine1 || ''}"
                        @text-field-changed="${(event) => {
                            this.patientData.patientProfileDetails.addressLine1 = event.detail.value;
                            this.patientProfileDetailsUpdate()}}"
                        ?disabled="${this.readOnlyMode}">
                    </backpackemr-text-field>
                    <backpackemr-text-field
                        label="${i18next.t('address2').toString()}"
                        value="${this.patientData.patientProfileDetails.addressLine2 || ''}"
                        @text-field-changed="${(event) => {
                            this.patientData.patientProfileDetails.addressLine2 = event.detail.value;
                            this.patientProfileDetailsUpdate()}}"
                        ?disabled="${this.readOnlyMode}">
                    </backpackemr-text-field>
                    <backpackemr-text-field
                        label="${i18next.t('address3').toString()}"
                        value="${this.patientData.patientProfileDetails.addressLine3 || ''}"
                        @text-field-changed="${(event) => {
                            this.patientData.patientProfileDetails.addressLine3 = event.detail.value;
                            this.patientProfileDetailsUpdate()}}"
                        ?disabled="${this.readOnlyMode}">
                    </backpackemr-text-field>
                    <backpackemr-text-field
                        label="${i18next.t('phoneNumber').toString()}"
                        value="${this.patientData.patientProfileDetails.phoneNumber || ''}"
                        @text-field-changed="${(event) => {
                            this.patientData.patientProfileDetails.phoneNumber = event.detail.value;
                            this.patientProfileDetailsUpdate()}}"
                        ?disabled="${this.readOnlyMode}">
                    </backpackemr-text-field>
                    <backpackemr-text-field
                        label="${i18next.t('email').toString()}"
                        value="${this.patientData.patientProfileDetails.email || ''}"
                        @text-field-changed="${(event) => {
                            this.patientData.patientProfileDetails.email = event.detail.value;
                            this.patientProfileDetailsUpdate()}}"
                        ?disabled="${this.readOnlyMode}">
                    </backpackemr-text-field>
                    <backpackemr-text-field
                        label="${i18next.t('contactName').toString()}"
                        helperText="${i18next.t('contactInfoHelperText').toString()}"
                        value="${this.patientData.patientProfileDetails.contactName || ''}"
                        @text-field-changed="${(event) => {
                            this.patientData.patientProfileDetails.contactName = event.detail.value;
                            this.patientProfileDetailsUpdate()}}"
                        ?disabled="${this.readOnlyMode}">
                    </backpackemr-text-field>
                </div>
                
                <!-- Insurance -->
                <div class="section-header">
                    <h4>${i18next.t('insurance')}</h4>
                    <backpackemr-select-two
                        label="${i18next.t('insurance').toString()}"
                        disableCustomValue
                        .items="${generateTranslatedList(insuranceOptions)}"
                        value="${this.patientData.patientProfileDetails.insuranceType}"
                        @value-changed="${(event:CustomEvent) => {
                            this.patientData.patientProfileDetails.insuranceType = event.detail.key;
                            this.patientProfileDetailsUpdate()}}"
                        ?disabled="${this.readOnlyMode}">
                    </backpackemr-select-two>
                    <backpackemr-text-field
                        label="${i18next.t('insuranceId').toString()}"
                        value="${this.patientData.patientProfileDetails.insuranceID || ''}"
                        @text-field-changed="${(event) => {
                            this.patientData.patientProfileDetails.insuranceID = event.detail.value;
                            this.patientProfileDetailsUpdate()}}"
                        ?disabled="${this.readOnlyMode}">
                    </backpackemr-text-field>
                </div>

                <!-- Consent Given -->
                <div class="section-header">
                    <h4>${i18next.t('consent')}</h4>
                    <backpackemr-checkbox 
                        label="${i18next.t('consented').toString()}"
                        ?checked="${this.patientData.patientProfileDetails.patientConsented}" 
                        @check-changed="${this._handleConsentToggle}"
                        ?disabled="${this.readOnlyMode}">
                    </backpackemr-checkbox>

                    ${this.patientData.patientProfileDetails.patientConsented 
                        ? html`
                            <backpackemr-text-field
                                label="${i18next.t('consentReceivedBy').toString()}"
                                value="${this.patientData.patientProfileDetails.patientConsentedUserDate}"
                                ?disabled="${true}">
                            </backpackemr-text-field>
                        `                            
                        : html`` 
                    }
                </div>

                ${this.creatingNewPatient === false 
                    ? html `
                        <backpackemr-text-field
                            label="${i18next.t('systemId').toString()}"
                            value="${this.patientData.patientID || ''}"
                            disabled>
                        </backpackemr-text-field>
                        <mwc-button
                            class="delete-button"
                            label="${i18next.t('archivePatient').toString()}"
                            outlined
                            @click="${() => {
                                this.dialogBoxOpen = true; 
                                this.patientProfileDetailsUpdate()
                                this.requestUpdate()
                            }}">
                        </mwc-button>
                    `
                    : html ``
                }

                ${this.dialogBoxOpen ? 
                    html` 
                        <delete-patient-dialog-box
                            ?open=${this.dialogBoxOpen}
                            @confirm-delete=${this.handleDeletePatient}
                            @cancel=${() => {this.dialogBoxOpen = false; this.requestUpdate()}}>
                        </delete-patient-dialog-box>`
                    : html ``
                }

            ${this.creatingNewPatient ? 
                html`
                    <footer-save-bar
                        ?readOnlyMode="${this.readOnlyMode}"
                        ?disableSaveButton="${this.creatingNewPatient && !this.selectedCampaignID}"
                        @save="${this.saveDoc}"
                    >
                    </footer-save-bar>` 
                : html ``
            }
            
            </div>
        </div>
        `
    }

    handleDeletePatient() {
        this.patientData.patientProfileDetails.archived = true;
        this.patientProfileDetailsUpdate();
    }

    _handleReturnToPatientChart() {
        if (isEmpty(currentPatientProfileDataChangesVar())) {
            Router.go(`/patients/${this.patientData.patientID}`);
        } else {
            this.dispatchEvent(new CustomEvent('patient-profile-details-updated', {
                detail: {
                    variables: this.getUpdateMutationVariables(),
                    updateImmediately: true
                },
                bubbles: true,
                composed: true
            }));
        }
    }

    _handleCampaignSelection(event) {
        this.selectedCampaignID = event.detail.key;
        this.selectedLocationID = null;

        const selectedCampaign = this.campaignList.find(campaign => {
        return campaign.campaignID === this.selectedCampaignID;
        })

        if(selectedCampaign && selectedCampaign.locations) {
        this.listOfSelectedCampaignLocations = selectedCampaign.locations.map( location => {
            return {
            key: location.locationID,
            value: location.locationName
            }
        });
        } else {
        this.listOfSelectedCampaignLocations = [];
        this.selectedLocationID = '';
        }
        
        this.requestUpdate();
    }

    formatAge(dateOfBirth:string):string {
        if(!dateOfBirth) return '';

        // TODO - move this over to dateTimeFormatter.ts utility
        // convertDateToAge()

        return moment(dateOfBirth).fromNow(true) + ' old';
    } 

    _handleBirthDateSelected(event) {
        this.patientData.patientProfileSummary.dateOfBirth = event.detail.date;
        this.patientProfileDetailsUpdate()
        this.requestUpdate();
    }

    _handleConsentToggle() {
        this.patientData.patientProfileDetails.patientConsented = !this.patientData.patientProfileDetails.patientConsented;
        if(this.patientData.patientProfileDetails.patientConsented === true) {
            this.consentReceivedByUserDate = new Date().toISOString();
            this.patientData.patientProfileDetails.patientConsentedUserDate = `${this.loggedInUser} on ${formatDateTime(this.consentReceivedByUserDate)}`
        } else {
            this.patientData.patientProfileDetails.patientConsentedUserDate = '';
        }
        this.patientProfileDetailsUpdate();
        this.requestUpdate();
    }

    // Only triggered for creating a new patient
    saveDoc() {
        this.readOnlyMode = !this.readOnlyMode;
        this.createNewPatient();
    }

    patientProfileDetailsUpdate() {
        this.dispatchEvent(new CustomEvent('patient-profile-details-updated', {
            detail: {
                variables: this.getUpdateMutationVariables(),
                archived: this.patientData.patientProfileDetails.archived
            },
            bubbles: true,
            composed: true
        }))
    }

    getUpdateMutationVariables() {
        const patientProfileSummary:PatientProfileSummary = {
        firstName:this.patientData.patientProfileSummary.firstName,
        lastName: this.patientData.patientProfileSummary.lastName,
        gender: this.patientData.patientProfileSummary.gender,
        governmentID: this.patientData.patientProfileSummary.governmentID,
        humanID: this.patientData.patientProfileSummary.humanID,
        dateOfBirth: this.patientData.patientProfileSummary.dateOfBirth,
        age: this.patientData.patientProfileSummary.dateOfBirth ? moment(this.patientData.patientProfileSummary.dateOfBirth).fromNow(true) : '',
        profilePhoto: this.patientData.patientProfileSummary.profilePhoto
        }

        const patientProfileDetails:PatientProfileDetails = {
        middleName: this.patientData.patientProfileDetails.middleName,
        occupation: this.patientData.patientProfileDetails.occupation,
        phoneNumber: this.patientData.patientProfileDetails.phoneNumber,
        contactName: this.patientData.patientProfileDetails.contactName,
        email: this.patientData.patientProfileDetails.email,
        addressLine1: this.patientData.patientProfileDetails.addressLine1,
        addressLine2: this.patientData.patientProfileDetails.addressLine2,
        addressLine3: this.patientData.patientProfileDetails.addressLine3,
        insuranceType: this.patientData.patientProfileDetails.insuranceType,
        insuranceID: this.patientData.patientProfileDetails.insuranceID,
        patientConsented: this.patientData.patientProfileDetails.patientConsented,
        patientConsentedUserDate: this.patientData.patientProfileDetails.patientConsentedUserDate,
        archived: this.patientData.patientProfileDetails.archived
        }

        return {
        id: this.patientData.patientID,
        input: {
            patientID: this.patientData.patientID,
            updatedAt: new Date().toISOString(),
            patientProfileSummary: patientProfileSummary,
            patientProfileDetails: patientProfileDetails,
            archived: this.patientData.patientProfileDetails.archived? this.patientData.patientProfileDetails.archived : false //boolean, if true, add it to the document on the api
        }
        }
    }


    createNewPatient() {
        this.dispatchEvent(new CustomEvent('new-patient-created', {
        detail: {
            variables: this.getCreateMutationVariables()
        },
        bubbles: true,
        composed: true
        }))
    }

    getCreateMutationVariables() {
        const patientProfileSummary:PatientProfileSummary = {
        firstName:this.patientData.patientProfileSummary.firstName,
        lastName: this.patientData.patientProfileSummary.lastName,
        gender: this.patientData.patientProfileSummary.gender,
        governmentID: this.patientData.patientProfileSummary.governmentID,
        humanID: this.patientData.patientProfileSummary.humanID,
        dateOfBirth: this.patientData.patientProfileSummary.dateOfBirth,
        age: this.patientData.patientProfileSummary.dateOfBirth ? moment(this.patientData.patientProfileSummary.dateOfBirth).fromNow(true) : '',
        profilePhoto: this.patientData.patientProfileSummary?.profilePhoto,
        currentCampaignData: this.patientData.patientProfileSummary.currentCampaignData
        }

        const patientProfileDetails:PatientProfileDetails = {
        middleName: this.patientData.patientProfileDetails.middleName,
        occupation: this.patientData.patientProfileDetails.occupation,
        phoneNumber: this.patientData.patientProfileDetails.phoneNumber,
        contactName: this.patientData.patientProfileDetails.contactName,
        email: this.patientData.patientProfileDetails.email,
        addressLine1: this.patientData.patientProfileDetails.addressLine1,
        addressLine2: this.patientData.patientProfileDetails.addressLine2,
        addressLine3: this.patientData.patientProfileDetails.addressLine3,
        insuranceType: this.patientData.patientProfileDetails.insuranceType,
        insuranceID: this.patientData.patientProfileDetails.insuranceID,
        patientConsented: this.patientData.patientProfileDetails.patientConsented,
        patientConsentedUserDate: this.patientData.patientProfileDetails.patientConsentedUserDate,
        archived: null
        }

        return {
        input: {
            createdBy: this.patientData.createdBy,
            updatedAt: new Date().toISOString(),
            currentCampaignID: this.selectedCampaignID,
            currentCampaignLocationID: this.selectedLocationID,
            patientProfileSummary: patientProfileSummary,
            patientProfileDetails: patientProfileDetails
        }
        }
    }

    async _handleImageUploadResult(event) {
        if (event?.detail?.success && event?.detail?.fileName && event?.detail?.fileSize && event?.detail?.localFile) {

            this.patientData.patientProfileSummary.profilePhoto = {
                fileName: event.detail?.fileName,
                fileSize: event.detail?.fileSize,
            };
            this.patientProfileDetailsUpdate();
            this.photoPreview = event.detail?.localFile;
        }
        else {
            //todo - notify the user it failed
            console.error('_handleImageUpload ~ failed to upload ~ message:', event?.detail?.message);
        }
    }

    handleDeleteProfilePhoto() {
        this.patientData.patientProfileSummary.profilePhoto = null;
        this.photoPreview = null;
        this.patientProfileDetailsUpdate();
        this.requestUpdate();
    }
}
