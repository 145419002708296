// Foundational
import { LitElement, customElement, html, internalProperty, css } from 'lit-element';
import { GraphQLError } from 'graphql';

// import { isDirtyVar, setIsDirty } from '../../api/graphQL';

import CurrentPatient from '../../models/currentPatient';

// utils
import authenticator from '../../utils/authenticator';

import { router } from '../../utils/routeRegistry';
import {
    Commands,
    PreventAndRedirectCommands,
    RedirectResult,
    Router,
    RouterLocation,
  } from '@vaadin/router';

// Web Components
import '@material/mwc-linear-progress';
import '@material/mwc-icon-button';
import { Snackbar } from '@material/mwc-snackbar';
import '@material/mwc-snackbar';
import '@material/mwc-dialog';
import '@material/mwc-button';
import '@vaadin/vaadin-text-field/vaadin-text-area';

import './patientChartSummary.ts';
import './visitList.ts';
import './visitListMobile.ts';
import './visitHeader.ts';
import './visitTabBar.ts';
import './visitTabContent.ts';
import './updatePatientVisitsDialogBox.ts';

// Models
import { Visit } from '../../models/visit';
import { PUBLICHEALTH } from '../../models/medicalCareTabs';
import { Queue } from '../../models/patient';

// Global Themes
import { themeLightGreyBorders,themePrimaryBlue } from '../../styles/themeColors';

import { Campaign } from '../../models/campaignDetails';
import { ExamDetails } from '../../models/clinical/exam';
import { ComplaintsDetails } from '../../models/clinical/complaints';
import { PublicHealthDetails } from '../../models/clinical/publicHealth';
import { VitalsDetails } from '../../models/clinical/vitals';
import { HistoryDetails } from '../../models/clinical/history';
import { LabsDetails } from '../../models/clinical/labs';
import { VisionDetails } from '../../models/clinical/vision';
import { VirtualCareDetails } from '../../models/clinical/virtualCare';

import { campaignCustomLists } from '../../api/graphQL';
import { selectedCampaignID } from '../../api/graphQL';
@customElement('patient-chart')
export class PatientChart extends LitElement {

    static get styles() {
        return css`
            .visits-container {
                display: grid;
                grid-template-columns: 180px 1fr;
                grid-template-areas: 
                    "visits-list-toggle visits-list-toggle"
                    "visits visit-details";
            }

            .visits-list-toggle {
                grid-area: visits-list-toggle;
                border: 1px solid ${themeLightGreyBorders};
            }
                
                .visit-details {
                    grid-area: visit-details;
                    display: grid;
                    align-content: start;
                    margin: 12px 0 0 12px;
                    min-height: 600px;
                }

            .custom-input {
                margin-left: 12px;
                padding-left: 8px;
                padding-top: 4px;
                padding-bottom: 4px;
            }

            .visits-list-toggle {
                display: none;
            }

            .visits-list {
                grid-area: visits;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            }

            .grid {
                display: grid;
            }

            mwc-linear-progress {
                --mdc-theme-primary: ${themePrimaryBlue};
            }

            /* tablet */
            @media (max-width: 768px) {

                /* .main-content {
                    display: grid;
                    grid-template-columns: 1fr;
                } */

                /* visits  */
                .visits-list-toggle {
                    display: grid;
                }

                .visits-container {
                    display: grid;
                    grid-template-columns: 1fr;
                }

                .visit-details {
                    margin: 12px 0 0 0;
                }

            }
            
        `;
    }

    @internalProperty()
    loading: boolean;

    @internalProperty()
    errors: ReadonlyArray<GraphQLError>;

    @internalProperty()
    patient: any;

    @internalProperty()
    location = router.location;

    @internalProperty()
    patientID: string;
    
    @internalProperty()
    selectedVisitID: string; // patientCampaignID
    
    @internalProperty()
    selectedTabName: string;

    @internalProperty()
    visitDialogBoxOpen: boolean = false;

    @internalProperty()
    creatingNewVisit: boolean = false;

    @internalProperty()
    loggedInUser: string = authenticator.getAuthenticatedUser()?.name || '';

    @internalProperty()
    loggedInUserCampaigns: Array<Campaign> = authenticator.getAuthenticatedUser()?.campaignList || [];

    @internalProperty()
    examDetails: ExamDetails;

    @internalProperty()
    complaintsDetails: ComplaintsDetails;

    @internalProperty()
    publicHealthDetails: PublicHealthDetails;

    @internalProperty()
    vitalsDetails: VitalsDetails;

    @internalProperty()
    historyDetails: HistoryDetails;

    @internalProperty()
    labsDetails: LabsDetails;

    @internalProperty()
    visionDetails: VisionDetails;

    @internalProperty()
    virtualCareDetails: VirtualCareDetails;

    get variables() {
        const patientID = this.location.params?.patientID.toString() || '';
        const visitID = new URLSearchParams(this.location.search).get('visit');
        const tab = new URLSearchParams(this.location.search).get('tab');
        const selectedCampaignID = window.localStorage.getItem('selectedCampaignID');


        console.log('variables used to fetch data: ', {patientID, visitID, tab, selectedCampaignID});

        return { patientID, visitID, tab, selectedCampaignID };
    }

    connectedCallback() {
        super.connectedCallback();
        this.fetchPatientFromServer();
        selectedCampaignID(window.localStorage.getItem('selectedCampaignID'));
    }

    async fetchPatientFromServer(){
        this.loading = true;
        this.errors = null;

        this.patient = await CurrentPatient.fetchPatientFromServer(this.variables);
        
        //todo - error handling in the CurrentPatient model. Populate the error prop and pass it down. 
        campaignCustomLists(this.patient?.selectedVisit?.campaignCustomLists);

        this.examDetails = this.patient?.selectedVisit?.visitMedicalDetails?.examDetails;
        this.complaintsDetails = this.patient?.selectedVisit?.visitMedicalDetails?.complaintDetails;
        this.labsDetails = this.patient?.selectedVisit?.visitMedicalDetails?.labsDetails;
        this.visionDetails = this.patient?.selectedVisit?.visitMedicalDetails?.visionDetails;
        this.historyDetails = this.patient?.selectedVisit?.visitMedicalDetails?.historyDetails;
        this.vitalsDetails = this.patient?.selectedVisit?.visitMedicalDetails?.vitalsDetails;
        this.virtualCareDetails = this.patient?.selectedVisit?.visitMedicalDetails?.virtualCareDetails;
        this.publicHealthDetails = this.patient?.selectedVisit?.visitMedicalDetails?.publicHealthDetails;
        
        this.loading = false;
    }

    shouldUpdate(changedProps) {
        if (changedProps.get('location')) {
            const patientIDChanged:boolean = changedProps.get('location')?.params?.patientID !== this.patientID;
            // const prevURLVisitID:string = new URLSearchParams(changedProps.get('location')?.search).get('visit');            
            const newURLVisitID:string = new URLSearchParams(this.location.search).get('visit');        
            // const visitIDChanged:boolean = prevURLVisitID !== newURLVisitID;
            const visitIDChanged:boolean = newURLVisitID !== this.selectedVisitID;

            if (patientIDChanged || visitIDChanged) {
                this.fetchPatientFromServer();
            } 
        } 

        return super.shouldUpdate(changedProps);
    }

    firstUpdated(){
        this.addEventListener('tab-info-updated', this._handleTabUpdate);
        this._notifyHeaderBarShowBackButton();  //todo- this was a hack. fix on a rainy/snowy day.
    }

    render() {     
        this.patientID = this.location.params?.patientID.toString();
        this.selectedVisitID = new URLSearchParams(this.location.search).get('visit');
        
        // if (this.loading) return html`<div><mwc-linear-progress indeterminate></mwc-linear-progress></div>`;
        // if (!this.patient) return html`<div>Patient chart is unavailable</div>`;
        
        if (this.errors) return html`<div>Error: ${this.errors[0].message}</div>`;
        if (!this.patient) return html`<div><mwc-linear-progress indeterminate></mwc-linear-progress></div>`;

        //todo - should this live somewhere else so we're not doing this on ever render? or maybe we want to... 🤔
        if(this.patient?.visits?.length > 0) {
            if(this.selectedVisitID){
                const tabNameFromURLSearchParams = new URLSearchParams(this.location.search).get('tab');
                this.selectedTabName = tabNameFromURLSearchParams ? tabNameFromURLSearchParams.toUpperCase() : PUBLICHEALTH.TABNAME;
            } else { 
                // visitID wasn't requested, so default to selecting the most recent visit and set the URL. don't need to refetch patient chart, already get these details by default
                this.selectedVisitID = this.patient?.visits[0].patientCampaignID;
                this.selectedTabName = PUBLICHEALTH.TABNAME;
            }
        }
        
        return html`
            <patient-chart-summary 
                .patientID=${this.patientID} 
                .patientProfileSummary=${this.patient.patientProfileSummary}
                .currentCampaignData="${this.patient.patientProfileSummary.currentCampaignData}">
            </patient-chart-summary>

            <mwc-snackbar 
                id="saveSnackbar"
                leading
            >
                <mwc-icon-button icon="close" slot="dismiss"></mwc-icon-button>
            </mwc-snackbar>

            <div class="visits-container">

                <!-- visit list (left side) -->
                <visit-list class="visits-list" 
                    .visits="${this.patient?.visits || []}"
                    .selectedVisitID="${this.patient?.selectedVisit?.patientCampaignID}"
                    @visit-changed="${this._handleVisitChanged}"
                    @add-new-visit="${this._handleAddNewVisit}">
                </visit-list>

                <visit-list-mobile class="visits-list-toggle" 
                    .visits="${this.patient?.visits || []}"
                    .selectedVisitID="${this.patient?.selectedVisit?.patientCampaignID}"
                    @visit-changed="${this._handleVisitChanged}"
                    @add-new-visit="${this._handleAddNewVisit}">
                </visit-list-mobile>

                <div class="visit-details">
                    
                    ${this.patient?.visits?.length > 0
                        ? 
                            this.loading
                                ? html`<div><mwc-linear-progress indeterminate></mwc-linear-progress></div>`
                                : html `
                                    <visit-header 
                                        .selectedVisit="${this.patient?.selectedVisit}"
                                        .selectedVisitMedicalModule="${this.patient?.selectedVisit?.medicalModule}"
                                        @edit-visit-details="${this._handleUpdateVisitDetails}"
                                        .campaignList="${this.loggedInUserCampaigns}"
                                        .currentCampaignData="${this.patient.patientProfileSummary?.currentCampaignData}"
                                        @queue-status-and-category-update="${event => this.handleQueueStatusUpdate(event)}">
                                    </visit-header>
                                    <visit-tab-bar 
                                        @tab-changed="${this._handleTabChanged}" 
                                        .selectedTabName="${this.selectedTabName}"
                                        .selectedVisitMedicalModule="${this.patient?.selectedVisit?.medicalModule}">
                                    </visit-tab-bar>
                                    <visit-tab-content 
                                        .selectedTabName="${this.selectedTabName}"
                                        .selectedVisit="${this.patient?.selectedVisit}"
                                        campaignID="${this.patient?.selectedVisit?.campaignID}"
                                        patientID="${this.patientID}"
                                        .loggedInUser="${this.loggedInUser}"
                                        .examDetails="${this.examDetails}"
                                        .complaintDetails="${this.complaintsDetails}"
                                        .virtualCareDetails="${this.virtualCareDetails}"
                                        .vitalsDetails="${this.vitalsDetails}"
                                        .publicHealthDetails="${this.publicHealthDetails}"
                                        .labsDetails="${this.labsDetails}"
                                        .visionDetails="${this.visionDetails}"
                                        .historyDetails="${this.historyDetails}">
                                    </visit-tab-content>`
                        : html `<h3>This patient does not have any visits yet.</h3>`                    
                    }
                    
                </div>

                ${this.visitDialogBoxOpen
                    ? html`
                        <update-patient-visits-dialog-box
                            ?open="${this.visitDialogBoxOpen}"
                            patientID="${this.patientID}"
                            patientName="${`${this.patient?.patientProfileSummary?.firstName} ${this.patient?.patientProfileSummary?.lastName}`}"
                            visitID="${this.selectedVisitID}"
                            .campaignList="${this.loggedInUserCampaigns}"
                            .visitDate="${this.patient?.selectedVisit?.visitDateTime ? this.patient.selectedVisit.visitDateTime : ''}"
                            .selectedLocationID="${this.patient?.selectedVisit?.locationID}"
                            .selectedCampaignID="${this.patient?.selectedVisit?.campaignID}"
                            .creatingNewVisit="${this.creatingNewVisit}"
                            @dialog-box-closed="${this._handleDialogBoxClosed}"
                            >
                        </update-patient-visits-dialog-box>
                    `
                    : html ``
                }
            </div>
        `;
    }

    handleQueueStatusUpdate(event) {
        const variables = {
            patientID: this.patientID,
            selectedCampaignID: selectedCampaignID(), 
            queueId: event.detail.selectedQueueCategory,
            status: event.detail.selectedQueueStatus,
            timestamp: event.detail.timestamp
        }

        CurrentPatient.updateDetailsInLocalState(variables, 'queue-data-update');
    }

    displayProgressBar() {
        if (this.loading) {
            return html`<div><mwc-linear-progress indeterminate></mwc-linear-progress></div>`;
        }

        return html``;
    }

    _handleAddNewVisit() {
        this.creatingNewVisit = true;
        this.visitDialogBoxOpen = !this.visitDialogBoxOpen;
    }

    _handleUpdateVisitDetails() {
        this.creatingNewVisit = false;
        this.visitDialogBoxOpen = !this.visitDialogBoxOpen
    }

    _handleDialogBoxClosed(event) {
        if(event.detail.key === 'canceled') {
            this.visitDialogBoxOpen = false;
        } else if(event.detail.key ==='submitted') {
            this.visitDialogBoxOpen = false;
            if(event.detail.creatingNewVisit) {
                this._createNewVisit(event.detail.variables);
            } else {
                this._updatePatientVisit(event.detail.variables);
            }
        }
    }

    async _updatePatientVisit(variables:any) {
        try {
            const resp = await CurrentPatient.updatePatientVisit(variables);

            if(resp?.updatePatientVisit?.success) {
                //works for now... but probably should return the new selected visit info and just set the this.selectedVisit and maybe run a "requestUpdate();"?

                //if deleting the visit
                if(variables.deletingVisit && resp?.updatePatientVisit?.refreshedDetails.visitID) {
                    this.navigateToVisit(resp?.updatePatientVisit?.refreshedDetails?.visitID);
                } else { //else if just updating the visit
                    this.fetchPatientFromServer();
                }
            }

        } 
        catch (error) {
            console.error("🚀 ~ file: patientChart.ts ~ line 396 ~ PatientChart ~ _updatePatientVisit ~ error", error)
            const snackbar = this.shadowRoot.querySelector('#saveSnackbar') as Snackbar;
            snackbar.timeoutMs = 10000;
            snackbar.labelText = `Failed to update visit details.`;
            snackbar.show();
        }
    }

    async _createNewVisit(variables) {
        try {
            const resp = await CurrentPatient.createNewVisit(variables);

            if(resp?.createNewVisit?.success) {
                this.navigateToVisit(resp.createNewVisit.refreshedDetails.visitID);
            }

        } catch (error) {
            console.error('patient chart ~ _createNewVisit',error);
            const snackbar = this.shadowRoot.querySelector('#saveSnackbar') as Snackbar;
            snackbar.timeoutMs = 10000;
            snackbar.labelText = `Failed to create a new visit.`;
            snackbar.show();
        }
    }

    _handleVisitChanged(event) {
        if(event?.detail?.patientCampaignID !== this.selectedVisitID){
            this.navigateToVisit(event.detail.patientCampaignID);
        }
    }

    navigateToVisit(patientCampaignID:string) {
        this.saveQueuedInfoAndRefresh();
        Router.go(`/patients/${this.patientID}?visit=${patientCampaignID}&tab=${PUBLICHEALTH.TABNAME}`);
    }

    async _handleTabChanged(event:CustomEvent) {
        if(!event?.detail?.tabName) return;

        if(event?.detail?.tabName !== this.selectedTabName){
            Router.go(`/patients/${this.patientID}?visit=${this.selectedVisitID}&tab=${event.detail.tabName}`);
            this.saveQueuedInfoAndRefresh()
        }
    }
    async saveQueuedInfoAndRefresh(){
        const updatedTab = await CurrentPatient.saveQueuedDetails('tab-details');
        if (!updatedTab) return;

        let successfullySavedChanges: boolean = false;
        let savedTabName: string = '';

        // exam tab
        if (updatedTab?.updateExamDetails) {
            successfullySavedChanges = updatedTab.updateExamDetails.success;
            this.examDetails = updatedTab.updateExamDetails.refreshedDetails;
            savedTabName = 'Exam';
        }
        else if (updatedTab?.createExamDetails?.refreshedDetails) {
            successfullySavedChanges = updatedTab.createExamDetails.success;
            this.examDetails = updatedTab.createExamDetails.refreshedDetails;
            savedTabName = 'Exam';
        }
        // complaints tab
        else if (updatedTab?.updateComplaintDetails) {
            successfullySavedChanges = updatedTab.updateComplaintDetails.success;
            this.complaintsDetails = updatedTab.updateComplaintDetails.refreshedDetails;
            savedTabName = 'Complaints';
        }
        else if (updatedTab?.createComplaintDetails?.refreshedDetails) {
            successfullySavedChanges = updatedTab.createComplaintDetails.success;
            this.complaintsDetails = updatedTab.createComplaintDetails.refreshedDetails;
            savedTabName = 'Complaints';
        }
        // public health tab
        else if (updatedTab?.updatePublicHealthDetails) {
            successfullySavedChanges = updatedTab.updatePublicHealthDetails.success;
            this.publicHealthDetails = updatedTab.updatePublicHealthDetails.refreshedDetails;
            savedTabName = 'Public Health';
        }
        else if (updatedTab?.createPublicHealthDetails?.refreshedDetails) {
            successfullySavedChanges = updatedTab.createPublicHealthDetails.success;
            this.publicHealthDetails = updatedTab.createPublicHealthDetails.refreshedDetails;
            savedTabName = 'Public Health';
        }
        // vitals tab
        else if (updatedTab?.updateVitalsDetails) {
            successfullySavedChanges = updatedTab.updateVitalsDetails.success;
            this.vitalsDetails = updatedTab.updateVitalsDetails.refreshedDetails;
            savedTabName = 'Vitals';
        }
        else if (updatedTab?.createVitalsDetails?.refreshedDetails) {
            successfullySavedChanges = updatedTab.createVitalsDetails.success;
            this.vitalsDetails = updatedTab.createVitalsDetails.refreshedDetails;
            savedTabName = 'Vitals';
        }
        // history tab
        else if (updatedTab?.updateHistoryDetails) {
            successfullySavedChanges = updatedTab.updateHistoryDetails.success;
            this.historyDetails = updatedTab.updateHistoryDetails.refreshedDetails;
            savedTabName = 'History';
        }
        else if (updatedTab?.createHistoryDetails?.refreshedDetails) {
            successfullySavedChanges = updatedTab.createHistoryDetails.success;
            this.historyDetails = updatedTab.createHistoryDetails.refreshedDetails;
            savedTabName = 'History';
        }
        // labs tab
        else if (updatedTab?.updateLabsDetails) {
            successfullySavedChanges = updatedTab.updateLabsDetails.success;
            this.labsDetails = updatedTab.updateLabsDetails.refreshedDetails;
            savedTabName = 'Labs';
        }
        else if (updatedTab?.createLabsDetails?.refreshedDetails) {
            successfullySavedChanges = updatedTab.createLabsDetails.success;
            this.labsDetails = updatedTab.createLabsDetails.refreshedDetails;
            savedTabName = 'Labs';
        }
        // vision tab
        else if (updatedTab?.updateVisionDetails) {
            successfullySavedChanges = updatedTab.updateVisionDetails.success;
            this.visionDetails = updatedTab.updateVisionDetails.refreshedDetails;
            savedTabName = 'Vision';
        }
        else if (updatedTab?.createVisionDetails?.refreshedDetails) {
            successfullySavedChanges = updatedTab.createVisionDetails.success;
            this.visionDetails = updatedTab.createVisionDetails.refreshedDetails;
            savedTabName = 'Vision';
        }
        // virtual care tab
        else if (updatedTab?.updateVirtualCareDetails) {
            successfullySavedChanges = updatedTab.updateVirtualCareDetails.success;
            this.virtualCareDetails = updatedTab.updateVirtualCareDetails.refreshedDetails;
            savedTabName = 'Virtual Care';
        }
        else if (updatedTab?.createVirtualCareDetails?.refreshedDetails) {
            successfullySavedChanges = updatedTab.createVirtualCareDetails.success;
            this.virtualCareDetails = updatedTab.createVirtualCareDetails.refreshedDetails;
            savedTabName = 'Virtual Care';
        }

        this.informUserOfSaveStatus(successfullySavedChanges, savedTabName);
    }

    informUserOfSaveStatus(didSave:boolean, tabName:string) {
        if (didSave) {
            const snackbar = this.shadowRoot.querySelector('#saveSnackbar') as Snackbar;
            snackbar.timeoutMs = 5000;
            snackbar.labelText = 'Saved!';
            snackbar.show();
        } 
        else {
            const snackbar = this.shadowRoot.querySelector('#saveSnackbar') as Snackbar;
            snackbar.timeoutMs = 10000;
            snackbar.labelText = `Failed to save the ${tabName} tab. Someone updated it since you opened this patient visit. We've refreshed the ${tabName} tab with the latest information. You may go back add your updates again.`;
            snackbar.show();
        }
    }

    //todo - this needs to be changed.. just a hack!
    _notifyHeaderBarShowBackButton() {
        this.dispatchEvent(new CustomEvent('show-header-back-button', {
            detail: {},
            bubbles: true,
            composed: true 
        }));
    }

    //fired by every field change to update the queued tab update
    async _handleTabUpdate(event:CustomEvent) {
        if(event?.detail?.variables) {
            // update local cache
            CurrentPatient.updateDetailsInLocalState(event.detail.variables, 'tab-update');

            if(event?.detail?.saveImmediately) {
                this.saveQueuedInfoAndRefresh();
            }
        }
    }
    
    onBeforeEnter(location:RouterLocation, commands:PreventAndRedirectCommands, router:Router): Promise<unknown> | RedirectResult | undefined {
        if (!authenticator.isAuthenticated()) {
            return commands.redirect('/login');
        }
    }
}