// Foundation
import { LitElement, customElement, html, property, css, internalProperty } from 'lit-element';
import { query } from 'lit-element/lib/decorators.js';
// Themes
import { themePrimaryBlue, themeLightGreyBorders, themeSecondaryBlue } from '../../styles/themeColors';

// Models
import { Visit } from '../../models/visit'
import { Campaign } from '../../models/campaignDetails';
import { CurrentCampaignData } from '../../models/patient';
import { QUEUECATEGORIES, QUEUESTATUS } from '../../models/queueCategories';
import { Queue } from '../../models/patient';

import i18next from 'i18next';

// Components
import './visitStationStatus.ts'
import '@material/mwc-button';
import '@material/mwc-icon';
import './visitHeaderQueueData.ts';

// Utility
import { formatDateTime, getTimeSinceDate, formatGetTimeSinceDate } from '../../utils/dateTimeFormatter';
import { selectedCampaignID } from '../../api/graphQL';


@customElement('visit-header')
export class VisitHeader extends LitElement {

    static get styles() {
        return css`
            .visit-header {
                display: grid;
                align-items: start;
                padding: 16px;
                font-size: 14px;
                grid-gap: 8px;
                background-color: #fff;
                border: 1px solid ${themeLightGreyBorders};
                border-bottom: none;
            }
                
            .visit-type {
                display: grid;
                grid-template-columns: 1fr auto;
                font-size: 18px;
                margin-bottom: 12px;
                align-items: center;
            }
            
            .visit-date-campaign-location-and-queue-data {
                display: grid;
                grid-gap: 10px;
                grid-template-columns: 1fr max-content;
                justify-items: left;
                align-items: center;
                grid-column-gap: 20px;
            }

            .visit-date {
                display: grid;
                grid-template-columns: max-content 1fr;
                align-items: center;
            }
            
            .visit-campaign-location {
                display: grid;
                grid-template-columns: max-content 1fr;
                align-items: center;
                word-wrap: break-word;
            }

            .edit-button {
                --mdc-theme-primary: ${themePrimaryBlue};
                --mdc-theme-on-primary: white;
                --mdc-button-outline-color: ${themePrimaryBlue};
                justify-self: end;
                text-align: right;
            }

            mwc-button{
                --mdc-theme-primary: ${themePrimaryBlue};
                --mdc-theme-on-primary: white;
            }

             /* tablet */
             @media (max-width: 768px) {
                .visit-header {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-gap: 8px;

                    padding: 16px;
                    font-size: 14px;

                    background-color: #fff;
                    border: 1px solid ${themeLightGreyBorders};
                    border-bottom: none;
                }

                .visit-date-campaign-location {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 8px;
                }

             }

        `
        ;
    }

    @property({attribute: false})
    selectedVisit: Visit;

    @property({type: String, attribute: false})
    selectedVisitMedicalModule: string;

    @property({type: Array, attribute: false})
    campaignList:Array<Campaign>

    @internalProperty()
    locationName:string;

    @internalProperty()
    campaignName:string;

    @property({attribute: false})
    currentCampaignData: CurrentCampaignData;

    @internalProperty()
    selectedQueueCategory: string = '';

    @internalProperty()
    selectedQueueStatus: string;

    @internalProperty()
    queueWaitingTimestamp: string;
    
    connectedCallback(): void {
        if (this.currentCampaignData?.queue) {
            this.selectedQueueCategory = this.currentCampaignData.queue.queueId;
            this.selectedQueueStatus = QUEUESTATUS[this.currentCampaignData.queue.status];
            this.queueWaitingTimestamp = this.currentCampaignData.queue.timestamp;
        }
        super.connectedCallback();
    }

    firstUpdated() {
        const selectedCampaign = this.campaignList.find(campaign => campaign.campaignID === this.selectedVisit.campaignID);
        if(selectedCampaign.locations.length === 1) {
            this.locationName = selectedCampaign.locations[0].locationName;
        } else {
            const location = selectedCampaign.locations.find(location => location.locationID === this.selectedVisit.locationID);
            if(location) {
                this.locationName = location.locationName;
            }
        }
        this.campaignName = selectedCampaign.campaignName;
    }

    render() {
        if(!this.selectedVisit){
            return html`
                ${i18next.t('noVisitSelected')}
            `;
        }

        return html`
            <div class="visit-header">
                <div class="visit-type">
                    ${this.displayVisitMedicalModule()}
                    
                    <!-- ${i18next.t('visitOptions').toString()} -->
                    <mwc-button
                        label="options"
                        icon="more_vert"
                        outlined
                        class="edit-button"
                        @click="${this.handleEditVisitDetails}">
                    </mwc-button>
                </div>

                <div class="visit-date-campaign-location-and-queue-data">
                    <div>
                        <div class="visit-date">
                            <mwc-icon>event</mwc-icon>
                            ${formatDateTime(this.selectedVisit.visitDateTime)} (${formatGetTimeSinceDate(this.selectedVisit.visitDateTime)})
                        </div>
                        
                        <div class="visit-campaign-location">
                            <mwc-icon>location_on</mwc-icon>
                            ${this.formatLocationString()}
                        </div>
                    </div>

                    <!-- If no campaign selected, don't render any queue data. If a campaign is selected, render the queue data component. Need two of them in order to keep the anchors for the drop downs in place. -->
                    ${selectedCampaignID() ? 
                        this.selectedQueueCategory ? 
                            html`
                                <visit-header-queue-data
                                    .selectedQueueStatus="${this.selectedQueueStatus}"
                                    .selectedQueueCategory="${this.selectedQueueCategory}"
                                    .queueWaitingTimestamp="${this.queueWaitingTimestamp}"
                                    @queue-status-and-category-update="${event => this.handleQueueStatusUpdate(event)}"
                                ></visit-header-queue-data>
                            `: html`
                                <visit-header-queue-data
                                    .selectedQueueStatus="${this.selectedQueueStatus}"
                                    .selectedQueueCategory="${this.selectedQueueCategory}"
                                    .queueWaitingTimestamp="${this.queueWaitingTimestamp}"
                                    @queue-status-and-category-update="${event => this.handleQueueStatusUpdate(event)}"
                                ></visit-header-queue-data> 
                            `: html``}
                </div>
            </div>
        `;
    }

    handleQueueStatusUpdate(event) {
        this.selectedQueueCategory = event.detail.selectedQueueCategory;
        this.selectedQueueStatus = event.detail.selectedQueueStatus;
        this.queueWaitingTimestamp = event.detail.timestamp;
        this.requestUpdate();
    }

    formatLocationString() {
        if(this.locationName) {
            return html`
                ${this.campaignName}: ${this.locationName}
            `
        } else {
            return html`${this.campaignName}`
        }
    }

    handleEditVisitDetails() {
        this.dispatchEvent(new CustomEvent('edit-visit-details', {
            bubbles: true,
            composed: true
        }))
    }

    displayVisitMedicalModule() {
        switch(this.selectedVisitMedicalModule){
            case 'primaryCare' :
                return `${i18next.t('primaryCareVisit')}`;
            case 'cleftLip' :
                return `${i18next.t('cleftLipVisit')}`;
            case 'orthopedic' :
                return `${i18next.t('orthopedic')}`
        }
    }

}