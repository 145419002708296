// Foundation
import { LitElement, customElement, html, property, css } from 'lit-element';
import * as moment from 'moment';

// Themes
import { themePrimaryBlue, themeDarkGrey } from '../../styles/themeColors';

import i18next from 'i18next';
import { compose } from 'redux';
import { formatGetTimeSinceDate } from '../../utils/dateTimeFormatter';

@customElement('medical-tab-header')
export class VisitEditStatusBar extends LitElement {

    static get styles() {
        return css`
            * {
                margin: 0;
                padding: 0;
            }

            .content {
                display: grid;
                grid-template-columns: 1fr auto auto;
                grid-template-areas:
                        "tab-name last-updated edit-button";
                grid-column-gap: 16px;
                align-items: center;
                justify-content: space-between;
                margin: 20px 0;
                background-color: #ffffff;
                color: ${themeDarkGrey};
            }

            .tab-name {
                grid-area: tab-name;
                font-size: 18px;
                font-weight: 700;
            }

            .last-updated {
                grid-area: last-updated;
                font-size: 14px;
            }
            
            .edit-button {
                grid-area: edit-button;
                --mdc-theme-primary: ${themePrimaryBlue};
                --mdc-theme-on-primary: white;
                justify-self: end;
            }

             /* tablet */
             @media (max-width: 768px) {
             
             }

             /* phone */
             @media (max-width: 488px) {
                .content {
                    grid-template-areas:
                        "tab-name last-updated"
                        "edit-button edit-button";
                    grid-column-gap: 0;
                    grid-row-gap: 8px;
                    margin: 16px 0;
                }

                .edit-button {
                    justify-self: stretch;
                }
             }
        `
        ;
    }

    @property()
    tabName:string = '';

    @property()
    updatedDateTime:string = '';

    @property({type: Boolean, attribute: true})
    readOnlyMode:boolean;

    render() {
        if(this.readOnlyMode) {
            return html`
                <div class="content">
                    <div class="tab-name">${this.tabName}</div>
                    <div class="last-updated">
                        ${this.formatDateSince()}
                    </div>
                    <mwc-button         
                        class="edit-button"
                        label="${i18next.t('edit').toString()}"
                        icon="edit"
                        raised
                        @click="${this._handleEditButtonClick}">
                    </mwc-button>
                </div>
            `;
        }
        else {
            return html`
                <div class="content">
                    <div class="tab-name">${this.tabName}</div>
                    <div class="last-updated">
                        ${this.formatDateSince()}
                    </div>
                    <div>
                    </div>
                </div>
            `;
        }
    }

    formatDateSince(){
        return this.updatedDateTime && html`${i18next.t('updated')} <strong>${formatGetTimeSinceDate(this.updatedDateTime)}</strong>`;
    }

    _handleEditButtonClick() {
        this.readOnlyMode = !this.readOnlyMode;
        this.dispatchEvent(new CustomEvent('edit-content', { 
            bubbles: true,
            composed: true
        }));
    }
}