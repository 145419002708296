// Foundation
import { LitElement, customElement, html, css, property } from 'lit-element';

// Themes
import { themePrimaryBlue, themeDarkGrey } from '../../styles/themeColors';

import '@material/mwc-select';
import '@material/mwc-list/mwc-list-item';

import i18next from 'i18next';
import { LANG_KEY } from '../../I18n/i18nUtil'

@customElement('language-select')
export class LanguageSelect extends LitElement {

    static get styles() {
        return css`
            .container {
                display: grid;
            }
            
            mwc-select {
                --mdc-select-disabled-ink-color: ${themeDarkGrey};
                --mdc-theme-primary: ${themePrimaryBlue};
            }

            @media (max-width: 768px) {

            }
        `
        ;
    }

    @property({type: String, attribute: true})
    language:string = '';

    render() {
        return html`
            <div class="container">
                <mwc-select 
                    icon="public"
                    value="${this.language}"
                    outlined
                    @selected="${this._handleChangeLanguage}"
                >
                    <mwc-list-item value="en">English</mwc-list-item>
                    <mwc-list-item value="es">Spanish</mwc-list-item>
                    <mwc-list-item value="fr">French</mwc-list-item>
                </mwc-select>
            </div>
        `;
    }

    _handleChangeLanguage(event){
        if (event?.target?.value){
            i18next.changeLanguage(event.target.value);
            
            // Store in browser local storage for next  time
            localStorage.setItem(LANG_KEY, event.target.value);
        }
    }
}