import { LitElement, customElement, html, css, TemplateResult } from 'lit-element';

@customElement('page-not-found')
export class PageNotFound extends LitElement {

  static get styles() {
    return css`

    `;
  }

  render():TemplateResult {
    
    return html`
      <div>
        404 - I think you're lost
      </div>
    `;
  }

}