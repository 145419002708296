import { LitElement, customElement, html, property } from 'lit-element';

@customElement('app-link')
export class AppLink extends LitElement {
    @property()
    href:string = "";
  
    render() {
        return html`
            <a href='${this.href}' @click='${this.linkClick}'>
                <slot></slot>
            </a><br/>
        `;
    }
    
    linkClick(event) {
        event.preventDefault();
    }
}
