// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

// Themes
import { themeMediumGrey } from '../../../styles/themeColors';

// Components
import '../medicalTabHeader.ts';
import '../../common/customSelect.ts';
import '../../common/customTextField';
import '../../common/customTextArea';
import '../../common/customDatePicker.ts';

import '../../common/customRadioButtons.ts';
import '../../common/customCheckboxGroup.ts';
import '../../common/customCheckboxGroupYesNo.ts';

//translations
import i18next from 'i18next';
import { generateTranslatedList } from '../../../I18n/i18nUtil'
import { occupationalRiskGroups, livingConditions, cookingConditions, booleanResponses } from '../../../constants/primaryCare-publicHealth';

// Models
import { PublicHealthDetails } from '../../../models/clinical/publicHealth';

@customElement('tab-public-health')
export class TabPublicHealth extends LitElement {

    static get styles() {
        return css`
            .content {
                display: grid;
            }

            h3 {
                font-size: 16px;
                margin-top: 0;
            }

            .section {
                display: grid;
            }
            
            .row {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 16px;
                align-items: start;
                padding-bottom: 16px;
            }

            .label {
                font-size: 14px;
                font-weight: 500;
                color: ${themeMediumGrey};
            }

            .sub-header {
                font-size: 14px;
                font-weight: 700;
                margin: 8px 0;
            }

            .indent {
                padding-left: 12px;
            }

            @media (max-width: 768px) {
                .row {
                    display: grid;
                    grid-template-columns: 1fr;
                }

                .label {
                    padding-bottom: 8px;
                }

                .indent {
                    padding-left: 0;
                }
            }
        `
        ;
    }

    @property({attribute: false})
    details: PublicHealthDetails;
    
    @internalProperty()
    readOnlyMode:boolean = true;

    @property()
    patientID:string = '';

    @property()
    visitID:string = '';

    @property()
    campaignID:string = '';

    firstUpdated() {
        if (!this.details) {
            this.details = {
                type: 'patient-primaryCarePublicHealth',
                campaignId: this.campaignID,
                channels: [this.campaignID],
                patientId: this.patientID,
                updatedAt: null,

                contacts: null,
                cookingConditions: null,
                drinkingWaterFiltered: null,
                firstSymptomsDate: null,
                knownCaseCloseContact: null,
                livingConditions: null,
                notes: null,
                occupationalRiskGroup: null,
                traveledInternationally: null,
                visitedCountries: null
                
            };
        }

        this.readOnlyMode = this.details?._id ? true : false;
    }

    render() {        
        if(!this.details){
            return html`
                <h3>${i18next.t('publicHealth')}</h3>
                <p>${i18next.t('noPublicHealthData')}.</p>
            `;
        }
        
        return html`
            <medical-tab-header
                tabName="${i18next.t('publicHealth').toString() || ''}"
                .updatedDateTime="${this.details?.updatedAt}"
                ?readOnlyMode="${this.readOnlyMode}"
                @edit-content="${this._handleEditContent}">
            </medical-tab-header>

            <div class="content">
                <div class="section">
                    <h3>${i18next.t('coronavirusPublicHealthQuestions')}</h3>
                    <div class="row">
                        <div class="label">${i18next.t('firstSymptomsDate')}:</div>
                        <custom-date-picker
                            .value="${this.details?.firstSymptomsDate}"
                            @date-selected="${this._handleDateSelected}"
                            ?disabled="${this.readOnlyMode}">
                        </custom-date-picker>
                    </div>
                    <div class="row">
                        <div class="label">${i18next.t('occupationalRiskGroup')}</div>
                        <backpackemr-select-two
                            .items="${generateTranslatedList(occupationalRiskGroups)}"
                            ?disabled="${this.readOnlyMode}"
                            .value="${this.details?.occupationalRiskGroup}"
                            @value-changed="${(event:CustomEvent) => {
                                this.details.occupationalRiskGroup = event.detail.key
                                this.tabDetailsUpdated();
                            }}">
                        </backpackemr-select-two>
                    </div>
                    <div class="sub-header">${i18next.t('prior14DaySymptoms')}...</div>
                    <div class="row">
                        <div class="label indent">${i18next.t('knownCaseCloseContact')}</div>
                        <backpackemr-checkbox-group-yes-no
                            .selectedValue="${this.details?.knownCaseCloseContact}"
                            ?disabled="${this.readOnlyMode}"
                            @check-changed="${event => {
                                this.details.knownCaseCloseContact = event?.detail?.value;
                                this.requestUpdate();
                                this.tabDetailsUpdated();
                            }}">
                        </backpackemr-checkbox-group-yes-no>
                    </div>
                    <div class="row">
                        <div class="label indent">${i18next.t('traveledInternationally')}</div>

                        <backpackemr-checkbox-group-yes-no
                            .selectedValue="${this.details?.traveledInternationally}"
                            ?disabled="${this.readOnlyMode}"
                            @check-changed="${event => {
                                this.details.traveledInternationally = event?.detail?.value;
                                if(!this.details?.traveledInternationally) {
                                    this.details.visitedCountries = '';
                                }
                                this.requestUpdate();
                                this.tabDetailsUpdated();
                            }}">
                        </backpackemr-checkbox-group-yes-no>
                    </div>
                    ${this.details?.traveledInternationally ? 
                        html`
                            <div class="row">
                                <div class="label"></div>
                                <backpackemr-text-area
                                    placeholder="List of countries"
                                    .value="${this.details?.visitedCountries || ''}"
                                    @text-changed = ${(event:CustomEvent) => {
                                        this.details.visitedCountries = event.detail.value
                                        this.tabDetailsUpdated();
                                    }}
                                    ?disabled="${this.readOnlyMode}">
                                </backpackemr-text-area>
                            </div>
                        `
                        : html``
                    }
                    <div class="row">
                        <div class="label indent">${i18next.t('contacts')}</div>
                        <backpackemr-text-area
                            .value="${this.details?.contacts || ''}"
                            @text-changed="${(event:CustomEvent) => {
                                this.details.contacts = event.detail.value
                                this.tabDetailsUpdated();
                            }}"
                            ?disabled="${this.readOnlyMode}">
                        </backpackemr-text-area>
                    </div>
                </div>

                <div class="section">
                    <h3>${i18next.t('publicHealthGeneralQuestions')}</h3>
                    <div class="row">
                        <div class="label">${i18next.t('livingConditions')}:</div>
                        <backpackemr-select-two
                            .items="${generateTranslatedList(livingConditions)}"
                            ?disabled="${this.readOnlyMode}"
                            .value="${this.details?.livingConditions}"
                            @value-changed="${(event:CustomEvent) => {
                                this.details.livingConditions = event.detail.key
                                this.tabDetailsUpdated();
                            }}">
                        </backpackemr-select-two>
                    </div>
                    <div class="row">
                        <div class="label">${i18next.t('drinkingWaterFiltered')}</div>
                        <backpackemr-checkbox-group-yes-no
                            .selectedValue="${this.details?.drinkingWaterFiltered}"
                            ?disabled="${this.readOnlyMode}"
                            @check-changed="${event => {
                                this.details.drinkingWaterFiltered = event?.detail?.value;
                                this.requestUpdate();
                                this.tabDetailsUpdated();
                            }}">
                        </backpackemr-checkbox-group-yes-no>
                    </div>
                    <div class="row">
                        <div class="label">${i18next.t('cookingConditions')}</div>
                        <backpackemr-select-two
                            .items="${generateTranslatedList(cookingConditions)}"
                            ?disabled="${this.readOnlyMode}"
                            .value="${this.details?.cookingConditions}"
                            @value-changed="${(event:CustomEvent) => {
                                this.details.cookingConditions = event.detail.key
                                this.tabDetailsUpdated();
                            }}">
                        </backpackemr-select-two>
                    </div>
                </div>
                <div class="section">
                    <h3>${i18next.t('generalNotes')}</h3>
                    <backpackemr-text-area
                        .value="${this.details?.notes}"
                        @text-changed="${(event:CustomEvent) => {
                            this.details.notes = event.detail.value
                            this.tabDetailsUpdated();
                        }}"
                        ?disabled="${this.readOnlyMode}">
                    </backpackemr-text-area>
                </div>
            </div>
        `;
    }

    _handleEditContent(){
        this.readOnlyMode = !this.readOnlyMode;
    }

    _handleDateSelected(event: any) {
        this.details.firstSymptomsDate = event.detail.date;        
        this.requestUpdate();
        this.tabDetailsUpdated();
    }

    getUpdateMutationVariables() {
        return {
            tab: 'PUBLICHEALTH',
            id: this.details?._id,
            visitID: this.visitID,
            tabDetails: {
                _id: this.details?._id,
                _rev: this.details?._rev,
                campaignId: this.details.campaignId,
                channels: this.details.channels,
                updatedAt: new Date().toISOString(),
                type: 'patient-primaryCarePublicHealth',
                patientId: this.details.patientId,
                contacts: this.details.contacts,
                cookingConditions: this.details.cookingConditions,
                drinkingWaterFiltered: this.details.drinkingWaterFiltered,
                firstSymptomsDate: this.details.firstSymptomsDate,
                knownCaseCloseContact: this.details.knownCaseCloseContact,
                livingConditions: this.details.livingConditions,
                notes: this.details.notes,
                occupationalRiskGroup: this.details.occupationalRiskGroup,
                traveledInternationally: this.details.traveledInternationally,
                visitedCountries: this.details.visitedCountries
            }
        }
    }
    
    tabDetailsUpdated(){        
        this.dispatchEvent(new CustomEvent('tab-info-updated', {
            detail: {
                variables: this.getUpdateMutationVariables()
            },
            bubbles: true,
            composed: true
        }));
    }
}