// Foundation
import { gql } from '@apollo/client/core';
import { customElement, html, internalProperty, css, property } from 'lit-element';
import { ApolloLitElement } from '../apolloLitElement';

import authenticator from '../../utils/authenticator';

import { router } from '../../utils/routeRegistry';

import {
    PreventAndRedirectCommands,
    RedirectResult,
    Router,
    RouterLocation,
} from '@vaadin/router';

// Models
import { Patient } from '../../models/patient';
import { Campaign } from '../../models/campaignDetails';
import { SORTFIELD } from '../../models/search';
import { COMPLAINTS } from '../../models/medicalCareTabs';

import { selectedCampaignID } from '../../api/graphQL';

// Components
import '@material/mwc-linear-progress';
import './patientListFilters';
import './patientListContainer';
import './paginationControls';
import '@vaadin/vaadin-tabs';
import './patientQueue';
import { themePrimaryBlue, themeMediumGrey } from '../../styles/themeColors';

// Apollo MakeVar
import { queueCategory } from '../../api/graphQL';

@customElement('patient-list')
export class PatientList extends ApolloLitElement {
    // GQL
    query = gql`
        query PatientsQuery($campaignIDs: [String!], $pageLimit: Int, $pageOffset: Int, $searchCriteria: String, $sortCriteria: String, $fetchingSpecificCampaign: Boolean, $requestingQueueView: Boolean),{
            patients(campaignIDs: $campaignIDs, pageLimit: $pageLimit, pageOffset: $pageOffset, searchCriteria: $searchCriteria, sortCriteria: $sortCriteria, fetchingSpecificCampaign: $fetchingSpecificCampaign, requestingQueueView: $requestingQueueView) {
                patientID,
                patientProfileSummary { 
                    firstName, 
                    lastName, 
                    gender, 
                    governmentID, 
                    humanID, 
                    dateOfBirth, 
                    profilePhoto {
                        fileName,
                        signedURL
                    }, 
                    currentCampaignData { 
                        clinicNumber, 
                        queue {
                            queueId, 
                            status, 
                            timestamp
                    }}
                 }
            }
        }
    `;

    static get styles() {
        return css`
            mwc-linear-progress {
                --mdc-theme-primary: ${themePrimaryBlue};
            }

            vaadin-tab {
                font-family: Roboto, Arial, sans-serif; 
                color: ${themeMediumGrey};
            }

            vaadin-tab[selected] {
                color: ${themePrimaryBlue}
            }

            vaadin-tabs {
                align-content: center;
                align-items: center;
                justify-content: center;
                justify-self: center;
                align-self: center;
                float: center;
            }

            .list-queue-toggle {
                margin: 0px 0px 10px 0px;
                padding: 0px 0px 0px 0px;
            }
            `
    }

    get variables() {
        const campaignID = new URLSearchParams(this.location.search).get('campaign');
        selectedCampaignID(campaignID || '');
        this.selectedCampaignID = selectedCampaignID();
        window.localStorage.setItem('selectedCampaignID', selectedCampaignID());

        const pageLimit: number = !queueCategory() ? this.pageLimit : 200; // TBD
        const pageOffset: number = this.pageOffset;
        const searchCriteria: string = this.searchCriteria;
        const sortCriteria: string = this.sortField;
        const fetchingSpecificCampaign: boolean = campaignID ? true : false;
        const requestingQueueView: boolean = queueCategory() ? true : false;

        let campaignIDs:Array<string>;

        if(campaignID) {
            campaignIDs = [campaignID];
        } else {
            campaignIDs = this.userCampaigns.map(campaign => campaign.campaignID);
        }
        
        console.log('Fetching patient list: ', { campaignIDs, pageLimit, pageOffset, searchCriteria, sortCriteria, fetchingSpecificCampaign, requestingQueueView });
        return { campaignIDs, pageLimit, pageOffset, searchCriteria, sortCriteria, fetchingSpecificCampaign, requestingQueueView };
    }

    @internalProperty()
    patients: Array<Patient>;

    @internalProperty()
    location = router.location;

    @property({attribute: false})
    userCampaigns: Array<Campaign> = authenticator.getAuthenticatedUser()?.campaignList || []; 

    @internalProperty()
    selectedCampaignID: string = new URLSearchParams(this.location.search).get('campaign') || ''; 

    @internalProperty()
    searchCriteria: string = '';

    @internalProperty()
    sortField: SORTFIELD = SORTFIELD.REGISTRATION;
    
    @internalProperty()
    pageOffset: number = 1;

    @internalProperty()
    pageLimit: number = 100; //100 for deployment, 1-12 for testing

    @internalProperty()
    nextPageButtonDisabled: boolean;

    @internalProperty()
    selectedTabIndex: number = !window.localStorage.getItem('patientQueueTab') ? 0 : 1; // if no queue tab stored, index is 0 for patient list

    firstUpdated(changedProperties) {
        this._notifyHeaderBarHideBackButton();
    }

    connectedCallback(){
        const queueCategoryTab = window.localStorage.getItem('patientQueueTab');
        if (queueCategoryTab) {
            queueCategory(queueCategoryTab); // without this, when you refresh the page while on patient queues, the app defaults the user back to complaints tab. 
        }

        super.connectedCallback();
        this.refetch();
    }

    shouldUpdate(changedProps) {
        if (changedProps.get('location')) {
            this.refetch();
        } 
        return super.shouldUpdate(changedProps);
    }

    render() {
        this.patients = this?.data?.patients;  

        return html`            
            <patient-list-filters 
                .userCampaignList="${this.userCampaigns}"
                .selectedCampaignID = "${selectedCampaignID()}" 
                .sortField = "${this.sortField}"
                searchCriteria="${this.searchCriteria}"
                @campaign-selected="${this._handleCampaignSelection}"
                @search-criteria-changed="${this._handleSearchCriteriaChange}"
                @sort-criteria-changed="${this._handleSortCriteriaChange}"
                ?patientListView="${!queueCategory()}"
            ></patient-list-filters>

            <div class="patient-list-queue-toggle-tabs">
                <vaadin-tabs selected="${this.selectedTabIndex}" class="list-queue-toggle" @selected-changed="${event => this._handlePatientListQueueToggle(event)}">
                    <vaadin-tab>Patient List</vaadin-tab>
                    <vaadin-tab>Patient Queue</vaadin-tab>
                </vaadin-tabs>
            </div>

            ${ this.loading
                ? html`<mwc-linear-progress indeterminate></mwc-linear-progress>`
                : !queueCategory() ? html`
                    <patient-list-container .patients="${this.patients}"></patient-list-container>

                    <pagination-controls
                        @first-page-clicked = "${this._handleFirstPageClick}"
                        @previous-page-clicked = "${this._handlePreviousPageClick}"
                        @next-page-clicked = "${this._handleNextPageClick}"
                        .pageNumber = "${this.pageOffset}"
                        ?nextPageButtonDisabled = "${this.patients.length !== this.pageLimit}">
                    </pagination-controls>
                ` : html `
                    <patient-queue
                        .patients = "${this.patients}"
                    ></patient-queue>

                    <pagination-controls
                        @first-page-clicked = "${this._handleFirstPageClick}"
                        @previous-page-clicked = "${this._handlePreviousPageClick}"
                        @next-page-clicked = "${this._handleNextPageClick}"
                        .pageNumber = "${this.pageOffset}"
                        ?nextPageButtonDisabled = "${this.patients.length !== this.pageLimit}">
                    </pagination-controls>
                `
            }
        `;
    }

    _handlePatientListQueueToggle(event) {
        if (event?.detail?.value === this.selectedTabIndex) return; // the vaadin tabs fires the selected change event even if the initial selected index is declared. The function will only enter this if statement the first time the app loads.

        this.selectedTabIndex = event?.detail?.value;
        if (event?.detail?.value === 0) {
            queueCategory('');
            window.localStorage.setItem('patientQueueTab', '');
            this.refetch();
        } else {
            queueCategory(COMPLAINTS.TABNAME);
            this.loading = true; 
            if (this.selectedCampaignID) {
                Router.go(`/patients?campaign=${this.selectedCampaignID}`)
            } else {
                Router.go(`/patients?campaign=${this.userCampaigns[0].campaignID}`)
            }
        }
    }

    showProgressBar() {
        if (this.loading) return html`<div><mwc-linear-progress indeterminate></mwc-linear-progress></div>`;
        else return html``;
    }

    _handleSortCriteriaChange(event:CustomEvent) {
        if (event.detail?.sortField) {
            this.sortField = event.detail.sortField;
            this.pageOffset = 1;
            this.refetch();
        }
    }

    _handleCampaignSelection(event:CustomEvent) {
        if (!event?.detail?.campaignID) {
            this.selectedCampaignID = '';
            window.localStorage.setItem('selectedCampaignID', '');
            Router.go(`/patients`);
        } else {
            this.selectedCampaignID = event?.detail?.campaignID;
            window.localStorage.setItem('selectedCampaignID', event.detail.campaignID);
            Router.go(`/patients?campaign=${event.detail.campaignID}`);
        }

        this.pageOffset = 1;
    }

    _handleFirstPageClick() {
        if(this.pageOffset !== 1) {
            this.pageOffset = 1;
            this.refetch();
        }
    }

    _handlePreviousPageClick() {
        if(this.pageOffset > 1) {
            this.pageOffset--;
            this.refetch();
        }
    }

    _handleNextPageClick(event) {
        if (this.patients.length === this.pageLimit) {
            this.pageOffset++;
            this.refetch();
        }
        if (this.patients.length === 0) {
            this.pageOffset--;
        }
    }

    _handleSearchCriteriaChange(event) {
        if (event?.detail?.searchCriteria !== this.searchCriteria) {
            this.searchCriteria = event?.detail?.searchCriteria || '';
            this.refetch();
        }
    }

    _notifyHeaderBarHideBackButton() {
        this.dispatchEvent(new CustomEvent('hide-header-back-button', {
            detail: {},
            bubbles: true,
            composed: true 
        }));
    }

    onBeforeEnter(location:RouterLocation, commands:PreventAndRedirectCommands, router:Router): Promise<unknown> | RedirectResult | undefined {
        if (!authenticator.isAuthenticated()) {
            return commands.redirect('/login');
        }
    }
}
