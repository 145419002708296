// Foundation
import { LitElement, customElement, html, css, property } from 'lit-element';
import * as moment from 'moment';

// Themes
import { themePrimaryBlue, themeHoverGrey, themeLightGreyBorders } from '../../styles/themeColors';

import { getTimeSinceDate, formatDate, formatGetTimeSinceDate } from '../../utils/dateTimeFormatter';
import i18next from 'i18next';

@customElement('visit-list-item')
export class VisitListItem extends LitElement {

    static get styles() {
        return css`
                
            .visits-list-item {
                background-color: #fff;
                padding: 12px;
                border-top: 1px solid ${themeLightGreyBorders};
                cursor: pointer;
            }
                
                .selected {
                    font-weight: 600;
                    background-color: ${themeHoverGrey};
                    border-left: 5px solid ${themePrimaryBlue};
                    /* box-shadow: rgba(0, 0, 0, 0.2) 3px 2px 9px 1px inset; */
                }

                .visits-list-item:hover {
                    background-color: ${themeHoverGrey};
                }
                
                .visits-list-item-date {
                    font-size: 16px;
                    padding-bottom: 6px;
                }
                
                .visits-list-item-date-duration {
                    font-size: 14px;
                    color: #666;
                }

            mwc-button {
                --mdc-theme-primary: ${themePrimaryBlue};
                --mdc-button-outline-color: ${themePrimaryBlue};
                --mdc-typography-button-text-transform: none;
                width: 100%;
            }

            @media (max-width: 768px) {
                .visits-list {
                    display: none;
                }
            }
        `
        ;
    }

    @property({attribute: false})
    visit: any;

    @property({type: Boolean})
    selected: boolean = false;

    render() {
        const selectedClass = this.selected ? "selected" : "";

        return html`
            <div class="visits-list-item ${selectedClass}" id=${this.visit.patientCampaignID} @click=${this._visitChanged}>
                <div class="visits-list-item-date">${formatDate(this.visit.visitDateTime)}</div>
                <div class="visits-list-item-date-duration">${formatGetTimeSinceDate(this.visit.visitDateTime)}</div>
            </div>
        `;
    }

    _visitChanged(event){
        event.preventDefault();

        const visitChanged = new CustomEvent('visit-changed', {
            detail: { patientCampaignID: this.visit.patientCampaignID },
            bubbles: true,
            composed: true 
        });

        this.dispatchEvent(visitChanged);
    }

}