import { LitElement, customElement, html, property } from 'lit-element';

import './appLink.ts';

// models
import { User } from '../models/user';

//TOOD(muz): make this based off of auth
@customElement('backpackemr-nav')
export class BackpackEmrNav extends LitElement {

  @property()
  user:User = null

  render() {
    return html`
      <app-link href="/patient">patients</app-link>
    `;
  }
}