export const config = {
    dev: {
        api: {
            url: "http://localhost:1337"
        }
    },
    staging: {
        api: {
            url: "https://api-staging.mybackpackemr.com"
        }
    },
    prod: {
        api: {
            url: "https://api.mybackpackemr.com"
        }
    },
};
