 export const chronicHistories:Array<string> = [
  'aids',
  'asthma',
  'burningInChest',
  'chronicCardiacDisease',
  'chronicHematologicalDisease',
  'chronicKidneyDisease',
  'chronicNeurologicalDisease',
  'chronicPulmonaryDisease',
  'cough',
  'dementia',
  'depression',
  'diabetes',
  'diarrhea',
  'femaleProblems',
  'fever',
  'headache',
  'hernia',
  'highBloodPressure',
  'liverDiseaseMild',
  'liverDiseaseModerateSevere',
  'malignantNeoplasm',
  'malnutrition',
  'obesity',
  'rheumatologicDisorder',
  'skinRash',
  'smoking',
  'stomachPain',
  'testedHivPositive',
  'testedMalariaPositive',
  'testedTBPositive',
  'urineProblems',
  'waistPain',
  'wombPain',
  'wormsOrParasites',
  'woundOrSkinSores'
  ]
  
 export const allergies:Array<string> = [
  'Penicillin',
  'Sulfa drugs',
  'Aspirin',
  'NSAIDs',
  'Tetracycline',
  'Codeine',
  'Phenytoin',
  'Carbamazapine',
  'None'
  ]
  
  export const currentMedications:Array<string> = [
  'Warfarin/Coumadin',
  'Ibuprofen',
  'Aspirin ',
  'Oxycodone',
  'Percocet',
  'Codine',
  'MS Contin',
  'Hydrocodone / Vicodin',
  'Acetaminophen',
  'Celecoxib / Celebrex',
  'Lisinopril',
  'Atorvastatin',
  'Cephalexin / Keflex ',
  'Amoxicillin',
  'Lidocaine patch',
  'Diclofenac',
  'None',
  ]
  