export const occupations = [
  'accountant',
  'agent',
  'agriculture',
  'artist',
  'banker',
  'business',
  'carpenter',
  'construction',
  'consultant',
  'dentist',
  'designer',
  'driver',
  'dry cleaner',
  'electrician',
  'hairdresser',
  'healthcare',
  'house help',
  'labor',
  'manufacturing',
  'marketing',
  'miningQuarrying',
  'music industry',
  'painter',
  'pastor',
  'sales',
  'secretary',
  'security',
  'self employed',
  'service representative',
  'services',
  'small business owner',
  'stay at home parent',
  'student',
  'teacher',
  'technology',
  'transportation',
  'waiter',
  'None'
]

export const gender = [
  'male',
  'female',
  'other'
]

export const insuranceOptions = [
  'insuranceSelfNone',
  'insurancePrivate',
  'insuranceNational'
]
