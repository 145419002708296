import * as moment from 'moment';
import i18next from 'i18next';


/////////// GET CURRENT DATE ///////////
// format: XX XXX XXXX (02 Nov 2020)
export function getCurrentDate() {
  moment.locale('en-gb')
  return `${moment().format('ll')}`;
}

// format: XX XXX XXXX (02 Nov 2020 at 10:07am)
export function getCurrentDateTime() {
  moment.locale('en-gb');
  let date:string = moment().format('ll');
  moment.locale('en');
  let time:string = moment().format('LT');

  if (i18next.t('language') === 'en-gb') {
    return `${date} at ${time}`;
  } else if (i18next.t('language') === 'es') {
    return `${date} a las ${time}`;
  } else if (i18next.t('language') === 'fr') {
    return `${date} à ${time}`
  }
}

// format: XX/XX/XXXX XX:XX PM/AM (01/16/2021 10:15pm)
export function getCurrentNumericDateTime() {
  return `${moment().format('l')} ${moment().format('LT')}`;
}

// calculates the amount of time elapsed since a date
export function getTimeSinceDate(date:string) {
  if (i18next.t('language') === 'en-gb') {
    return `${moment(date).fromNow(true)}`;
  } else if (i18next.t('language') === 'es') {
    return `hace ${moment(date).fromNow(true)}`;
  } else if (i18next.t('language') === 'fr') {
    return`Il y a ${moment(date).fromNow(true)}`;
  }
}

// format a date from ex: "2000-08-09T12:00:00.000Z" to DD MMM YYY
export function formatDate(date:string) {
  return moment(date).format('DD MMM YYYY');
}

export function formatAgeAndBirthDate(dateOfBirth:string) {
  if (i18next.t('language') === 'en-gb') {
      return dateOfBirth ? `${getTimeSinceDate(dateOfBirth)} old (${formatDate(dateOfBirth)})` : '';
  } else if (i18next.t('language') === 'es') {
    return dateOfBirth ? `${getTimeSinceDate(dateOfBirth)} (${formatDate(dateOfBirth)})` : '';
  } else if (i18next.t('language') === 'fr') {
    return dateOfBirth ? `${getTimeSinceDate(dateOfBirth)} (${formatDate(dateOfBirth)})` : '';
  }
}

export function formatGetTimeSinceDate(date:string){
  if (i18next.t('language') === 'en-gb') {
    return `${getTimeSinceDate(date)} ago`
  } else if (i18next.t('language') === 'es') {
    moment.locale('es');
    return `${getTimeSinceDate(date)}`
  } else if (i18next.t('language') === 'fr') {
    moment.locale('fr');
    return `${getTimeSinceDate(date)}`
  }
}


export function formatDateTime(date:string) {
  if (i18next.t('language') === 'en-gb') {
    return moment(date).format('DD MMM YYYY [at] h:mm a');
  } else if (i18next.t('language') === 'es') {
    moment.locale('es');
    return moment(date).format('DD MMM YYYY [a las] HH:mm');
  } else if (i18next.t('language') === 'fr') {
    moment.locale('fr');
    return moment(date).format('DD MMM YYYY [à] HH:mm');
  }
}

// input format iso8601
//TODO needs language translation for "months, years, old"
export function convertDateToAge(iso8601date:string) {
  if(!iso8601date) return '';

  //TODO check date input format. Defensive AF
  // if(.isValid()) return '';

  //TODO if DOB is less than 12 months, calculate months

  //TODO

  /*
      Ideally..

      1 to 3 weeks            = "2 weeks old", "3 weeks old"
      4 weeks to 11 months    = "2 months old"
      12-23 months            = "1 year, 2 months old"
      24+ months              = "2 years old", "5 years old"
  */
  
  //translations
  // i18next.t('years').toString() //TODO need to add "old" to translations

  return moment(iso8601date).fromNow(true) + ' old';
}

/*
export const ageFromDOB = dateOfBirth => {
  const dobMoment = dateOfBirth && moment(dateOfBirth, [moment.ISO_8601, dateFormat], true)

  if (!(dobMoment && dobMoment.isValid())) return // invalid date!

  let age

  const days = (dobMoment && moment().diff(dobMoment, 'days')) || 0
  const weeks = (dobMoment && moment().diff(dobMoment, 'weeks')) || 0
  const months = (dobMoment && moment().diff(dobMoment, 'months')) || 0
  const years = (dobMoment && moment().diff(dobMoment, 'years')) || 0

  if (Math.abs(years) > 1) age = `${Math.floor(years)} ${I18n.t('years')}`
  else if (Math.abs(months) > 1) age = `${Math.floor(months)} ${I18n.t('months')}`
  else if (Math.abs(weeks) > 1) age = `${Math.floor(weeks)} ${I18n.t('weeks')}`
  else if (Math.abs(days) > 1) age = `${Math.floor(days)} ${I18n.t('days')}`
  else age = dobMoment && I18n.t('newborn')

  return age
}
*/

/* 
const getAge = (dateString) => {
  //found this function on stackoverflow..
  
  if(!dateString){
      return null;
  }
  
  let today = new Date();
  let birthDate = new Date(dateString);

  if(isNaN(birthDate)){

      console.error('Server: Bad dateOfBirth: ' + dateString);
      return null;
  }

  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
} */