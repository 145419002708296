// Foundation
import { LitElement, customElement, html, internalProperty, css, property } from 'lit-element';

// Themes
import { themePrimaryBlue, themeSecondaryBlue, themeHoverGrey, themeLightGreyBorders, themeDarkGrey } from '../../styles/themeColors';

import '@material/mwc-select';
import '@material/mwc-list/mwc-list-item';

@customElement('backpackemr-select')
export class CustomSelect extends LitElement {

    static get styles() {
        return css`
            .container {
                display: grid;
            }
            
            mwc-select {
                --mdc-select-disabled-ink-color: ${themeDarkGrey};
                --mdc-theme-primary: ${themePrimaryBlue};
            }

            @media (max-width: 768px) {

            }
        `
        ;
    }

    @property({type: Boolean, attribute: true})
    disabled:boolean = false;

    @property({type: String, attribute: true})
    label:string = "";

    @property({type: String, attribute: true})
    value:string;

    @property({type: Array, attribute: false})
    items:Array<any> = [];

    render() {
        const listContainsItem = this.items.findIndex(item => {
            return item.key === this.value;
        });

        // console.warn('i18n: custom select', {
        //     value: this.value,
        //     items: this.items
        // })
        
        // if (listContainsItem ===  -1){
        //     this.items.push({
        //         key: this.value
        //     });
        // }

        return html`
            <div class="container">
                <mwc-select
                    label="${this.label}"
                    value="${this.value}"
                    ?disabled="${this.disabled}"
                >
                    <mwc-list-item value=" "></mwc-list-item>
                    ${this.buildItemsList()}

                    ${listContainsItem === -1 && this.value !== ' ' ? 
                        html`
                            <mwc-list-item
                                value="${this.value}"
                            >   
                                ${this.value}
                            </mwc-list-item>`
                        : 
                            html``
                        }
                </mwc-select>
            </div>
        `;
    }

    buildItemsList() {
        return html`
            ${this.items.map(item => {
                return html`
                    <mwc-list-item
                        value="${item.key}"
                    >   
                        ${item.value}
                    </mwc-list-item>
                `
            })}
        `;
    }
}