import { html, LitElement, customElement, css, property, TemplateResult, } from 'lit-element';
import { connect } from 'pwa-helpers';
import * as OfflinePluginRuntime from 'offline-plugin/runtime';
import './components/backpackEmrRouter.ts';

OfflinePluginRuntime.install({
    onInstalled: () => {
        // console.log('Service Worker installed');
    },
    onUpdating: () => {
        // console.log('SW Event:', 'onUpdating');
    },
    onUpdateReady: () => {
        // console.log('SW Event:', 'onUpdateReady');
        OfflinePluginRuntime.applyUpdate();
    },
    onUpdated: () => {
        // console.log('SW Event:', 'onUpdated');
    },
    onUpdateFailed: () => {
        // console.log('SW Event:', 'onUpdateFailed');
    }
});

@customElement('backpackemr-web')
export default class extends LitElement {

    public connectedCallback(): void {
        if (super.connectedCallback) {
            super.connectedCallback();
        }
    }
    
    public render(): TemplateResult {
        return html`
            <backpackemr-router></backpackemr-router>
        `;
    }

    // handles store state changes
    stateChanged(state:any) {
        this.requestUpdate();
    }  
}
