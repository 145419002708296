// Foundation
import { LitElement, customElement, html, css, property, query, internalProperty } from 'lit-element';

import { Photo } from '../../models/clinical/exam';

@customElement('profile-photo')
export class ProfilePhoto extends LitElement {
    static get styles() {
        return css`
            .profile-photo {
                display: grid;
                width: 56px;
                height: 56px;
                border-radius: 50%;
                margin: 4px;
                padding: 1px;
                background-color: grey;
                cursor: pointer;
            }

            .fade {
                animation-name: fade;
                animation-duration: 2s;
            }

            .large-photo {
                width: 200px;
                height: 200px;
                margin: 8px;
            }

            .placeholder-image {
                --mdc-icon-size: 70px;
            }

            .placeholder-image-large {
                --mdc-icon-size: 200px;
                width: 200px;
                height: 200px;
            }

            @keyframes fade {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
        `
        ;
    }

    @property({attribute: false})
    photo:Photo;

    @property({attribute: false})
    previewPhoto: any;

    @query('#preview-image')
    previewImage: HTMLImageElement

    @internalProperty()
    fileReader: FileReader = new FileReader();

    @property({type: Boolean, attribute: true})
    large: boolean = false

    @internalProperty()
    photoReceivedSuccessfully: boolean

    async firstUpdated(){
        this.fileReader.addEventListener("load", (event) => {
            this.previewImage.src = event.target.result.toString();
        });

        if (this.photo?.signedURL) {
            this.photoReceivedSuccessfully = await this.validateURL();
        }
    }

    render() {
        if (!this.photo?.signedURL && this.previewPhoto) {
            this.setPreviewImage()
        }

        return html`
            ${this.photo?.signedURL && this.photoReceivedSuccessfully
                ? html`
                    <a 
                        href="${this.photo.signedURL}" 
                        target="_blank">
                            <img 
                                class="profile-photo fade ${this.large && 'large-photo'}"
                                src="${this.photo.signedURL}"
                                alt="Patient Photo: ${this.photo.fileName}"
                                loading="lazy"
                            />
                    </a>
                `
                : this.previewPhoto
                ? html`
                    <img 
                        id="preview-image"
                        class="profile-photo fade ${this.large && 'large-photo'}"
                        alt="Patient Photo: ${this.photo?.fileName}"
                    />
                `
                : html`<mwc-icon class="fade ${!this.large ? 'placeholder-image' : 'placeholder-image-large'}">account_circle</mwc-icon>`
            }
        `;
    }

    setPreviewImage() {
        if (!this.previewPhoto) return;
        this.fileReader.readAsDataURL(this.previewPhoto);
    }

    // This function validates the signedURL that was retrieved from the patient document. If the photo doesn't exist on S3, the response will return a 404.
    async validateURL() {
        const resp:any = await fetch(this.photo?.signedURL, { method: 'GET' })

        //console.log('RESPONSE FROM AWS S3 PROVIDER: ', resp);

        return resp?.ok === true
    }
}