import { LitElement, customElement, html, css } from 'lit-element';

// components
import './userHeaderBox.ts';

import { themePrimaryBlue } from '../styles/themeColors';

@customElement('backpackemr-footer')
export class BackpackEmrFooter extends LitElement {

  static get styles() {
    return css`

      .centered-app{
          display: grid;
          grid-template-columns: 1fr minmax(auto, 1200px) 1fr;
          padding-left: 12px;
          padding-right: 12px;
      }

      .footer-content {
          grid-column: 2;
          display: grid;
          grid-template-columns: auto auto;
          align-items: center;
          justify-content: space-between;
      }

      footer {
          /* box-shadow: -12px -12px 2px #aaa; */
          background-color: #fff;
          height: 40px;
          margin-top: 6px;
      }


      mwc-select {
        --mdc-theme-primary: ${themePrimaryBlue};
        --mdc-select-fill-color: #fff;
      }

      @media (max-width: 768px) {
          .filters-container{
              display: grid;
              grid-template-columns: 1fr;
              padding: 20px 0;
          }
      }

    `;
  }

  render() {
    
    return html`
      <footer class="centered-app">
          <div class="footer-content">
              <div>	&#169; 2021 Binary Bridge</div>
          </div>
      </footer>
    `;
  }
}