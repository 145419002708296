import { client } from '../api/graphQL';
import axios from 'axios'

// models
// import { Photo } from "../models/photo";

import { GET_SIGNED_PUT_URL, CREATE_PHOTO_RECORD } from '../api/photoMutations';
import { stripTypenames } from '../utils/graphqlHelper';

class PhotoService {
    public async uploadPhoto(file: File): Promise<any> {
        const signedPutURLResp:any = await this.getSignedPutURL(file.type);

        if (!signedPutURLResp?.success || !signedPutURLResp?.photoUploadDetails?.signedURL) {       
            return {
                success: false,
                message: signedPutURLResp.message,
            };
        }

        const uploadToServerResp = await this.uploadPhotoToServer(file, signedPutURLResp?.photoUploadDetails?.signedURL);

        return {
            success: uploadToServerResp?.success,
            message: uploadToServerResp?.message,
            fileName: signedPutURLResp?.photoUploadDetails?.fileName
        };
    }

    private async getSignedPutURL(fileType:string): Promise<any> {
        try {
            const result = await client.mutate({
                mutation: GET_SIGNED_PUT_URL,
                variables: {
                    fileType
                }
            });
            
            return result?.data?.getSignedPutURL;

        } catch (error) {
            console.error("photoService.ts ~ getSignedPutURL", error);
            // console.error("photoService.ts ~ getSignedPutURL ~ networkError:", error.networkError.result.errors);
            return;
        }
    }

    private async uploadPhotoToServer(file:any, signedPutURL:string): Promise<any>{

        const options = {
            headers: {
                "Content-Type": file.type
            }
        };

        try {
            const resp = await axios.put(signedPutURL, file, options);
            
            //TODO - create a retry process? slow internet was causing a timeout

            return { 
                success: true,
                message: 'Successfully uploaded file to server.'
            }
        }
        catch (error) {
            console.error('photoService.ts ~ uploaadPhotoToServer ~ error:', error);
            return { 
                success: false,
                message: 'Failed to upload file to server'
            }
        }
    }

    private async updateDocument(mutation: any, variables: any): Promise<any> {
        const variablesStrippedOfTypename = stripTypenames(variables, "__typename");

        try {
            const result = await client.mutate({
                mutation,
                variables: variablesStrippedOfTypename,
            });
            return result.data;
        } catch (error) {
            console.error("currentPatient.ts ~ updateDocument", error);
            console.error("currentPatient.ts ~ updateDocument ~ networkError:", error.networkError.result.errors);
            // return error;
        }
    }

    public async createPhotoRecord(input: any) {
        const mutationQuery = CREATE_PHOTO_RECORD;
        const result = await this.updateDocument(mutationQuery, { input });

        return result;
    }

}

export default new PhotoService();